import React from "react";
import "./error.css";

const Error = ({ title, header, description, imgLink }) => {
  return (
    <div className="error-container">
      <div className="error-summary">
        <div>
          <div className="error-title">{title}</div>
          <div className="error-header">{header}</div>
          <div className="error-description">{description}</div>
        </div>
        <div className="back-to-home">
          <div className="text">Go back to home</div>
        </div>
      </div>
      <img src={imgLink} height={605} width={575} />
    </div>
  );
};

export default Error;
