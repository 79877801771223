import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import img from "./web-share-1200x630.jpg";

const ShareMetaData = ({ title, description, imageUrl }) => {
  console.log(`${window.location.origin}/${title.replace(/\s/g, "")}`);
  useEffect(() => {
    // Set the OG Graph Meta Tag
    var metaTagTitle = document.querySelector('meta[property="og:title"]');
    metaTagTitle.setAttribute("content", title);
    var metaTagDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    metaTagDescription.setAttribute("content", description);
    var metaTagUrl = document.querySelector('meta[property="og:url"]');

    if (!metaTagUrl) {
      let metaTag = document.createElement("meta");
      metaTag.setAttribute("property", "og:url");
      document.head.appendChild(metaTag);
      metaTag.setAttribute(
        "content",
        `${window.location.origin}/${title.replace(/\s/g, "")}`
      );
    } else
      metaTagUrl.setAttribute(
        "content",
        `${window.location.origin}/${title.replace(/\s/g, "")}`
      );

    var twitterTagTitle = document.querySelector('meta[name="twitter:title"]');
    twitterTagTitle.setAttribute("content", title);

    var twitterTagDescription = document.querySelector(
      'meta[name="twitter:description"]'
    );
    twitterTagDescription.setAttribute("content", description);
  }, []);

  return null;
};

export default ShareMetaData;
