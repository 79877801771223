// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#custom-link {
  text-decoration: none;
  color: inherit;
}

#custom-link:hover {
  text-decoration: underline;
}

.json-key {
  color: #77e687;
  font-family: "Source Code Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.json-value {
  color: #a5d6ff;
  font-family: "Source Code Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.shell-pre {
  padding: 15px 10px 10px;
  white-space: pre-line;
  word-break: break-word;
}

.code-copy-btn {
  position: relative;
  height: 33px !important;
  align-items: center;
}

.code-copy-btn:hover .copy-btn {
  opacity: 1;
  position: absolute;
  /* top: 0; */
  right: 0;
}

.code-copy-btn .hovered-copy-btn {
  opacity: 0;
  position: absolute;
  /* top: 0; */
  right: 0;
}

.code-copy-btn:hover .copy-btn {
  opacity: 0;
  position: absolute;
  /* top: 0; */
  right: 0;
}

.code-copy-btn:hover .hovered-copy-btn {
  opacity: 1;
  position: absolute;
  /* top: 0; */
  right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/mdFilePreviewer/index.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,QAAQ;AACV;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,YAAY;EACZ,UAAU;AACZ","sourcesContent":["#custom-link {\n  text-decoration: none;\n  color: inherit;\n}\n\n#custom-link:hover {\n  text-decoration: underline;\n}\n\n.json-key {\n  color: #77e687;\n  font-family: \"Source Code Pro\";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n}\n\n.json-value {\n  color: #a5d6ff;\n  font-family: \"Source Code Pro\";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 24px;\n}\n\n.shell-pre {\n  padding: 15px 10px 10px;\n  white-space: pre-line;\n  word-break: break-word;\n}\n\n.code-copy-btn {\n  position: relative;\n  height: 33px !important;\n  align-items: center;\n}\n\n.code-copy-btn:hover .copy-btn {\n  opacity: 1;\n  position: absolute;\n  /* top: 0; */\n  right: 0;\n}\n\n.code-copy-btn .hovered-copy-btn {\n  opacity: 0;\n  position: absolute;\n  /* top: 0; */\n  right: 0;\n}\n\n.code-copy-btn:hover .copy-btn {\n  opacity: 0;\n  position: absolute;\n  /* top: 0; */\n  right: 0;\n}\n\n.code-copy-btn:hover .hovered-copy-btn {\n  opacity: 1;\n  position: absolute;\n  /* top: 0; */\n  right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
