import React, { useState } from "react";
import Menu from "./menu/Menu";
import Logo from "./logo/Logo";
import ReleaseNotes from "./buttons/ReleaseNotes";
import GitHub from "./buttons/GitHub";
import styles from "./NavBar.module.css";
import AdaptiveCloud from "./buttons/AdaptiveCloud";
import Search from "./buttons/Search";
import SearchDrawer from "./searchbar/SearchDrawer";
import Login from "./buttons/Login";
import Logout from "./buttons/Logout";
import Profile from "./buttons/ProfileName";
import { useAuthContext } from "../../context/AuthProvider";

const NavBar = ({
  menuItems,
  selectedMenuItem,
  setSelectedMenuItem,
  isSearchDrawerVisible,
  toggleSearchDrawer,

  setQueHideMenuDrawer,
  handleClickSearchDrawer,
  onSearch,
  searchResults,
  setQueHideSearchDrawer,
}) => {
  const [isMenuDrawerVisible, setIsMenuDrawerVisible] = useState(
    menuItems.map(() => false)
  );
  const { token } = useAuthContext();

  return (
    <div className={styles.navbarContainer}>
      <div className={styles.leftItems}>
        <Logo />
        <Menu
          menuItems={menuItems}
          selectedMenuItem={selectedMenuItem}
          setSelectedMenuItem={setSelectedMenuItem}
          isMenuDrawerVisible={isMenuDrawerVisible}
          setQueHideMenuDrawer={setQueHideMenuDrawer}
          setIsMenuDrawerVisible={setIsMenuDrawerVisible}
        />
        <AdaptiveCloud
          setIsMenuDrawerVisible={setIsMenuDrawerVisible}
          menuItems={menuItems}
          setSelectedMenuItem={setSelectedMenuItem}
          setQueHideSearchDrawer={setQueHideSearchDrawer}
        />
      </div>
      <div className={styles.rightItems}>
        <Search
          isSearchDrawerVisible={isSearchDrawerVisible}
          toggleSearchDrawer={toggleSearchDrawer}
        />
        {isSearchDrawerVisible ? (
          <div
            className={`app-search-drawer ${
              isSearchDrawerVisible ? "visible" : ""
            }`}
            // ref={searchDrawerRef}
            // onMouseEnter={handleMouseEnterSearchDrawer}
            // onMouseLeave={handleMouseLeaveSearchDrawer}
          >
            <div className="app-search-drawer-content">
              <SearchDrawer
                isSearchDrawerVisible={isSearchDrawerVisible}
                onClick={handleClickSearchDrawer}
                onSearch={onSearch}
                searchResults={searchResults}
              />
            </div>
          </div>
        ) : null}
        <ReleaseNotes setQueHideSearchDrawer={setQueHideSearchDrawer} />
        <GitHub />
        {/* {token || sessionStorage.getItem("authToken") ? (
          <>
            <Logout />
            <Profile />
          </>
        ) : (
          <Login />
        )} */}
      </div>
    </div>
  );
};

export default NavBar;
