import { createContext, useContext, useReducer } from "react";

const SidePanel = createContext();

const initialState = {
  overview: {
    cover: "",
    coverRaw: "",
    description: "",
  },
  overviewValidation: "",
  coverImageUploadingStatus: {
    fileName: "",
    status: "",
  },
  prerequisites: Array.from(Array(3), (_, i) => ({
    title: "",
    type: "",
    description: "",
  })),
  prerequsitiesValidation: Array.from(Array(3), (_, i) => ({
    titleStatus: "",
    descriptionStatus: "",
  })),
  prerequisiteApperance: "Bullets",
  gettingStarted: {
    title: "",
    description: "",
    texts: Array.from(Array(3), (_, i) => ""),
    code: "",
    textType: "text",
  },
  gettingStartedValidation: {
    titleStatus: "",
    descriptionStatus: "",
    textsStatus: Array.from(Array(3), (_, i) => ""),
    codeStatus: "",
  },
  artifacts: {
    title: "",
    description: "",
    contentType: "file",
    code: "",
    content: "",
    fileRaw: "",
  },
  artifcatsValidation: {
    titleStatus: "",
    descriptionStatus: "",
    codeStatus: "",
    contentStatus: {
      fileName: "",
      status: "",
    },
  },
  resource: {
    description: "",
    contentType: "text",
    texts: Array.from(Array(3), (_, i) => ""),
    textsLink: Array.from(Array(3), (_, i) => ""),
    highlightText: Array.from(Array(3), (_, i) => ""),
    file: "",
    fileRaw: "",
  },
  resourceValidation: {
    descriptionStatus: "",
    textsStatus: Array.from(Array(3), (_, i) => ""),
    fileStatus: "",
  },
  mdFileUploadingStatus: {
    fileName: "",
    status: "",
  },
  gitHubUrlValidatity: {
    status: "",
    message: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_OVERVIEW":
      return { ...state, overview: action.payload.overview };
    case "SET_OVERVIEW_Validation":
      return {
        ...state,
        overviewValidation: action.payload.overviewValidation,
      };
    case "SET_COVER_IMAGE_UPLOADING_STATUS":
      return {
        ...state,
        coverImageUploadingStatus: action.payload.coverImageUploadingStatus,
      };
    case "SET_PREREQUISITES":
      return { ...state, prerequisites: action.payload.prerequisites };
    case "SET_PREREQUISITES_Validation":
      return {
        ...state,
        prerequsitiesValidation: action.payload.prerequsitiesValidation,
      };
    case "SET_PREREQUISITES_APPERANCE":
      return {
        ...state,
        prerequisiteApperance: action.payload.prerequisiteApperance,
      };
    case "SET_GETTING_STARTED":
      return { ...state, gettingStarted: action.payload.gettingStarted };
    case "SET_GETTING_STARTTED_Validation":
      return {
        ...state,
        gettingStartedValidation: action.payload.gettingStartedValidation,
      };
    case "SET_ARTIFACTS":
      return { ...state, artifacts: action.payload.artifacts };
    case "SET_ARTIFACTS_Validation":
      return {
        ...state,
        artifcatsValidation: action.payload.artifcatsValidation,
      };
    case "SET_RESOURCE":
      return { ...state, resource: action.payload.resource };
    case "SET_RESOURCE_Validation":
      return {
        ...state,
        resourceValidation: action.payload.resourceValidation,
      };
    case "SET_MD_FILE_UPLOADING_STATUS":
      return {
        ...state,
        mdFileUploadingStatus: action.payload.mdFileUploadingStatus,
      };
    case "SET_GITHUB_URL_VALIDITY":
      return {
        ...state,
        gitHubUrlValidatity: action.payload.gitHubUrlValidatity,
      };
    case "RESET_SIDEPANEL_INPUTS":
      return initialState;

    default:
      return state;
  }
};

const SidePanelProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SidePanel.Provider value={{ ...state, dispatch }}>
      {children}
    </SidePanel.Provider>
  );
};

export default SidePanelProvider;

export const useSidePanelContext = () => {
  return useContext(SidePanel);
};
