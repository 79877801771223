// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-anchor a, li.custom-list-item a {
    color: var(--neutral-text-text-cta, #50e6ff);
    font: 400 16px/24px "Segoe UI", sans-serif;
    text-decoration: none;
    font-weight: 600 !important;
}

a {
    color: var(--neutral-text-text-cta, #50e6ff);
    font: 400 16px/24px "Segoe UI", sans-serif;
    text-decoration: none;
    font-weight: 600 !important;
}

a:visited {
    color: var(--neutral-text-text-cta, #50e6ff);
    font: 400 16px/24px "Segoe UI", sans-serif;
    text-decoration: none;
    font-weight: 600 !important;
}

.custom-anchor:hover {
    border-style: solid;
    border-color: #50e6ff;
    border-width: 0px 0px 1px 0px;
}

.custom-anchor:focus {
    border-radius: 4px;
    border-style: dashed;
    border-color: #50e6ff;
    border-width: 1px;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Overrides/Anchor.css"],"names":[],"mappings":"AAAA;IACI,4CAA4C;IAC5C,0CAA0C;IAC1C,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,4CAA4C;IAC5C,0CAA0C;IAC1C,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,4CAA4C;IAC5C,0CAA0C;IAC1C,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,qBAAqB;IACrB,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".custom-anchor a, li.custom-list-item a {\n    color: var(--neutral-text-text-cta, #50e6ff);\n    font: 400 16px/24px \"Segoe UI\", sans-serif;\n    text-decoration: none;\n    font-weight: 600 !important;\n}\n\na {\n    color: var(--neutral-text-text-cta, #50e6ff);\n    font: 400 16px/24px \"Segoe UI\", sans-serif;\n    text-decoration: none;\n    font-weight: 600 !important;\n}\n\na:visited {\n    color: var(--neutral-text-text-cta, #50e6ff);\n    font: 400 16px/24px \"Segoe UI\", sans-serif;\n    text-decoration: none;\n    font-weight: 600 !important;\n}\n\n.custom-anchor:hover {\n    border-style: solid;\n    border-color: #50e6ff;\n    border-width: 0px 0px 1px 0px;\n}\n\n.custom-anchor:focus {\n    border-radius: 4px;\n    border-style: dashed;\n    border-color: #50e6ff;\n    border-width: 1px;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
