import { useEffect, useMemo, useState } from "react";
import Footer from "../../navigation/footer/Footer";
import "./DropCreation.css";
import DropCard from "../../dropCard/DropCard";
import Drpofilters from "../../../data/dropsFilter.json";
import { Link, json, useNavigate } from "react-router-dom";
import SidePanel from "./SidePanel";
import CardSchema from "./CardSchema";
import Modal from "react-modal";
import { useSidePanelContext } from "../../../context/SidepanelProvider";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ToolTip from "../../toolTip/toolTip";
import { downloadJSON } from "../../../utils/helper";
import CopyButtonTooltip from "../../CopyButtonTooltip/CopyButtonTooltip";

const DropCreation = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState(Drpofilters);
  const [showAccordian, setShowAccordian] = useState(
    Array.from(Array(4), (_, i) => false)
  );
  const [accordianStatus, setAccordianStatus] = useState(
    Array.from(Array(4), (_, i) => "Not started")
  );
  const [sidePanelAccordianStatus, setSidePanelAccordianStatus] = useState(
    Array.from(Array(5), (_, i) => "Not started")
  );

  const [expandAll, setExpandAll] = useState(false);
  const [creating, setCreating] = useState(true);
  const [cardCreationInProgress, setCardCreationInProgress] = useState(false);
  let [cardData, setCardData] = useState({
    Title: "Title",
    Summary: "Card description",
    Type: "script_automation",
    Authors: [{ Name: "Author", Link: "" }],
    Products: ["Tag", "Tag", "Tag", "Tag"],
    Source: "",
    ProgrammingLanguage: [],
    Difficulty: "",
    Topics: [],
    Type: "",
  });
  const [cardDataValidation, setCardDataValidation] = useState(
    Array.from(Array(3), (_, i) => "")
  );
  let [authorValidation, setAuthorValidation] = useState([
    { Name: "", Link: "" },
  ]);
  const [jsonUploadingStatus, setJsonUploadingStatus] = useState({
    fileName: "",
    status: "",
  });
  const [gitHubUrlValidatity, setGitHubUrlValidity] = useState({
    status: "",
    message: "",
    readOnly: false,
  });
  const [personalGitHubUrlValidatity, setPersonalGitHubUrlValidity] = useState({
    status: "",
    message: "",
  });
  const [authorInput, setAuthorInput] = useState();
  const [mdText, setMdText] = useState();
  const [uploadOption, setUploadOption] = useState(true);
  const [exsitingJsonSchema, setExistingJsonSchema] = useState();
  const [creatingDrop, setCreatingDrop] = useState(true);
  const [showFAQ, setShowFAQ] = useState(false);
  const [completeCreation, setCompleteCreation] = useState(false);
  const [sidePanelCreationStatusCompletd, setSidePanelCreationStatusCompleted] =
    useState(false);
  const [showAfterCreation, setShowAfterCreation] = useState(false);
  const [dropCreationStatus, setDropCreationStatus] = useState("Not started");
  const [sidePanelDropCreation, setSidePanelDropCreation] =
    useState("Not started");
  const [coverImage, setCoverImage] = useState();
  const [coverImageName, setCoverImageName] = useState();
  const [resourceImage, setResourceImage] = useState();
  const [resourceImageName, setResourceImageName] = useState();
  const [artifactImage, setArtifactImage] = useState();
  const [artifactImageName, setArtifactImageName] = useState();
  const [createdPullRequest, setCreatedPullRequest] = useState();
  const [githubPrefrence, setGithubPrefrence] = useState("jumpstart");
  const [progress, setProgress] = useState();
  const [progressCalculate, setProgressCalculate] = useState(0);
  const [sidePaneluploadOption, setSidePanelUploadOption] = useState(true);
  const [sidePanelCreating, setSidePanelCreating] = useState(true);
  const [usingExtarnalDropSchema, setUsingExternalDropSchema] = useState(false);
  const [isMdFileUploaded, setIsMdFileUploaded] = useState(false);
  const [tagFilter, setTagFilter] = useState({
    Product: "",
    Language: "",
    Topics: "",
    Type: "",
    Difficulty: "",
  });
  const [productTagsError, setProductTagsError] = useState({
    status: false,
    message: "",
  });
  const [addNewTag, setAddNewTag] = useState({
    Products: [],
    Language: [],
    Topics: [],
  });
  const [mdLink, setMdLink] = useState();
  const { dispatch } = useSidePanelContext();
  const [isUrlCopied, setIsUrlCopien] = useState(false);

  useEffect(() => {
    dispatch({
      type: "RESET_SIDEPANEL_INPUTS",
    });
  }, []);

  const handleOnCloseFAQ = () => {
    setShowFAQ(false);
  };
  const [seconds, setSeconds] = useState(20);
  useEffect(() => {
    let interval;
    if (completeCreation && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        setProgressCalculate((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (seconds === 0) {
      setCompleteCreation(false);
      setShowAfterCreation(true);
    }

    return () => clearInterval(interval);
  }, [completeCreation, seconds]);
  const countDown = () => {
    setSeconds(20);
    setCompleteCreation(true);
  };

  const handleCreateDrop = () => {
    if (githubPrefrence === "personal" && !sidePanelCreationStatusCompletd)
      setPersonalGitHubUrlValidity({
        status: "error",
        message: "Enter valid URL.",
      });
    if (
      (accordianStatus.includes("Not started") &&
        dropCreationStatus !== "Completed") ||
      (accordianStatus.includes("In progress") &&
        dropCreationStatus !== "Completed")
    )
      setDropCreationStatus("error");
    else if (
      (sidePanelAccordianStatus.includes("Not started") &&
        sidePanelCreationStatusCompletd !== true) ||
      (sidePanelAccordianStatus.includes("In progress") &&
        sidePanelCreationStatusCompletd !== true)
    )
      setSidePanelDropCreation("error");
    else {
      countDown();
      fetch(`${process.env.REACT_APP_API_URL}/createDrop`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          json: {
            ...cardData,
            Source:
              githubPrefrence === "jumpstart"
                ? `https://github.com/${
                    process.env.REACT_APP_GITHUB_ORG
                  }/arc_jumpstart_drops/tree/main/${
                    cardData.Type
                  }/${cardData.Title.replace(/\s+/g, "_").toLowerCase()}`
                : cardData.Source,
          },
          mdText: githubPrefrence === "jumpstart" && mdText,
          coverImage,
          coverImageName,
          resourceImage,
          resourceImageName,
          artifactImage,
          artifactImageName,
        }),
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }

          return response.json();
        })
        .then((data) => {
          setProgress(100);
          setSeconds(2);
          if (data.data.url) {
            let url = data.data.url;
            url = url.replace("api.", "");
            url = url.replace("repos/", "");
            url = url.replace("pulls", "pull");
            setCreatedPullRequest(url);
          } else setCreatedPullRequest(null);
        })
        .catch((error) => {
          console.error("There was a problem with the fetch operation:", error);
        });
    }
  };

  useMemo(() => {
    if (accordianStatus.every((val) => val === "Not started"))
      setDropCreationStatus("Not started");
    if (accordianStatus.some((val) => val === "In progress"))
      setDropCreationStatus("In progress");

    if (accordianStatus.every((val) => val === "Completed"))
      setDropCreationStatus("Completed");
  }, [accordianStatus]);

  useMemo(() => {
    if (sidePanelAccordianStatus.every((val) => val === "Not started"))
      setSidePanelDropCreation("Not started");
    if (sidePanelAccordianStatus.some((val) => val === "In progress"))
      setSidePanelDropCreation("In progress");

    if (sidePanelAccordianStatus.every((val) => val === "Completed"))
      setSidePanelDropCreation("Completed");

    if (sidePanelCreationStatusCompletd) setSidePanelDropCreation("Completed");
  }, [sidePanelAccordianStatus, sidePanelCreationStatusCompletd]);

  const mdLinkSetter = (Source) => {
    if (Source.includes("blob")) {
      const parts = Source.split("/blob");
      var newLink = parts[0] + parts[1];
    }
    if (Source.includes("tree")) {
      const parts = Source.split("/tree");
      var newLink = parts[0] + parts[1];
    }
    setMdLink(
      newLink
        ? `https://raw.githubusercontent.com${newLink.substring(18)}`
        : `https://raw.githubusercontent.com${Source.substring(18)}`
    );
  };
  useMemo(() => {
    if (usingExtarnalDropSchema) {
      if (mdLink?.includes("arc_jumpstart_drops") && mdLink?.includes("main"))
        var canaryLink = mdLink.replace(/\/main\//, "/canary/");
      else if (mdLink?.includes("arc_jumpstart_drops")) {
        // Find the index of "arc_jumpstart_drops/"
        const index = mdLink.indexOf("arc_jumpstart_drops/");

        if (index !== -1) {
          // Extract the first part of the URL until "arc_jumpstart_drops/"
          const firstPart = mdLink.substring(
            0,
            index + "arc_jumpstart_drops/".length
          );

          // Extract the second part of the URL after "arc_jumpstart_drops/"
          const secondPart = mdLink.substring(
            index + "arc_jumpstart_drops/".length
          );
          var canaryLink = firstPart + "canary/";
        }
      }
      if (mdLink) {
        const urls = [
          `${mdLink}/main/README.md`,
          `${mdLink}/master/README.md`,
          `${mdLink}/README.md`,
          `${mdLink}/index.md`,
          `${mdLink}/main/index.md`,
          `${mdLink}/master/index.md`,
          `${mdLink}/_index.md`,
          `${mdLink}/main/_index.md`,
          `${mdLink}/master/_index.md`,
          `${canaryLink && `${canaryLink}/_index.md`}`,
          `${canaryLink && `${canaryLink}/index.md`}`,
        ];

        const promises = urls.map((url) => fetch(url));

        Promise.all(promises)
          .then((responses) => {
            const results = responses.map((response) => response.text());
            return Promise.all(results);
          })
          .then((data) => {
            const mdData = data.find((text) => text.length > 15);
            setMdText(mdData);
          })
          .catch((error) => console.error(error));
      }
    }
  }, [usingExtarnalDropSchema]);

  function getFilterValues(filterName) {
    const filter = filters.find((f) => f.name === filterName);
    return filter
      ? filter.tags.map((tag) => (filterName === "Type" ? tag.value : tag.name))
      : [];
  }

  function validateJsonData(jsonData) {
    let results = "";
    if (!getFilterValues("Type").includes(jsonData.Type))
      results = "Type is not valid";
    else if (!getFilterValues("Difficulty").includes(jsonData.Difficulty))
      results = "Difficulty is not valid";
    return results;
  }

  const handleJsonFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    if (event.target.files[0])
      setJsonUploadingStatus({
        fileName: event.target.files[0]?.name,
        status: event.target.files[0]?.name?.includes("json")
          ? "success"
          : "error",
        size: event.target.files[0]?.size,
        showProgress: true,
        message:
          !event.target.files[0]?.name?.includes("json") && "Invalid file",
      });

    reader.onload = (e) => {
      try {
        const parsedData = JSON.parse(e.target.result);

        const keys = Object.keys(parsedData);
        const requiredKeys = [
          "Title",
          "Source",
          "Summary",
          "Description",
          "Cover",
          "Type",
          "Authors",
          "Products",
          "ProgrammingLanguage",
          "Difficulty",
          "Topics",
          "LastModified",
          "CreatedDate",
        ];
        if (requiredKeys.every((key) => keys.includes(key))) {
          if (keys.length > 13)
            setTimeout(() => {
              setJsonUploadingStatus({
                fileName: event.target.files[0]?.name,
                size: event.target.files[0]?.size,
                status: "error",
                showProgress: false,
                message: "Extra keys are not allowed.",
              });
              setShowFAQ(true);
            }, 1000);
          else if (validateJsonData(parsedData))
            setTimeout(() => {
              setJsonUploadingStatus({
                fileName: event.target.files[0]?.name,
                size: event.target.files[0]?.size,
                status: "error",
                showProgress: false,
                message: validateJsonData(parsedData),
              });
              setShowFAQ(true);
            }, 1000);
          else {
            setDropCreationStatus("Completed");
            setSidePanelCreationStatusCompleted(true);
            setCardData({
              ...parsedData,
              Summary: parsedData.Summary.slice(0, 250),
              Title: parsedData.Title.slice(0, 100),
            });
            setUsingExternalDropSchema(true);
            mdLinkSetter(parsedData?.Source);

            setTimeout(
              () =>
                setJsonUploadingStatus({
                  fileName: event.target.files[0]?.name,
                  size: event.target.files[0]?.size,
                  status: "success",
                  showProgress: false,
                }),
              1000
            );
          }
        } else {
          const key = requiredKeys.filter((key) => !keys.includes(key));

          setTimeout(() => {
            setJsonUploadingStatus({
              fileName: event.target.files[0]?.name,
              size: event.target.files[0]?.size,
              status: "error",
              showProgress: false,
              message: `The json file should include a ${key}. Please refer to the Drop Schema.`,
            });
            setShowFAQ(true);
            event.target.value = null;
          }, 1000);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    try {
      reader.readAsText(file);
    } catch {
      console.log("An error occured");
    }
  };
  const handleJsonFileDrop = (event) => {
    const file = event.dataTransfer.files[0];
    if (event.target.files[0])
      setJsonUploadingStatus({
        fileName: event.target.files[0]?.name,
        status: event.target.files[0]?.name?.includes("json")
          ? "success"
          : "error",
        size: event.target.files[0]?.size,
        showProgress: true,
        message:
          !event.target.files[0]?.name?.includes("json") && "Invalid file.",
      });
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const parsedData = JSON.parse(e.target.result);
        const keys = Object.keys(parsedData);
        const requiredKeys = [
          "Title",
          "Source",
          "Summary",
          "Description",
          "Cover",
          "Type",
          "Authors",
          "Products",
          "ProgrammingLanguage",
          "Difficulty",
          "Topics",
          "LastModified",
          "CreatedDate",
        ];
        if (requiredKeys.every((key) => keys.includes(key))) {
          if (keys.length > 13) {
            setTimeout(
              () =>
                setJsonUploadingStatus({
                  fileName: event.target.files[0]?.name,
                  size: event.target.files[0]?.size,
                  status: "error",
                  showProgress: false,
                  message: "Extra keys are not allowed.",
                }),
              1000
            );
            setShowFAQ(true);
          } else if (keys[0].length > 100)
            setTimeout(() => {
              setJsonUploadingStatus({
                fileName: event.target.files[0]?.name,
                size: event.target.files[0]?.size,
                status: "error",
                showProgress: false,
                message: "Title should be less than 100 characters.",
              });
              setShowFAQ(true);
            }, 1000);
          else if (validateJsonData(parsedData))
            setTimeout(() => {
              setJsonUploadingStatus({
                fileName: event.target.files[0]?.name,
                size: event.target.files[0]?.size,
                status: "error",
                showProgress: false,
                message: validateJsonData(parsedData),
              });
              setShowFAQ(true);
            }, 1000);
          else {
            setDropCreationStatus("Completed");
            setSidePanelCreationStatusCompleted(true);
            setCardData({
              ...parsedData,
              Summary: parsedData.Summary.slice(0, 250),
              Title: parsedData.Title.slice(0, 100),
            });
            setUsingExternalDropSchema(true);
            mdLinkSetter(parsedData?.Source);

            setTimeout(
              () =>
                setJsonUploadingStatus({
                  fileName: event.target.files[0]?.name,
                  size: event.target.files[0]?.size,
                  status: "success",
                  showProgress: false,
                }),
              1000
            );
          }
        } else {
          const key = requiredKeys.filter((key) => !keys.includes(key));

          setTimeout(() => {
            setJsonUploadingStatus({
              fileName: event.target.files[0]?.name,
              size: event.target.files[0]?.size,
              status: "error",
              showProgress: false,
              message: `The json file should include a ${key}. Please refer to the Drop Schema.`,
            });
            setShowFAQ(true);
          }, 1000);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    };

    try {
      reader.readAsText(file);
    } catch {
      console.log("An error occured.");
    }
  };

  const handleDropGithubUrl = async (event) => {
    if (event.target.value.includes(".json")) {
      if (event.target.value.includes("blob")) {
        const parts = event.target.value.split("/blob");
        var newLink = parts[0] + parts[1];
      }
      if (event.target.value.includes("tree")) {
        const parts = event.target.value.split("/tree");
        var newLink = parts[0] + parts[1];
      }

      const githubLink = newLink
        ? `https://raw.githubusercontent.com${newLink.substring(18)}`
        : `https://raw.githubusercontent.com${event.target.value.substring(
            18
          )}`;

      try {
        const response = await fetch(githubLink);
        const data = await response.json();
        const keys = Object.keys(data);
        const requiredKeys = [
          "Title",
          "Source",
          "Summary",
          "Description",
          "Cover",
          "Type",
          "Authors",
          "Products",
          "ProgrammingLanguage",
          "Difficulty",
          "Topics",
          "LastModified",
          "CreatedDate",
        ];
        if (requiredKeys.every((key) => keys.includes(key))) {
          if (keys.length > 13) {
            setGitHubUrlValidity({
              status: "error",
              message: "Extra keys are not allowed.",
              readOnly: false,
              input: event.target.value,
            });
            setShowFAQ(true);
          } else if (validateJsonData(data)) {
            setGitHubUrlValidity({
              status: "error",
              message: validateJsonData(data),
              readOnly: false,
              input: event.target.value,
            });
            setShowFAQ(true);
          } else {
            setDropCreationStatus("Completed");
            setCardData({
              ...data,
              Summary: data.Summary.slice(0, 250),
              Title: data.Title.slice(0, 100),
            });
            setUsingExternalDropSchema(true);
            setSidePanelCreationStatusCompleted(true);
            mdLinkSetter(data?.Source);
            setGitHubUrlValidity({
              url: event.target.value,
              status: "",
              message: "",
              readOnly: true,
              input: event.target.value,
            });
          }
        } else {
          const key = requiredKeys.filter((key) => !keys.includes(key));
          setShowFAQ(true);
          setGitHubUrlValidity({
            status: "error",
            message: `The json file should include a ${key[0]}. Please refer to the Drop Schema.`,
            input: event.target.value,
          });
        }
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    } else
      setGitHubUrlValidity({
        status: "error",
        message: "The URL should contain JSON.",
        input: event.target.value,
      });
  };

  return (
    <div className="creation-wizard">
      <div className="creation-header">
        <div
          onClick={() => navigate("/azure_jumpstart_drops")}
          style={{ cursor: "pointer" }}
        >
          <img src="./icon/creation-back-arrow.svg" alt="" />
          <span>Create a Drop</span>
        </div>

        <div className="cta-btns mta-btns">
          <div
            className={
              dropCreationStatus === "Not started"
                ? "nta-btns"
                : dropCreationStatus === "In progress"
                ? "pta-btns"
                : dropCreationStatus === "error"
                ? "eta-btns"
                : "cota-btns"
            }
          >
            <input
              type="radio"
              id="createDrop"
              name="gender"
              value=""
              style={{ background: "none" }}
              checked={creatingDrop}
              onClick={() => setCreatingDrop(true)}
            />
            <label for="createDrop">Card details</label>
          </div>
          <div
            className={
              sidePanelDropCreation === "Not started"
                ? "nta-btns"
                : sidePanelDropCreation === "In progress"
                ? "pta-btns"
                : sidePanelDropCreation === "error"
                ? "eta-btns"
                : "cota-btns"
            }
          >
            <input
              type="radio"
              id="sidePanel"
              name="gender"
              value="F"
              checked={!creatingDrop}
              onClick={() => {
                if (
                  githubPrefrence === "personal" &&
                  !sidePanelCreationStatusCompletd
                ) {
                  setSidePanelDropCreation("error");
                  setPersonalGitHubUrlValidity({
                    status: "error",
                    message: "Enter valid URL.",
                  });
                } else setCreatingDrop(false);
              }}
            />
            <label for="sidePanel">Side panel details</label>
          </div>
        </div>
        <button className="creation-btn" onClick={handleCreateDrop}>
          <img
            className="normal-drop-creation-icon"
            src="../icon/creation-drop-light.svg"
            alt=""
          />
          <img
            className="hovered-drop-creation-icon"
            src="../icon/creation-drop-light-hover.svg"
            alt=""
          />
          Create
        </button>
      </div>
      <div className="creation-drops">
        {creatingDrop ? (
          <>
            {!showFAQ ? (
              <div className="card-preview" id="card-preview" style={{}}>
                <p className="dropcard-heading" htmlFor="">
                  Card display
                  <ToolTip
                    title={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        This shows a preview of the the card display that will
                        be created when this drop is approved and published.
                        Information is shown based on what's provided in the
                        "card details" section.
                        <span>
                          Learn more in the{" "}
                          <a
                            style={{
                              fontSize: "var(--Size-200, 12px)",
                              fontStyle: "normal",
                              fontWeight: 400,
                              lineHeight: "var(--Line-height-200, 16px)",
                              cursor: "pointer",
                            }}
                            href="/drops_contribution_guidelines"
                            target="_blank"
                          >
                            Drops contribution guidelines
                          </a>{" "}
                        </span>
                      </div>
                    }
                    height={100}
                    width={300}
                  >
                    <div className="info-icon">
                      <img
                        className="normal-info-icon"
                        src="./images/drops/sidebar/info-icon.svg"
                        alt=""
                      />

                      <img
                        className="hovered-info-icon"
                        src="./images/drops/sidebar/info-icon-hover.svg"
                        alt=""
                      />
                    </div>
                  </ToolTip>
                  <button className="wizard-preview">PREVIEW</button>
                </p>
                <DropCard cardData={cardData} />
              </div>
            ) : null}
            <div
              className="card-details"
              style={{
                marginLeft: showFAQ ? "0" : "20px",
                width: "60%",
                maxHeight: "1000px",
                overflow: "scroll",
              }}
            >
              <span>
                Card details
                <ToolTip
                  title={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      Complete this section according to this drop's JSON
                      schema. This information provided will be used for search,
                      filters, and the drop card.
                      <span>
                        Learn more in the{" "}
                        <a
                          style={{
                            fontSize: "var(--Size-200, 12px)",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "var(--Line-height-200, 16px)",
                            cursor: "pointer",
                          }}
                          href="/drops_contribution_guidelines"
                          target="_blank"
                        >
                          Drops contribution guidelines
                        </a>{" "}
                      </span>
                    </div>
                  }
                  height={80}
                  width={280}
                >
                  <div className="info-icon">
                    <img
                      className="normal-info-icon"
                      src="./images/drops/sidebar/info-icon.svg"
                      alt=""
                    />

                    <img
                      className="hovered-info-icon"
                      src="./images/drops/sidebar/info-icon-hover.svg"
                      alt=""
                    />
                  </div>
                </ToolTip>
                <button>
                  {" "}
                  <div className="wizard-edit">EDIT</div>
                </button>
              </span>
              <div>
                <img src="../icon/card-details-creation.svg" alt="" />
              </div>{" "}
              <div className="card-details-cta-btns">
                <div
                  className="outline-cta-btns"
                  style={{
                    outline:
                      githubPrefrence === "jumpstart"
                        ? "1px solid #50e6ff"
                        : "",
                  }}
                >
                  <input
                    type="radio"
                    id="jumpstart-repo"
                    name="jumpstart-repo"
                    value="step-by-step"
                    checked={githubPrefrence === "jumpstart"}
                    onClick={() => setGithubPrefrence("jumpstart")}
                  />
                  <label for="jumpstart-repo">
                    Create in Drops GitHub repository
                  </label>
                </div>
                <div
                  className="outline-cta-btns"
                  style={{
                    outline:
                      githubPrefrence === "personal"
                        ? "1px solid #50e6ff "
                        : "",
                    paddingRight: githubPrefrence === "personal" ? "18px" : "",
                  }}
                >
                  <input
                    type="radio"
                    id="personal-repo"
                    name="personal-repo"
                    value="upload"
                    checked={githubPrefrence === "personal"}
                    onClick={() => setGithubPrefrence("personal")}
                  />
                  <label for="personal-repo">
                    Create in personal GitHub repository
                  </label>
                </div>
              </div>
              {githubPrefrence === "personal" ? (
                <div
                  style={{
                    marginTop: "12px",
                  }}
                >
                  <input
                    type="text"
                    className="github-input"
                    placeholder="Enter GitHub URL"
                    style={{
                      border:
                        personalGitHubUrlValidatity.status === "error" &&
                        "1px solid #f87c86",
                    }}
                    id="pre-req"
                    value={cardData.Source}
                    onChange={(e) => {
                      setCardData({ ...cardData, Source: e.target.value });
                      const gitLink = e.target.value.split("/");
                      if (gitLink.length === 5 && gitLink[2] === "github.com") {
                        setSidePanelCreationStatusCompleted(true);
                        setPersonalGitHubUrlValidity({
                          status: "",
                          message: "",
                        });
                      } else
                        setPersonalGitHubUrlValidity({
                          status: "error",
                          message: "The URL should be from GitHub.",
                        });
                      !e.target.value.includes("http://") &&
                      !e.target.value.includes("https://")
                        ? setPersonalGitHubUrlValidity({
                            status: "error",
                            message:
                              "The URL is not valid. Make sure it starts with http:// or https://.",
                          })
                        : !e.target.value.includes("github")
                        ? setPersonalGitHubUrlValidity({
                            status: "error",
                            message: "The URL should be from GitHub",
                          })
                        : setPersonalGitHubUrlValidity({
                            status: "",
                            message: "",
                          });
                    }}
                    onBlur={(e) =>
                      !e.target.value &&
                      setPersonalGitHubUrlValidity({
                        status: "error",
                        message: "Enter personal repository URL",
                      })
                    }
                  />
                  {personalGitHubUrlValidatity.status === "error" && (
                    <div className="validation">
                      <img src="./icon/input-error.svg" />

                      <span className="error-message">
                        {personalGitHubUrlValidatity.message}
                      </span>
                    </div>
                  )}
                  {personalGitHubUrlValidatity.status !== "error" &&
                    cardData.Source && (
                      <a
                        href={cardData.Source}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <div className="uploading-status">
                          <div className="uploading-status-body">
                            <div className="file-head">
                              <img src="./icon/link-logo.svg" />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>Personal GitHub URL</span>
                                <span className="file-size">
                                  {cardData.Source}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p>Open link</p>
                                <a
                                  href={cardData.Source}
                                  target="_blank"
                                  style={{ marginTop: "5px" }}
                                >
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src="./icon/open-link.svg"
                                  />
                                </a>
                              </div>
                              {}
                            </div>
                          </div>
                          {}
                        </div>
                      </a>
                    )}
                </div>
              ) : null}
              <div
                style={{
                  backgroundColor: "#2F3646",
                  color: "#B2CDFF",
                  paddingLeft: "10px",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                  padding: "14px 10px",
                  margin: "12px 0 10px 0",
                  borderRadius: "10px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  {" "}
                  <img src="./icon/note-info.svg" style={{}} />{" "}
                  <span style={{ marginLeft: "5px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "20px",
                        borderRadius: "10px",
                      }}
                    >
                      Note:
                    </span>{" "}
                    Creating a Drop in the Drops GitHub repository provides more
                    functionalities such as automatically creating folders and
                    being able to use our side panel creation experience.{" "}
                    <span style={{ color: "#B2CDFF" }}>
                      {" "}
                      If you want complete ownership,
                    </span>
                    <span style={{ color: "rgb(80, 230, 255)" }}>
                      {" "}
                      <Link
                        to="/drops_contribution_guidelines"
                        target="_blank"
                        style={{ fontSize: "14px" }}
                      >
                        create in personal GitHub repository.
                      </Link>
                    </span>
                  </span>
                </span>
              </div>
              <div className="card-details-cta-btns">
                <div
                  className="outline-cta-btns"
                  style={{ outline: creating ? "1px solid #50e6ff" : "" }}
                >
                  <input
                    type="radio"
                    id="step-by-step"
                    name="creation-option"
                    value="step-by-step"
                    checked={creating}
                    onClick={() => {
                      if (dropCreationStatus !== "Completed") {
                        setCreating(true);
                        setDropCreationStatus("Not started");
                      }
                    }}
                  />
                  <label for="step-by-step">
                    Create with step-by-step guidance
                  </label>
                </div>
                <div
                  className="outline-cta-btns"
                  style={{ outline: !creating ? "1px solid #50e6ff" : "" }}
                >
                  <input
                    type="radio"
                    id="upload"
                    name="creation-option"
                    value="upload"
                    checked={!creating}
                    onClick={() =>
                      dropCreationStatus !== "Completed" && setCreating(false)
                    }
                  />
                  <label for="upload">Create with existing JSON schema</label>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "#2F3646",
                  color: "#B2CDFF",

                  paddingLeft: "10px",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "20px",
                  padding: "14px 10px",
                  margin: "12px 0 10px 0",
                  borderRadius: "10px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "start",
                  }}
                >
                  {" "}
                  <img src="./icon/note-info.svg" style={{}} />{" "}
                  <span style={{ marginLeft: "5px" }}>
                    <span
                      style={{
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "20px",
                      }}
                    >
                      Note:
                    </span>{" "}
                    {creating
                      ? "Complete the fields below and we will create a Drop using GitHub mechanisms. You will be able to make changes later in GitHub."
                      : " We will parse the information from the file or URL you provide and auto-populate the Drop for you. You will be able to make changes later in GitHub."}
                  </span>
                </span>
              </div>
              {!creating ? (
                <>
                  <div className="card-details-cta-btns">
                    <div
                      className="outline-cta-btns"
                      style={{
                        outline: uploadOption ? "1px solid #50e6ff" : "",
                      }}
                      onClick={() =>
                        dropCreationStatus !== "Completed" &&
                        setUploadOption(true)
                      }
                    >
                      <input
                        type="radio"
                        id="upload1"
                        name="uploading-option"
                        value="upload-a-file"
                        checked={uploadOption}
                      />
                      <label for="upload1">Upload file</label>
                    </div>
                    <div
                      className="outline-cta-btns"
                      style={{
                        outline: !uploadOption ? "1px solid #50e6ff" : "",
                      }}
                      onClick={() =>
                        dropCreationStatus !== "Completed" &&
                        setUploadOption(false)
                      }
                    >
                      <input
                        type="radio"
                        id="upload2"
                        name="uploading-option"
                        value="upload-a-url"
                        checked={!uploadOption}
                      />
                      <label for="upload2">Enter URL</label>
                    </div>
                  </div>
                  {uploadOption && (
                    <>
                      <div className="uploading-asset">
                        <div>
                          <img src="./icon/uploading-asset.svg" alt="" />
                        </div>
                        <span>Drag and drop or</span>
                        <input
                          type="file"
                          style={{
                            opacity: 0,
                            position: "absolute",
                            marginTop: "30px",
                            padding: "40px 200px 100px 200px",
                            width: "auto",
                            cursor: "pointer",
                          }}
                          title={jsonUploadingStatus.fileName}
                          readOnly={dropCreationStatus === "Completed"}
                          accept=".json"
                          onChange={(e) => {
                            handleJsonFileUpload(e);
                            setTimeout(() => (e.target.value = null), 1500);
                          }}
                          onDrop={(e) => {
                            handleJsonFileDrop(e);
                            setTimeout(() => (e.target.value = null), 1500);
                          }}
                        />
                        <button className="choose-file">
                          Choose file{" "}
                          <input type="file" id="myfile" name="myfile" />
                        </button>

                        <p>JSON</p>
                      </div>
                      {jsonUploadingStatus.fileName && (
                        <div
                          className="uploading-status"
                          style={{
                            border:
                              jsonUploadingStatus.status === "error" &&
                              "1px solid #f87c86",
                            marginTop: "10px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            downloadJSON(cardData, "drops");
                          }}
                        >
                          <div className="uploading-status-body">
                            <div className="file-head">
                              <img src="./icon/file.svg" />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>{jsonUploadingStatus.fileName}</span>
                                <span className="file-size">
                                  {jsonUploadingStatus.size} KB
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p>Download</p>
                                <img
                                  style={{ cursor: "pointer" }}
                                  src="./icon/download_status.svg"
                                />
                              </div>
                              <img
                                style={{ cursor: "pointer" }}
                                src="./icon/close-modal.svg"
                                width={15}
                                height={15}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDropCreationStatus("Not started");
                                  setSidePanelCreationStatusCompleted(false);
                                  setUsingExternalDropSchema(false);
                                  setCardData({
                                    Title: "Title",
                                    Summary: "Card description",
                                    Type: "script_automation",
                                    Authors: [{ Name: "Author", Link: "" }],
                                    Products: ["Tag", "Tag", "Tag", "Tag"],
                                    Source: "",
                                    ProgrammingLanguage: [],
                                    Difficulty: "",
                                    Topics: [],
                                    Type: "",
                                  });
                                  setJsonUploadingStatus({
                                    fileName: "",
                                    status: "",
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {jsonUploadingStatus.showProgress && (
                            <div class="upload-progress-bar"></div>
                          )}
                        </div>
                      )}
                      {jsonUploadingStatus.status === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            {jsonUploadingStatus.message}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                  {!uploadOption && (
                    <div className="uploading-git-url">
                      <input
                        className="accord2-inp"
                        type="text"
                        value={gitHubUrlValidatity?.input}
                        htmlFor="title2"
                        placeholder="Enter GitHub URL"
                        readOnly={gitHubUrlValidatity.readOnly}
                        style={{
                          marginTop: "15px",
                          border:
                            gitHubUrlValidatity.status === "error" &&
                            "1px solid #f87c86",
                        }}
                        onChange={handleDropGithubUrl}
                        onBlur={(e) => {
                          if (gitHubUrlValidatity.status !== "error") {
                            (!e.target.value.includes("http://") &&
                              !e.target.value.includes("https://")) ||
                            !e.target.value.includes(".json")
                              ? setGitHubUrlValidity({
                                  ...gitHubUrlValidatity,
                                  status: "error",
                                  message:
                                    "The URL is not valid. Make sure it starts with http:// or https://.",
                                  input: e.target.value,
                                })
                              : setGitHubUrlValidity({
                                  ...gitHubUrlValidatity,
                                  status: "",
                                  message: "",
                                  input: e.target.value,
                                });
                          }
                        }}
                      />
                      {gitHubUrlValidatity.status === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            {gitHubUrlValidatity.message}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                  {gitHubUrlValidatity.status !== "error" &&
                    gitHubUrlValidatity?.url && (
                      <a
                        href={gitHubUrlValidatity.input}
                        target="_blank"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <div className="uploading-status">
                          <div className="uploading-status-body">
                            <div className="file-head">
                              <img src="./icon/link-logo.svg" />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span>JSON GitHub URL</span>
                                <span className="file-size">
                                  {gitHubUrlValidatity?.url}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p>Open link</p>
                                <a
                                  href={gitHubUrlValidatity.input}
                                  target="_blank"
                                  style={{ marginTop: "5px" }}
                                >
                                  <img
                                    style={{ cursor: "pointer" }}
                                    src="./icon/open-link.svg"
                                  />
                                </a>
                              </div>
                              {}
                            </div>
                          </div>
                          {}
                        </div>
                      </a>
                    )}
                  <div
                    className="schema-upload"
                    onClick={() => setShowFAQ(true)}
                  >
                    <p>
                      What is the schema definition for creating a Drops card?
                    </p>
                  </div>
                  <span
                    style={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "20px",
                      color: "#50e6ff",
                      cursor: "pointer",
                      maxWidth: "234px",
                    }}
                    onClick={() => setShowFAQ(true)}
                  >
                    Is there an example of the schema?
                  </span>
                </>
              ) : null}
              {creating ? (
                <>
                  <div
                    className="all-accordians"
                    onClick={() => {
                      setShowAccordian(
                        expandAll
                          ? showAccordian.map(() => false)
                          : showAccordian.map(() => true)
                      );
                      setExpandAll(!expandAll);
                    }}
                  >
                    <p>{expandAll ? "Collapse all" : "Expand all"}</p>
                    <img
                      src="../icon/chevron-down-accord.svg"
                      alt=""
                      style={{
                        transform: expandAll
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                        transition: "0.3s ease",
                      }}
                    />
                  </div>
                  <div class="accordion">
                    <div
                      class="accordion-header"
                      onClick={() =>
                        setShowAccordian(
                          showAccordian.map((val, i) => (i === 0 ? !val : val))
                        )
                      }
                    >
                      <p>
                        {" "}
                        Enter title <span className="validation-accord">*</span>
                      </p>
                      <div>
                        <div className="accordion-status">
                          {" "}
                          {accordianStatus[0]}
                        </div>
                        <img
                          src="../icon/chevron-down-acc-header.svg"
                          alt=""
                          onClick={() =>
                            setShowAccordian(
                              showAccordian.map((val, i) =>
                                i === 0 ? !val : val
                              )
                            )
                          }
                          style={{
                            transform: showAccordian[0]
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    {showAccordian[0] ? (
                      <div>
                        <div className="header-border-bot"></div>
                      </div>
                    ) : null}

                    <div
                      class="accordion-content"
                      style={{
                        transition: "1s ease",
                        display: showAccordian[0] ? "flex" : "none",
                      }}
                    >
                      <input
                        type="text"
                        name="title"
                        style={{
                          border:
                            cardDataValidation[0] === "error" &&
                            "1px solid #f87c86",
                        }}
                        value={cardData.Title === "Title" ? "" : cardData.Title}
                        placeholder="Enter title"
                        maxLength="100"
                        onChange={(e) => {
                          setCardData({ ...cardData, Title: e.target.value });

                          accordianStatus[0] !== "In progress" &&
                            setAccordianStatus(
                              accordianStatus.map((val, i) =>
                                i === 0 ? "In progress" : val
                              )
                            );
                        }}
                        onBlur={() =>
                          cardData.Title !== "Title" &&
                          cardData.Title.length > 3
                            ? setCardDataValidation(
                                cardDataValidation.map((validation, i) =>
                                  i === 0 ? "" : validation
                                )
                              )
                            : setCardDataValidation(
                                cardDataValidation.map((validation, i) =>
                                  i === 0 ? "error" : validation
                                )
                              )
                        }
                      />
                      {cardDataValidation[0] === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            Character length should be greater than 3.
                          </span>
                        </div>
                      )}

                      <label style={{}} htmlFor="title">
                        Character limit is 100
                      </label>

                      <div
                        className={
                          cardData.Title !== "Title" &&
                          cardData.Title.length > 3
                            ? "next-btn"
                            : "next-btn-disabled"
                        }
                      >
                        {" "}
                        <button
                          style={{ position: "relative" }}
                          onClick={() => {
                            cardData.Title !== "Title" &&
                              cardData.Title.length > 3 &&
                              setShowAccordian(
                                showAccordian.map((val, i) =>
                                  i === 0 ? false : i === 1 ? true : false
                                )
                              );
                            if (
                              cardData.Title !== "Title" &&
                              cardData.Title.length > 3
                            )
                              setAccordianStatus(
                                accordianStatus.map((val, i) =>
                                  i === 0 ? "Completed" : val
                                )
                              );
                          }}
                        >
                          Next section
                          <img
                            style={{}}
                            src="../icon/next-section-accord.svg"
                            alt=""
                          />
                          <img
                            className="hovered-save"
                            src="../icon/next-section-hover-accord.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="accordion">
                    {/* 2 */}
                    <div
                      class="accordion-header"
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) => (i === 1 ? !val : val))
                        );
                      }}
                    >
                      <p>
                        {" "}
                        Add authors <span className="validation-accord">*</span>
                      </p>
                      <div>
                        <div className="accordion-status">
                          {accordianStatus[1]}
                        </div>
                        <img
                          src="../icon/chevron-down-acc-header.svg"
                          alt=""
                          onClick={() => {
                            setShowAccordian(
                              showAccordian.map((val, i) =>
                                i === 1 ? !val : val
                              )
                            );

                            setAccordianStatus(
                              accordianStatus.map((val, i) =>
                                i === 1 ? "In progress" : val
                              )
                            );
                          }}
                          style={{
                            transform: showAccordian[1]
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    {showAccordian[1] ? (
                      <div>
                        <div className="header-border-bot"></div>
                      </div>
                    ) : null}
                    {showAccordian[1] ? (
                      <div class="accordion-content">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <label className="authors" htmlFor="title">
                            Author(s){" "}
                            <span className="validation-accord">*</span>
                          </label>

                          {cardData.Authors.map((author, i) => (
                            <>
                              <div
                                className="author-url"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  gap: "5px",
                                }}
                              >
                                <input
                                  type="text"
                                  name="title"
                                  placeholder="Enter name"
                                  value={
                                    author.Name === "Author" ? "" : author.Name
                                  }
                                  style={{
                                    border:
                                      authorValidation[i]?.Name === "error" &&
                                      "1px solid #f87c86",
                                  }}
                                  onBlur={() => {
                                    author.Name.length > 3
                                      ? setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? { ...validation, Name: "" }
                                                : validation
                                          )
                                        )
                                      : setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? {
                                                    ...validation,
                                                    Name: "error",
                                                  }
                                                : validation
                                          )
                                        );

                                    if (
                                      cardData.Authors.find((author, j) =>
                                        i === j
                                          ? false
                                          : authorValidation[j].Name === "error"
                                          ? false
                                          : author.Name &&
                                            author.Name ===
                                              cardData.Authors[i].Name
                                      )
                                    )
                                      setAuthorValidation(
                                        authorValidation.map((validation, j) =>
                                          j === i
                                            ? {
                                                ...validation,
                                                Name: "error",
                                              }
                                            : validation
                                        )
                                      );
                                    else if (author.Name.length > 3)
                                      setAuthorValidation(
                                        authorValidation.map((validation, j) =>
                                          j === i
                                            ? { ...validation, Name: "" }
                                            : validation
                                        )
                                      );
                                  }}
                                  onChange={(e) => {
                                    setCardData({
                                      ...cardData,
                                      Authors: cardData.Authors.map((aut, j) =>
                                        j === i
                                          ? {
                                              ...aut,
                                              Name: e.target.value,
                                            }
                                          : aut
                                      ),
                                    });
                                    author.Name.length > 3 &&
                                      setAuthorValidation(
                                        authorValidation.map((validation, j) =>
                                          j === i
                                            ? { ...validation, Name: "" }
                                            : validation
                                        )
                                      );

                                    //   setCardDataValidation(
                                    //     cardDataValidation.map(
                                    //       (validation, i) =>
                                    //         i === 1 ? "" : validation
                                    //     )
                                    //   );
                                    //   setAuthorInput("");
                                    // } else
                                    //   setCardDataValidation(
                                    //     cardDataValidation.map(
                                    //       (validation, i) =>
                                    //         i === 1 ? "error" : validation
                                    //     )
                                    //   );

                                    if (accordianStatus[1] !== "In progress")
                                      setAccordianStatus(
                                        accordianStatus.map((val, i) =>
                                          i === 1 ? "In progress" : val
                                        )
                                      );
                                  }}
                                  maxLength={25}
                                />

                                <div style={{ display: "flex", gap: "8px" }}>
                                  <input
                                    className="accord2-inp"
                                    type="text"
                                    style={{
                                      border:
                                        authorValidation[i]?.Link === "error" &&
                                        "1px solid #f87c86",
                                    }}
                                    htmlFor="title2"
                                    placeholder="Enter social profile URL"
                                    value={author.Link}
                                    onBlur={(e) => {
                                      const urlPattern =
                                        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}/g;

                                      if (urlPattern.test(author.Link)) {
                                        setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? { ...validation, Link: "" }
                                                : validation
                                          )
                                        );
                                      } else {
                                        setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? {
                                                    ...validation,
                                                    Link: "error",
                                                  }
                                                : validation
                                          )
                                        );
                                      }

                                      if (
                                        cardData.Authors.find((author, j) =>
                                          i === j
                                            ? false
                                            : authorValidation[j].Link ===
                                              "error"
                                            ? false
                                            : author.Link ===
                                              cardData.Authors[i].Link
                                        )
                                      )
                                        setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? {
                                                    ...validation,
                                                    Link: "error",
                                                  }
                                                : validation
                                          )
                                        );
                                      setCardData({
                                        ...cardData,
                                        Authors: cardData.Authors.map(
                                          (aut, j) =>
                                            j === i
                                              ? {
                                                  ...aut,
                                                  Link: e.target.value,
                                                }
                                              : aut
                                        ),
                                      });
                                    }}
                                    onChange={(e) => {
                                      const urlPattern =
                                        /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}/g;

                                      if (urlPattern.test(author.Link)) {
                                        setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? { ...validation, Link: "" }
                                                : validation
                                          )
                                        );
                                      } else {
                                        setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? {
                                                    ...validation,
                                                    Link: "error",
                                                  }
                                                : validation
                                          )
                                        );
                                      }

                                      if (
                                        cardData.Authors.find((author, j) =>
                                          i === j
                                            ? false
                                            : authorValidation[j].Link ===
                                              "error"
                                            ? false
                                            : author.Link ===
                                              cardData.Authors[i].Link
                                        )
                                      )
                                        setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? {
                                                    ...validation,
                                                    Link: "error",
                                                  }
                                                : validation
                                          )
                                        );
                                      if (
                                        cardData.Authors[i].Link !==
                                          e.target.value &&
                                        authorValidation[i].Link !== "error"
                                      ) {
                                        const index =
                                          cardData.Authors.findIndex(
                                            (author, j) =>
                                              i === j
                                                ? false
                                                : author.Link ===
                                                  cardData.Authors[i].Link
                                          );
                                        setAuthorValidation(
                                          authorValidation.map(
                                            (validation, j) =>
                                              j === index
                                                ? { ...validation, Link: "" }
                                                : validation
                                          )
                                        );
                                      }

                                      setCardData({
                                        ...cardData,
                                        Authors: cardData.Authors.map(
                                          (aut, j) =>
                                            j === i
                                              ? {
                                                  ...aut,
                                                  Link: e.target.value,
                                                }
                                              : aut
                                        ),
                                      });

                                      if (accordianStatus[1] !== "In progress")
                                        setAccordianStatus(
                                          accordianStatus.map((val, i) =>
                                            i === 1 ? "In progress" : val
                                          )
                                        );
                                    }}
                                  />

                                  {i !== 0 && (
                                    <img
                                      src="./icon/accord-url-cross.svg"
                                      alt=""
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        if (cardData.Authors.length === 1) {
                                          setCardData({
                                            ...cardData,
                                            Authors: [
                                              {
                                                Name: "Author",
                                                Link: "",
                                              },
                                            ],
                                          });
                                          setAuthorValidation(
                                            authorValidation.filter(
                                              (val, j) => j !== i
                                            )
                                          );
                                        } else {
                                          const url =
                                            cardData.Authors.findIndex(
                                              (author, j) =>
                                                i === j
                                                  ? false
                                                  : author.Link ===
                                                    cardData.Authors[i].Link
                                            );
                                          setCardData({
                                            ...cardData,
                                            Authors: cardData.Authors.filter(
                                              (auth, j) => j !== i
                                            ),
                                          });

                                          if (url && url > -1) {
                                            authorValidation =
                                              authorValidation.map(
                                                (status, j) =>
                                                  i === j
                                                    ? null
                                                    : j === url
                                                    ? { ...status, Link: "" }
                                                    : status
                                              );
                                            authorValidation =
                                              authorValidation.filter(
                                                (val, j) => j !== i
                                              );
                                            setAuthorValidation(
                                              authorValidation
                                            );
                                          } else
                                            setAuthorValidation(
                                              authorValidation.filter(
                                                (val, j) => j !== i
                                              )
                                            );
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: i === 0 ? "60px" : "27px",
                                }}
                              >
                                {authorValidation[i].Name === "error" && (
                                  <div
                                    className="validation"
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                    }}
                                  >
                                    <img src="./icon/input-error.svg" />

                                    <span className="error-message">
                                      {cardData.Authors.find((author, j) =>
                                        i === j
                                          ? false
                                          : authorValidation[j].Name === "error"
                                          ? false
                                          : author.Name &&
                                            author.Name ===
                                              cardData.Authors[i].Name
                                      )
                                        ? "Name is already added."
                                        : " Character length should be greater than 3."}
                                    </span>
                                  </div>
                                )}

                                {authorValidation[i].Link === "error" && (
                                  <div
                                    className="validation"
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      marginLeft: !authorValidation[i].Name
                                        ? i === 0
                                          ? "296px"
                                          : "262px"
                                        : "0px",
                                    }}
                                  >
                                    <img src="./icon/input-error.svg" />

                                    <span className="error-message">
                                      {cardData.Authors.find((author, j) =>
                                        i === j
                                          ? false
                                          : authorValidation[j].Link === "error"
                                          ? false
                                          : author.Link &&
                                            author.Link ===
                                              cardData.Authors[i].Link
                                      )
                                        ? "URL is already added."
                                        : "URL is not valid."}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </>
                          ))}
                          <img
                            src="./icon/add-authors.svg"
                            style={{ marginTop: "15px" }}
                            alt=""
                            width={24}
                            height={24}
                            onClick={() => {
                              if (cardData.Authors.length < 4) {
                                setCardData({
                                  ...cardData,
                                  Authors: [
                                    ...cardData.Authors,
                                    {
                                      Name: "",
                                      Link: "",
                                    },
                                  ],
                                });
                                setAuthorValidation([
                                  ...authorValidation,
                                  { Name: "", Link: "" },
                                ]);
                              }
                            }}
                          />
                        </div>
                        <ToolTip
                          title={
                            "Use a link to your preferred social media (such as LinkedIn or Google) or GitHub account where you want others to find and connect with you."
                          }
                          height={80}
                          width={250}
                        >
                          <p
                            style={{
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "20px",
                              color: "#50E6FF",
                              cursor: "pointer",
                            }}
                          >
                            What social profile URL should i use?
                          </p>
                        </ToolTip>

                        <div
                          className={
                            cardData.Authors.filter(
                              (author) =>
                                author.Name === "Author" ||
                                author.Name.length < 3 ||
                                author.Link.length < 3
                            ).length === 0 &&
                            !authorValidation.filter(
                              (validation) =>
                                validation.Link === "error" ||
                                validation.Name === "error"
                            ).length
                              ? "next-btn"
                              : "next-btn-disabled"
                          }
                        >
                          {" "}
                          <button
                            style={{ position: "relative" }}
                            onClick={() => {
                              cardData.Authors.filter(
                                (author) =>
                                  author.Name === "Author" ||
                                  author.Name.length < 3 ||
                                  author.Link.length < 3
                              ).length === 0 &&
                                !authorValidation.filter(
                                  (validation) =>
                                    validation.Link === "error" ||
                                    validation.Name === "error"
                                ).length &&
                                setShowAccordian(
                                  showAccordian.map((val, i) =>
                                    i === 1 ? false : i === 2 ? true : false
                                  )
                                );

                              if (
                                cardData.Authors.filter(
                                  (author) =>
                                    author.Name === "Author" ||
                                    author.Name.length < 3 ||
                                    author.Link.length < 3
                                ).length === 0 &&
                                !authorValidation.filter(
                                  (validation) =>
                                    validation.Link === "error" ||
                                    validation.Name === "error"
                                ).length
                              )
                                setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 1 ? "Completed" : val
                                  )
                                );
                            }}
                          >
                            Next section
                            <img
                              style={{}}
                              src="../icon/next-section-accord.svg"
                              alt=""
                            />
                            <img
                              className="hovered-save"
                              src="../icon/next-section-hover-accord.svg"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div class="accordion">
                    {/* 3 */}
                    <div
                      class="accordion-header"
                      onClick={() =>
                        setShowAccordian(
                          showAccordian.map((val, i) => (i === 2 ? !val : val))
                        )
                      }
                    >
                      <p>
                        {" "}
                        Enter description{" "}
                        <span className="validation-accord">*</span>
                      </p>
                      <div>
                        <div className="accordion-status">
                          {accordianStatus[2]}
                        </div>
                        <img
                          src="../icon/chevron-down-acc-header.svg"
                          alt=""
                          onClick={() =>
                            setShowAccordian(
                              showAccordian.map((val, i) =>
                                i === 2 ? !val : val
                              )
                            )
                          }
                          style={{
                            transform: showAccordian[2]
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    {showAccordian[2] ? (
                      <div>
                        <div className="header-border-bot"></div>
                      </div>
                    ) : null}
                    {showAccordian[2] ? (
                      <div class="accordion-content">
                        <textarea
                          id="w3review"
                          name="w3review"
                          cols="80"
                          rows="5"
                          style={{
                            border:
                              cardDataValidation[2] === "error" &&
                              "1px solid #f87c86",
                          }}
                          maxLength="250"
                          value={
                            cardData.Summary === "Card description"
                              ? ""
                              : cardData.Summary
                          }
                          autoFocus
                          placeholder="Enter description"
                          onChange={(e) => {
                            setCardData({
                              ...cardData,
                              Summary: e.target.value,
                            });
                            cardData.Summary.length > 0
                              ? setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 2 ? "In progress" : val
                                  )
                                )
                              : setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 2 ? "Not started" : val
                                  )
                                );
                          }}
                          onBlur={() =>
                            cardData.Summary.length > 5
                              ? setCardDataValidation(
                                  cardDataValidation.map((validation, i) =>
                                    i === 2 ? "" : validation
                                  )
                                )
                              : setCardDataValidation(
                                  cardDataValidation.map((validation, i) =>
                                    i === 2 ? "error" : validation
                                  )
                                )
                          }
                        />
                        {cardDataValidation[2] === "error" && (
                          <div className="validation">
                            <img src="./icon/input-error.svg" />

                            <span className="error-message">
                              Character length should be greater than 5.
                            </span>
                          </div>
                        )}

                        <label htmlFor="title">Character limit is 250.</label>

                        <div
                          className={
                            cardData.Summary !== "Card description" &&
                            cardData.Summary.length > 5
                              ? "next-btn"
                              : "next-btn-disabled"
                          }
                        >
                          {" "}
                          <button
                            style={{ position: "relative" }}
                            onClick={() => {
                              cardData.Summary !== "Card description" &&
                                cardData.Summary.length > 5 &&
                                setShowAccordian(
                                  showAccordian.map((val, i) =>
                                    i === 2 ? false : i === 3 ? true : false
                                  )
                                );
                              if (
                                cardData.Summary !== "Card description" &&
                                cardData.Summary.length > 5
                              )
                                setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 2 ? "Completed" : val
                                  )
                                );
                            }}
                          >
                            Next section
                            <img
                              style={{}}
                              src="../icon/next-section-accord.svg"
                              alt=""
                            />
                            <img
                              className="hovered-save"
                              src="../icon/next-section-hover-accord.svg"
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div class="accordion">
                    {/* 4 */}
                    <div
                      class="accordion-header"
                      onClick={() =>
                        setShowAccordian(
                          showAccordian.map((val, i) => (i === 3 ? !val : val))
                        )
                      }
                    >
                      <p>
                        {" "}
                        Add tags <span className="validation-accord">*</span>
                      </p>
                      <div>
                        <div className="accordion-status">
                          {accordianStatus[3]}
                        </div>
                        <img
                          src="../icon/chevron-down-acc-header.svg"
                          alt=""
                          onClick={() =>
                            setShowAccordian(
                              showAccordian.map((val, i) =>
                                i === 3 ? !val : val
                              )
                            )
                          }
                          style={{
                            transform: showAccordian[3]
                              ? "rotate(-180deg)"
                              : "rotate(0deg)",
                            transition: "0.3s ease",
                          }}
                        />
                      </div>
                    </div>
                    {showAccordian[3] ? (
                      <div>
                        <div className="header-border-bot"></div>
                      </div>
                    ) : null}
                    {showAccordian[3] ? (
                      <div>
                        <div
                          className="accordion-content"
                          style={{ gap: "24px" }}
                        >
                          <div
                            style={{
                              backgroundColor: "#2F3646",
                              color: "#B2CDFF",
                              width: "-webkit-fill-available",
                              paddingLeft: "10px",
                              fontSize: "14px",
                              fontStyle: "normal",
                              fontWeight: "400",
                              lineHeight: "20px",
                              padding: "14px 10px",
                              margin: "12px 0 10px 0",
                              borderRadius: "10px",
                            }}
                          >
                            <span
                              style={{
                                display: "flex",
                                alignItems: "start",
                              }}
                            >
                              {" "}
                              <img src="./icon/note-info.svg" style={{}} />{" "}
                              <span style={{ marginLeft: "5px" }}>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "20px",
                                  }}
                                >
                                  Note:
                                </span>{" "}
                                Only Azure product tags are displayed on the
                                Drops card.
                              </span>
                            </span>
                          </div>
                          {filters?.map((filter) =>
                            filter.name !== "Last Updated" ? (
                              <div
                                key={filter.name}
                                style={{ width: "-webkit-fill-available" }}
                              >
                                <div>
                                  <span>
                                    {filter.name}{" "}
                                    <span className="validation-accord">*</span>{" "}
                                  </span>
                                </div>

                                {(filter.tags.length > 6 ||
                                  filter.name === "Topics") && (
                                  <div
                                    className=""
                                    style={{ position: "relative" }}
                                  >
                                    <img
                                      src="./icon/search-icon.svg"
                                      style={{
                                        position: "absolute",
                                        left: "0",
                                        top: "50%",
                                        transform: "translate(50%, -50%)",
                                      }}
                                      alt=""
                                    />{" "}
                                    <input
                                      type="text"
                                      name="title"
                                      placeholder="Search"
                                      style={{
                                        margin: "8px 0px 8px 0px",
                                        maxWidth: "539px",
                                        paddingLeft: "30px",
                                      }}
                                      value={tagFilter[filter.name]}
                                      onChange={(e) =>
                                        setTagFilter({
                                          ...tagFilter,
                                          [filter.name]: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                )}
                                <div
                                  className="tags-content"
                                  style={{ display: `${showFAQ && "block"}` }}
                                >
                                  {" "}
                                  {filter.tags
                                    .filter((tag) =>
                                      tagFilter[filter.name]
                                        ? tag.name
                                            .toLowerCase()
                                            .includes(
                                              tagFilter[
                                                filter.name
                                              ].toLowerCase()
                                            )
                                          ? true
                                          : false
                                        : tag
                                    )
                                    .map((tag) => (
                                      <div
                                        style={{
                                          width: `${showFAQ && "auto"}`,
                                        }}
                                        className={
                                          filter.name === "Difficulty" ||
                                          filter.name === "Type"
                                            ? "card-details-cta-btns hover-none "
                                            : "custom-checkbox right-side-panel"
                                        }
                                        key={tag.name}
                                        onClick={() => {
                                          if (cardData.Products.includes("Tag"))
                                            cardData = {
                                              ...cardData,
                                              Products:
                                                cardData.Products.filter(
                                                  (val) => val !== "Tag"
                                                ),
                                            };

                                          filter.name === "Products"
                                            ? cardData.Products?.includes(
                                                tag.name
                                              )
                                              ? setCardData({
                                                  ...cardData,
                                                  Products:
                                                    cardData.Products.filter(
                                                      (val) => val !== tag.name
                                                    ),
                                                })
                                              : cardData.Products.length < 4 &&
                                                setCardData({
                                                  ...cardData,
                                                  Products: [
                                                    ...cardData.Products,
                                                    tag.name,
                                                  ],
                                                })
                                            : filter.name === "Language"
                                            ? cardData.ProgrammingLanguage?.includes(
                                                tag.name
                                              )
                                              ? setCardData({
                                                  ...cardData,
                                                  ProgrammingLanguage:
                                                    cardData.ProgrammingLanguage.filter(
                                                      (val) => val !== tag.name
                                                    ),
                                                })
                                              : setCardData({
                                                  ...cardData,
                                                  ProgrammingLanguage: [
                                                    ...cardData.ProgrammingLanguage,
                                                    tag.name,
                                                  ],
                                                })
                                            : filter.name === "Difficulty"
                                            ? setCardData({
                                                ...cardData,
                                                Difficulty: tag.name,
                                              })
                                            : filter.name === "Topics"
                                            ? cardData.Topics?.includes(
                                                tag.name
                                              )
                                              ? setCardData({
                                                  ...cardData,
                                                  Topics:
                                                    cardData.Topics.filter(
                                                      (val) => val !== tag.name
                                                    ),
                                                })
                                              : setCardData({
                                                  ...cardData,
                                                  Topics: [
                                                    ...cardData.Topics,
                                                    tag.name,
                                                  ],
                                                })
                                            : setCardData({
                                                ...cardData,
                                                Type: tag.value,
                                              });

                                          if (
                                            filter.name === "Products" &&
                                            cardData.Products.length >= 4 &&
                                            !cardData.Products.includes(
                                              tag.name
                                            )
                                          ) {
                                            setProductTagsError({
                                              status: true,
                                              message:
                                                "Cannot exceed 2 rows of tags.",
                                            });

                                            setTimeout(() => {
                                              setProductTagsError({
                                                status: false,
                                                message: "",
                                              });
                                            }, 5000);
                                          }
                                          if (
                                            cardData.Products.includes(
                                              tag.name
                                            ) &&
                                            productTagsError
                                          )
                                            setProductTagsError({
                                              status: false,
                                              message: "",
                                            });

                                          if (
                                            accordianStatus[3] !== "In progress"
                                          )
                                            setAccordianStatus(
                                              accordianStatus.map((val, i) =>
                                                i === 3 ? "In progress" : val
                                              )
                                            );
                                        }}
                                      >
                                        <div
                                          className={
                                            filter.name === "Difficulty" ||
                                            (filter.name === "Type" &&
                                              "outline-none")
                                          }
                                          style={{
                                            outline:
                                              cardData.Difficulty ===
                                                tag.name ||
                                              cardData.Type === tag.value
                                                ? "1px solid #50e6ff"
                                                : "",
                                          }}
                                        >
                                          {filter.name === "Difficulty" ||
                                          filter.name === "Type" ? (
                                            <>
                                              {" "}
                                              <input
                                                type="radio"
                                                id={tag.name}
                                                name={tag.name}
                                                value={tag.name}
                                                checked={
                                                  filter.name === "Difficulty"
                                                    ? cardData.Difficulty ===
                                                      tag.name
                                                    : cardData.Type ===
                                                      tag.value
                                                }
                                              />{" "}
                                              <label htmlFor={tag.name}>
                                                {tag.name}
                                              </label>
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <input
                                                type="checkbox"
                                                id={tag.name}
                                                name="myCheckbox"
                                                value={tag.name}
                                                checked={
                                                  filter.name === "Products"
                                                    ? cardData.Products.includes(
                                                        tag.name
                                                      )
                                                    : filter.name === "Language"
                                                    ? cardData.ProgrammingLanguage.includes(
                                                        tag.name
                                                      )
                                                    : filter.name ===
                                                      "Difficulty"
                                                    ? cardData.Difficulty ===
                                                      tag.name
                                                    : filter.name === "Topics"
                                                    ? cardData.Topics.includes(
                                                        tag.name
                                                      )
                                                    : cardData.Type ===
                                                      tag.value
                                                }
                                              />
                                              <label htmlFor="myCheckbox">
                                                {tag.name}
                                              </label>
                                            </>
                                          )}
                                        </div>

                                        <ToolTip
                                          title={tag.description}
                                          placement="right"
                                          // width={240}
                                          // height={40}
                                        >
                                          {" "}
                                          <div className="info-icon">
                                            <img
                                              className="normal-info-icon"
                                              src="./images/drops/sidebar/info-icon.svg"
                                              alt=""
                                            />

                                            <img
                                              className="hovered-info-icon"
                                              src="./images/drops/sidebar/info-icon-hover.svg"
                                              alt=""
                                            />
                                            {/* <div class="tooltip-content">{tag.description}</div> */}
                                          </div>
                                        </ToolTip>
                                      </div>
                                    ))}
                                </div>
                                {(filter.tags.length > 6 ||
                                  filter.name === "Topics") && (
                                  <div>
                                    <div
                                      className="new-tag-add"
                                      onClick={() => {
                                        ((filter.name === "Products" &&
                                          cardData.Products.length < 4) ||
                                          cardData.Products.filter(
                                            (tag) => tag === "Tag"
                                          ).length === 4 ||
                                          filter.name !== "Products") &&
                                          setAddNewTag({
                                            ...addNewTag,
                                            [filter.name]: [
                                              ...addNewTag[filter.name],
                                              { checked: true, input: "" },
                                            ],
                                          });
                                      }}
                                    >
                                      <img
                                        src="../icon/tag-icon.svg"
                                        alt="xys"
                                        style={{ cursor: "pointer" }}
                                      />
                                      <p>Add tag</p>
                                    </div>
                                    <div
                                      style={{
                                        display: "grid",
                                        gridTemplateColumns: "auto auto",
                                      }}
                                    >
                                      {addNewTag[filter.name].map(
                                        (newTag, k) => (
                                          <div className="new-tag-con">
                                            {" "}
                                            <div class="styled-checkbox">
                                              <input
                                                id={`${filter.name}${k}`}
                                                class="checkbox4"
                                                name={`${filter.name}${k}`}
                                                type="checkbox"
                                                checked={newTag?.checked}
                                                maxLength={20}
                                                onClick={() => {
                                                  if (
                                                    cardData.Products.includes(
                                                      "Tag"
                                                    )
                                                  )
                                                    cardData = {
                                                      ...cardData,
                                                      Products:
                                                        cardData.Products.filter(
                                                          (val) => val !== "Tag"
                                                        ),
                                                    };

                                                  filter.name === "Products"
                                                    ? cardData.Products?.includes(
                                                        newTag?.input
                                                      )
                                                      ? setCardData({
                                                          ...cardData,
                                                          Products:
                                                            cardData.Products.filter(
                                                              (val) =>
                                                                val !==
                                                                newTag?.input
                                                            ),
                                                        })
                                                      : cardData.Products
                                                          .length < 4 &&
                                                        setCardData({
                                                          ...cardData,
                                                          Products: [
                                                            ...cardData.Products,
                                                            newTag?.input,
                                                          ],
                                                        })
                                                    : filter.name === "Language"
                                                    ? cardData.ProgrammingLanguage?.includes(
                                                        newTag?.input
                                                      )
                                                      ? setCardData({
                                                          ...cardData,
                                                          ProgrammingLanguage:
                                                            cardData.ProgrammingLanguage.filter(
                                                              (val) =>
                                                                val !==
                                                                newTag?.input
                                                            ),
                                                        })
                                                      : setCardData({
                                                          ...cardData,
                                                          ProgrammingLanguage: [
                                                            ...cardData.ProgrammingLanguage,
                                                            newTag?.input,
                                                          ],
                                                        })
                                                    : cardData.Topics?.includes(
                                                        newTag?.input
                                                      )
                                                    ? setCardData({
                                                        ...cardData,
                                                        Topics:
                                                          cardData.Topics.filter(
                                                            (val) =>
                                                              val !==
                                                              newTag?.input
                                                          ),
                                                      })
                                                    : setCardData({
                                                        ...cardData,
                                                        Topics: [
                                                          ...cardData.Topics,
                                                          newTag?.input,
                                                        ],
                                                      });

                                                  setAddNewTag({
                                                    ...addNewTag,
                                                    [filter.name]: addNewTag[
                                                      filter.name
                                                    ]?.map((tag, j) =>
                                                      j === k
                                                        ? {
                                                            ...tag,
                                                            checked:
                                                              !tag.checked,
                                                          }
                                                        : tag
                                                    ),
                                                  });
                                                }}
                                              />
                                              <label
                                                for={`${filter.name}${k}`}
                                              ></label>
                                            </div>
                                            <input
                                              placeholder="Enter tag"
                                              className="new-tag-ser"
                                              type="text"
                                              maxLength={20}
                                              // value={newTag?.input}
                                              onChange={(e) => {
                                                if (
                                                  cardData.Products.includes(
                                                    e.target.value
                                                  ) ||
                                                  filter.tags.some(
                                                    (tag) =>
                                                      tag.name ===
                                                      e.target.value
                                                  )
                                                ) {
                                                  setProductTagsError({
                                                    status: true,
                                                    message:
                                                      "This tag already exists.",
                                                  });
                                                  setTimeout(() => {
                                                    setProductTagsError({
                                                      status: false,
                                                      message: "",
                                                    });
                                                  }, 5000);
                                                } else if (newTag?.checked) {
                                                  (filter.name === "Products" &&
                                                    cardData.Products.length <
                                                      4) ||
                                                  cardData.Products.filter(
                                                    (tag) => tag === "Tag"
                                                  ).length === 4 ||
                                                  (filter.name === "Products" &&
                                                    k < 4)
                                                    ? setCardData({
                                                        ...cardData,
                                                        Products:
                                                          cardData.Products.includes(
                                                            "Tag"
                                                          ) ||
                                                          cardData.Products
                                                            .length === 0
                                                            ? [e.target.value]
                                                            : cardData.Products
                                                                .length -
                                                                1 <
                                                              k
                                                            ? [
                                                                ...cardData.Products,
                                                                e.target.value,
                                                              ]
                                                            : cardData.Products.includes(
                                                                newTag.input
                                                              )
                                                            ? cardData.Products.map(
                                                                (data, j) =>
                                                                  data ===
                                                                  newTag.input
                                                                    ? e.target
                                                                        .value
                                                                    : data
                                                              )
                                                            : [
                                                                ...cardData.Products,
                                                                e.target.value,
                                                              ],
                                                      })
                                                    : filter.name === "Language"
                                                    ? setCardData({
                                                        ...cardData,
                                                        ProgrammingLanguage:
                                                          cardData.ProgrammingLanguage.map(
                                                            (data, j) =>
                                                              j === k
                                                                ? e.target.value
                                                                : data
                                                          ),
                                                      })
                                                    : setCardData({
                                                        ...cardData,
                                                        Topics:
                                                          cardData.Topics.map(
                                                            (data, j) =>
                                                              j === k
                                                                ? e.target.value
                                                                : data
                                                          ),
                                                      });

                                                  setAddNewTag({
                                                    ...addNewTag,
                                                    [filter.name]: addNewTag[
                                                      filter.name
                                                    ]?.map((tag, j) =>
                                                      j === k
                                                        ? {
                                                            ...tag,
                                                            input:
                                                              e.target.value,
                                                          }
                                                        : tag
                                                    ),
                                                  });
                                                }
                                              }}
                                              // onKeyDown={(e) => {
                                              //   if (
                                              //     e.key === "Enter" &&
                                              //     filter.tags.filter((tag) =>
                                              //       e.target.value
                                              //         ? tag.name
                                              //             .toLowerCase()
                                              //             .includes(
                                              //               e.target.value.toLowerCase()
                                              //             )
                                              //           ? true
                                              //           : false
                                              //         : tag
                                              //     ).length === 0
                                              //   ) {
                                              //     if (newTag?.checked) {
                                              //       (filter.name ===
                                              //         "Products" &&
                                              //         cardData.Products.length <
                                              //           4) ||
                                              //       cardData.Products.filter(
                                              //         (tag) => tag === "Tag"
                                              //       ).length === 4
                                              //         ? setCardData({
                                              //             ...cardData,
                                              //             Products:
                                              //               cardData.Products.includes(
                                              //                 "Tag"
                                              //               )
                                              //                 ? [e.target.value]
                                              //                 : [
                                              //                     ...cardData.Products,
                                              //                     e.target
                                              //                       .value,
                                              //                   ],
                                              //           })
                                              //         : filter.name ===
                                              //           "Language"
                                              //         ? setCardData({
                                              //             ...cardData,
                                              //             ProgrammingLanguage: [
                                              //               ...cardData.ProgrammingLanguage,
                                              //               e.target.value,
                                              //             ],
                                              //           })
                                              //         : filter.name === "Topics"
                                              //         ? setCardData({
                                              //             ...cardData,
                                              //             Topics: [
                                              //               ...cardData.Topics,
                                              //               e.target.value,
                                              //             ],
                                              //           })
                                              //         : setCardData({
                                              //             ...cardData,
                                              //             Topics: [
                                              //               ...cardData.Topics,
                                              //               e.target.value,
                                              //             ],
                                              //           });
                                              //     }

                                              //     setFilters(
                                              //       filters.map((filterData) =>
                                              //         filterData.name ===
                                              //         filter.name
                                              //           ? {
                                              //               ...filterData,
                                              //               tags: [
                                              //                 ...filterData.tags,
                                              //                 {
                                              //                   name: e.target
                                              //                     .value,
                                              //                 },
                                              //               ],
                                              //             }
                                              //           : filterData
                                              //       )
                                              //     );
                                              //   }
                                              // }}
                                            />
                                            <img
                                              src="./icon/accord-url-cross.svg"
                                              alt=""
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                filter.name === "Products"
                                                  ? cardData.Products?.includes(
                                                      newTag?.input
                                                    ) &&
                                                    setCardData({
                                                      ...cardData,
                                                      Products:
                                                        cardData.Products.filter(
                                                          (val) =>
                                                            val !==
                                                            newTag?.input
                                                        ),
                                                    })
                                                  : filter.name === "Language"
                                                  ? cardData.ProgrammingLanguage?.includes(
                                                      newTag?.input
                                                    ) &&
                                                    setCardData({
                                                      ...cardData,
                                                      ProgrammingLanguage:
                                                        cardData.ProgrammingLanguage.filter(
                                                          (val) =>
                                                            val !==
                                                            newTag?.input
                                                        ),
                                                    })
                                                  : cardData.Topics?.includes(
                                                      newTag?.input
                                                    ) &&
                                                    setCardData({
                                                      ...cardData,
                                                      Topics:
                                                        cardData.Topics.filter(
                                                          (val) =>
                                                            val !==
                                                            newTag?.input
                                                        ),
                                                    });

                                                setAddNewTag({
                                                  ...addNewTag,
                                                  [filter.name]: addNewTag[
                                                    filter.name
                                                  ]?.filter(
                                                    (tag, j) => j !== k
                                                  ),
                                                });
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                                {productTagsError.status &&
                                  filter.name === "Products" && (
                                    <div
                                      style={{
                                        backgroundColor: "#462F2F",
                                        color: "#B2CDFF",
                                        width: "-webkit-fill-available",
                                        paddingLeft: "10px",
                                        fontSize: "14px",
                                        fontStyle: "normal",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                        padding: "14px 10px",
                                        margin: "12px 0 10px 0",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "start",
                                          color: "#FF8383",
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src="./icon/error-info.svg"
                                          style={{}}
                                        />{" "}
                                        <span style={{ marginLeft: "5px" }}>
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              fontStyle: "normal",
                                              fontWeight: "700",
                                              lineHeight: "20px",
                                            }}
                                          >
                                            Error:
                                          </span>{" "}
                                          {productTagsError.message}
                                        </span>
                                      </span>
                                    </div>
                                  )}
                              </div>
                            ) : null
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "20px 20px 20px 20px",
                            alignItems: "end",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <ToolTip
                              title={
                                "Add tags to improve discoverability and filtering. For example, tags make it easy to find projects based on related topics. Tags also help categorize projects within the Arc Jumpstart community, making it easier for contributors and users with similar interests to connect with your work."
                              }
                              height={115}
                              width={280}
                            >
                              <b className="example-text">
                                Why should I add tags?
                              </b>
                            </ToolTip>
                            <ToolTip
                              title={
                                "When adding tags, choose terms that best describe the key features and core functionalities of your project. Include any technologies used, relevant frameworks, and concepts that are central to your drop."
                              }
                              height={80}
                              width={280}
                            >
                              <b className="example-text">
                                What type of tags should I add?
                              </b>
                            </ToolTip>
                          </div>
                          <div
                            className={
                              cardData.Products.length > 0 &&
                              cardData.ProgrammingLanguage.length > 0 &&
                              cardData.Topics.length > 0 &&
                              cardData.Type &&
                              cardData.Difficulty
                                ? "next-btn"
                                : "next-btn-disabled"
                            }
                          >
                            {" "}
                            <button
                              style={{ position: "relative" }}
                              onClick={() => {
                                if (
                                  githubPrefrence === "personal" &&
                                  !sidePanelCreationStatusCompletd
                                ) {
                                  setSidePanelDropCreation("error");
                                  setPersonalGitHubUrlValidity({
                                    status: "error",
                                    message: "Enter valid URL.",
                                  });
                                }
                                if (
                                  cardData.Products.length > 0 &&
                                  cardData.ProgrammingLanguage.length > 0 &&
                                  cardData.Topics.length > 0 &&
                                  cardData.Type &&
                                  cardData.Difficulty
                                ) {
                                  setCreatingDrop(false);
                                  setAccordianStatus(
                                    accordianStatus.map((val, i) =>
                                      i === 3 ? "Completed" : val
                                    )
                                  );
                                }
                              }}
                            >
                              Next section:Side panel
                              <img
                                style={{}}
                                src="../icon/next-section-accord.svg"
                                alt=""
                              />
                              <img
                                className="hovered-save"
                                src="../icon/next-section-hover-accord.svg"
                                alt=""
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <b className="example-text" onClick={() => setShowFAQ(true)}>
                    {" "}
                    Show me an image example of the Drops card.
                  </b>
                </>
              ) : null}
            </div>
            {showFAQ ? <CardSchema onClose={handleOnCloseFAQ} /> : null}
          </>
        ) : (
          <SidePanel
            cardData={cardData}
            setMdText={setMdText}
            accordianStatus={sidePanelAccordianStatus}
            setAccordianStatus={setSidePanelAccordianStatus}
            setCoverImage={setCoverImage}
            setCoverImageName={setCoverImageName}
            setResourceImage={setResourceImage}
            setResourceImageName={setResourceImageName}
            setArtifactImage={setArtifactImage}
            setArtifactImageName={setArtifactImageName}
            setSidePanelCreationStatusCompleted={
              setSidePanelCreationStatusCompleted
            }
            sidePanelDropCreation={sidePanelDropCreation}
            sidePaneluploadOption={sidePaneluploadOption}
            setSidePanelUploadOption={setSidePanelUploadOption}
            sidePanelCreating={sidePanelCreating}
            setSidePanelCreating={setSidePanelCreating}
            usingExtarnalDropSchema={usingExtarnalDropSchema}
            githubPrefrence={githubPrefrence}
            mdLink={mdLink}
            mdText={mdText}
            isMdFileUploaded={isMdFileUploaded}
            setIsMdFileUploaded={setIsMdFileUploaded}
          />
        )}
      </div>

      <Modal
        isOpen={completeCreation}
        className="creation-modal-section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0)",

            backdropFilter: "none",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          },

          content: {
            backgroundColor: "#383838",
          },
        }}
      >
        <div className="creation-modal">
          <h4> Creating your drop</h4>
          <img src="./icon/creation-drop-logo.svg" alt="" />
          <div class="progress-bar-container">
            <div
              class="progress-bar"
              id="progress-bar"
              style={{
                width: `${!progress ? progressCalculate * 5 : progress}%`,
              }}
            ></div>
            <span class="time-remaining">{seconds} seconds remaining</span>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showAfterCreation}
        className="creation-modal-section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0)",

            backdropFilter: "none",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          },

          content: {
            backgroundColor: "#383838",
          },
        }}
      >
        <div className="creation-modal creation-modal-success">
          <h4> Created your drop</h4>
          <img
            src="./animation/loading_animation.gif"
            alt=""
            style={{ height: "150px", width: "150px" }}
          />
          <div
            className="success-drop-text repo"
            style={{
              background: createdPullRequest === null && "#462F2F",
              color: createdPullRequest === null && "#FF8383",
            }}
          >
            {" "}
            <img
              src={
                createdPullRequest === null
                  ? "./icon/error-save-icon.svg"
                  : "./icon/success-save-icon.svg"
              }
              alt=""
            />
            {createdPullRequest === null ? (
              <div>
                <span
                  className="success-drop-title"
                  style={{ color: "#FF8383" }}
                >
                  Error:
                </span>{" "}
                Your drop could not be created due to an issue. Please try again
                later. If the problem persists, contact support for assistance.
              </div>
            ) : (
              <div>
                <span className="success-drop-title" style={{}}>
                  Success:
                </span>{" "}
                Your Drop has been successfully created and is now pending
                review and approval. You will receive notification via email
                regarding the next steps in the process. In the meantime, you
                can access your drop below. Thank you for your contribution!
                <div className="created-link">
                  {" "}
                  <input
                    type="text"
                    name=""
                    style={{ color: "white" }}
                    id=""
                    readOnly
                    value={createdPullRequest}
                  />{" "}
                  <CopyButtonTooltip
                    title={"Copied!"}
                    placement={"right"}
                    open={isUrlCopied}
                  >
                    <img
                      onClick={() => {
                        navigator.clipboard.writeText(createdPullRequest);
                        setIsUrlCopien(true);
                        setTimeout(() => {
                          setIsUrlCopien(false);
                        }, 2000);
                      }}
                      className="copy-btn finish-copy"
                      style={{ cursor: "pointer" }}
                      src="./icon/copy-square.svg"
                      width="24px"
                      height="24px"
                      alt=""
                    />
                    <img
                      onClick={() => {
                        navigator.clipboard.writeText(createdPullRequest);

                        setIsUrlCopien(true);
                        setTimeout(() => {
                          setIsUrlCopien(false);
                        }, 2000);
                      }}
                      className="hovered-copy-btn finish-hover-copy"
                      style={{ cursor: "pointer" }}
                      src="./icon/hovered-copy.svg"
                      width="24px"
                      height="24px"
                      alt=""
                    />
                  </CopyButtonTooltip>
                </div>
              </div>
            )}
          </div>

          <div className="modal-btns">
            <button
              className="submit-modal"
              onClick={() => window.location.reload()}
            >
              Submit another drop
            </button>

            <Link to="/azure_jumpstart_drops">
              <button className="back-to-btn">Back to main page</button>
            </Link>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DropCreation;
