import { Link } from "react-router-dom";
import "./dropCard.css";
import JSZip from "jszip";
import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import Popover from "@mui/material/Popover";
import { s } from "@mui/system";

const DropCard = ({
  cardData,
  setModalIsOpen,
  setMdLink,
  setRightBarTitle,
  setRightBarAuthors,
  setRightBarLastModified,
  setGithubLink,
  handleHideScrollBarFromMainPage,
  drops,
  handleUpdateDrops,
  trendingTags,
  popularTags,
  setFilterByPill,
  filterByPill,
  dropViewChecked,
  setDropViewChecked,
  setProductFilter,
  productFilter,
  handleSelectedSortDropdownOption,
}) => {
  const {
    Title,
    Summary,
    Authors,
    Products,
    LastModified,
    Source,
    CreatedDate,
  } = cardData;
  let [downloadZip, setDownloadZip] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [seconds, setSeconds] = useState(10);
  const [trending, setTrending] = useState(false);
  const [popular, setPopular] = useState(false);
  const [progress, setProgress] = useState(0);
  // const [openPopover, setOpenOver] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  let [openRightSidebar] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // const useStyles = makeStyles((theme) => ({
  //   paper: {
  //     borderRadius: "4px",
  //     background: "var(--Neutral-Background-16-Rest, #242424)",
  //     boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
  //   },
  // }));

  const [telemetry, setTelemetry] = useState({
    views: 0,
    downloads: 0,
  });
  useMemo(() => {
    if (drops.length > 0) {
      const data = drops.find((drop) => drop.title === Title);
      if (data !== undefined)
        setTelemetry({
          views: data?.views,
          downloads: data?.downloads,
        });
      else
        fetch(`${process.env.REACT_APP_API_URL}/telemetry/addDrop`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if needed
          },
          body: JSON.stringify({
            title: Title,
            likes: 0,
            downloads: 0,
            views: 0,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .catch((error) => {
            console.error(
              "There was a problem with your fetch operation:",
              error
            );
          });
    }
  }, [drops]);

  useMemo(() => {
    setTrending(trendingTags.some((trending) => trending.Title === Title));

    setPopular(popularTags.some((popular) => popular.Title === Title));
  }, [, popularTags, trendingTags]);

  const handleShareClick = async () => {
    if (navigator.share)
      await navigator
        .share({
          title: Title,
          url: `${window.location.origin}/${cardData.Title.toLowerCase()
            .replace(/\s/g, "_")
            .replace(/[^a-z0-9_]/g, "")}`,
          text: Summary,
        })
        .then(() => console.log("Shared Succesfully"))
        .catch((err) => console.log(err));
  };

  const handleDownload = async (event) => {
    event.preventDefault();

    const requiredUrl = Source.split("/");
    setSeconds(5);
    setDownloading(true);

    try {
      // Fetch repository contents
      const response = await fetch(
        `https://api.github.com/repos/${
          requiredUrl[4] === "arc_jumpstart_drops" ? "Azure" : requiredUrl[3]
        }/${requiredUrl[4]}/contents/${
          requiredUrl[4] === "arc_jumpstart_drops"
            ? requiredUrl[7] + "/" + requiredUrl[8]
            : ""
        }`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch repository contents");
      }

      const files = await response.json();
      const totalFiles = files.length;
      let filesProcessed = 0;

      // Create a new instance of JSZip
      const zip = new JSZip();

      // Function to process files and directories
      const processEntry = async (entry, parentFolder) => {
        if (entry.type === "file") {
          // Fetch file data and add to zip
          const fileResponse = await fetch(entry.download_url);
          const fileData = await fileResponse.blob();
          parentFolder.file(entry.name, fileData);
          // Update progress
          if (filesProcessed < totalFiles) filesProcessed++;
          setProgress((filesProcessed / totalFiles) * 100);
        } else if (entry.type === "dir") {
          // Create a folder in the zip
          const folder = parentFolder.folder(entry.name);

          // Fetch directory contents
          const dirResponse = await fetch(entry.url);
          const dirFiles = await dirResponse.json();

          // Process each file and subdirectory
          await Promise.all(
            dirFiles.map((subEntry) => processEntry(subEntry, folder))
          );
        }
      };

      // Process top-level files and directories
      await Promise.all(files.map((file) => processEntry(file, zip)));

      // Generate the zip file
      const zipBlob = await zip.generateAsync({ type: "blob" });

      // Create a download link and trigger the download
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(zipBlob);
      downloadLink.download = `${Title}.zip`; // Ensure proper string interpolation
      document.body.appendChild(downloadLink);

      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Update telemetry
      const telemetryResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/telemetry/updateDropDownloads`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if needed
          },
          body: JSON.stringify({
            title: Title,
          }),
        }
      );

      if (!telemetryResponse.ok) {
        throw new Error("Network response was not ok");
      }

      const telemetryJson = await telemetryResponse.json();

      handleUpdateDrops();
      setDownloading(false);
    } catch (error) {
      console.error("Error downloading repository:", error);
      setDownloading(false);
    }
  };

  function isWithinPastMonth(dateString) {
    const dateObj = new Date(dateString);

    const today = new Date();

    const oneMonthAgo = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );

    if (oneMonthAgo.getDate() !== today.getDate()) {
      oneMonthAgo.setDate(0);
    }

    return dateObj >= oneMonthAgo && dateObj < today;
  }

  const handleDropCardClick = () => {
    if (openRightSidebar) {
      setModalIsOpen(true);
      setRightBarTitle(Title);
      setRightBarAuthors([...Authors]);
      setRightBarLastModified(LastModified);
      handleHideScrollBarFromMainPage();

      if (Source.includes("blob")) {
        const parts = Source.split("/blob");
        var newLink = parts[0] + parts[1];
      }
      if (Source.includes("tree")) {
        const parts = Source.split("/tree");
        var newLink = parts[0] + parts[1];
      }
      setMdLink(
        newLink
          ? `https://raw.githubusercontent.com${newLink.substring(18)}`
          : `https://raw.githubusercontent.com${Source.substring(18)}`
      );
      setGithubLink(Source);

      if (
        !JSON.parse(localStorage.getItem("dropViewChecked"))?.find(
          (data) => data.title === Title
        )?.viewed
      ) {
        localStorage.setItem(
          "dropViewChecked",
          JSON.stringify([
            ...JSON.parse(localStorage.getItem("dropViewChecked")),
            { title: Title, viewed: true },
          ])
        );

        fetch(`${process.env.REACT_APP_API_URL}/telemetry/updateDropViews`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if needed
          },
          body: JSON.stringify({
            title: Title,
          }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            handleUpdateDrops();
            setDropViewChecked(
              dropViewChecked.map((card) =>
                card.title === Title ? { ...card, viewed: true } : card
              )
            );
            return response.json();
          })
          .then((json) => {
            console.log(json);
          })
          .catch((error) => {
            console.error(
              "There was a problem with your fetch operation:",
              error
            );
          });
      }
    } else openRightSidebar = true;
  };

  return (
    <div className="drop-card" tabIndex={0} role="button">
      <div className="" style={{ width: "-webkit-fill-available" }}>
        <div className="top-content" onClick={handleDropCardClick}>
          <div className="header">
            <div className="header-left">
              <div>
                <img src="./images/drops/dropcard/downloads.svg" alt="" />
                {telemetry?.downloads}
              </div>
              <div>
                <img src="./images/drops/dropcard/eye.svg" alt="" />
                {telemetry?.views}
              </div>
            </div>
            {popular ? (
              <div
                className="header-right header-right-popular"
                style={{ position: "relative " }}
                onClick={() => {
                  console.log(filterByPill);
                  if (filterByPill === "popular") {
                    setFilterByPill("");
                    console.log("shdjsh");
                    handleSelectedSortDropdownOption({
                      value: "Newest",
                      label: "Newest",
                    });
                  } else {
                    handleSelectedSortDropdownOption({
                      value: "Popular",
                      label: "Popular",
                    });
                    setFilterByPill("popular");
                  }
                  openRightSidebar = false;
                }}
              >
                <div>
                  <img src="./images/drops/dropcard/popular.svg" alt="" />
                  <img
                    className="hovered-save"
                    src="./images/drops/dropcard/popular-hovered.svg"
                    alt=""
                  />{" "}
                  POPULAR
                </div>
              </div>
            ) : trending ? (
              <div
                className="header-right header-right-trending"
                style={{ position: "relative " }}
                onClick={() => {
                  if (filterByPill === "trending") {
                    handleSelectedSortDropdownOption({
                      value: "Newest",
                      label: "Newest",
                    });
                    setFilterByPill("");
                  } else {
                    handleSelectedSortDropdownOption({
                      value: "Trending",
                      label: "Trending",
                    });
                    setFilterByPill("trending");
                  }
                  openRightSidebar = false;
                }}
              >
                <div>
                  <img src="./images/drops/dropcard/trending.svg" alt="" />
                  <img
                    className="hovered-save"
                    src="./images/drops/dropcard/trending.svg"
                    alt=""
                  />{" "}
                  TRENDING
                </div>
              </div>
            ) : isWithinPastMonth(CreatedDate) ? (
              <div
                className="header-right header-right-new"
                style={{ position: "relative " }}
                onClick={() => {
                  handleSelectedSortDropdownOption({ value: "Newest" });
                  filterByPill === "new"
                    ? setFilterByPill("")
                    : setFilterByPill("new");
                  openRightSidebar = false;
                }}
              >
                <div>
                  <img src="./images/drops/dropcard/new.svg" alt="" />
                  <img
                    className="hovered-save"
                    src="./images/drops/dropcard/new-hovered.svg"
                    alt=""
                  />{" "}
                  NEW
                </div>
              </div>
            ) : isWithinPastMonth(LastModified) ? (
              <div
                className="header-right header-right-new"
                style={{ position: "relative " }}
                onClick={() => {
                  filterByPill === "lastModified"
                    ? setFilterByPill("")
                    : setFilterByPill("lastModified");
                  openRightSidebar = false;
                }}
              >
                <div>
                  <img src="./images/drops/dropcard/new.svg" alt="" />
                  <img
                    className="hovered-save"
                    src="./images/drops/dropcard/new-hovered.svg"
                    alt=""
                  />{" "}
                  Last Modified
                </div>
              </div>
            ) : null}
          </div>
          <div className="drop-body">
            <h3 style={{ cursor: "pointer" }}>{Title}</h3>
            <p
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                flexWrap: "wrap",
              }}
            >
              <span>
                {" "}
                by{" "}
                <a href={Authors[0].Link} target="_blank">
                  <span style={{ color: "#50E6FF", cursor: "pointer" }}>
                    {" "}
                    {Authors[0].Name}
                  </span>
                </a>
              </span>

              {Authors.length > 1 ? (
                <>
                  and{" "}
                  <a
                    onClick={(e) => {
                      openRightSidebar = false;
                      handleClick(e);
                    }}
                  >
                    {" "}
                    <span style={{ color: "#50E6FF", cursor: "pointer" }}>
                      {" "}
                      {`${Authors.length - 1} ${
                        Authors.length - 1 > 1 ? "others" : "other"
                      }`}
                    </span>
                  </a>
                  <Popover
                    sx={{
                      "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                        backgroundColor: "#242424",
                        color: "#fff",
                        position: "absolute",
                        padding: "5px",
                        boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
                      },
                    }}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => {
                      openRightSidebar = false;
                      handleClose();
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <span
                      style={{
                        width: "256px",
                        height: "35px",
                        padding: "0px 0px 0px 15px",
                        alignItems: "center",
                        gap: "7px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          color: "#ADADAD",
                          fontFamily: "Segoe UI",
                          fontSize: "12px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "16px",
                        }}
                      >
                        {" "}
                        Additional authors
                      </span>
                      <span style={{ paddingLeft: "" }}>
                        {" "}
                        <img
                          src="./icon/close-modal.svg"
                          onClick={() => {
                            openRightSidebar = false;
                            handleClose();
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </span>
                    </span>
                    {Authors.map((author, i) =>
                      i > 0 ? (
                        <div
                          className="other-authors"
                          style={{
                            display: "flex",
                            padding: "5px",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            borderRadius: "4px",
                            width: "261px",
                            borderTopLeftRadius: "0",
                            borderTopRightRadius: "0",
                            height: "35px",
                            padding: "0px 0px 0px 15px",
                            alignItems: "center",
                            gap: "7px",
                            background:
                              "var(--Neutral-Background-16-Rest, #242424)",
                          }}
                          key={i}
                        >
                          <a
                            className=""
                            href={author.Link}
                            target="_blank"
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "7px",
                            }}
                          >
                            {" "}
                            <img
                              className="not-hovered"
                              src="./icon/author-popover.svg"
                              alt=""
                            />
                            <img
                              className="hovered"
                              src="./icon/author-hovered-popover.svg"
                              alt=""
                            />
                            <span style={{ color: "white" }}>
                              {author.Name}
                            </span>
                          </a>
                        </div>
                      ) : null
                    )}
                  </Popover>
                </>
              ) : null}
            </p>

            <span style={{ cursor: "pointer" }}>{Summary}</span>
          </div>
        </div>

        <div className="footer">
          <div className="footer-pills" style={{ cursor: "pointer" }}>
            {Products.map((item) => (
              <div
                className=""
                onClick={() => {
                  const filter =
                    productFilter?.filter((product) => product?.includes(item))
                      .length > 0;

                  if (!filter) setProductFilter([...productFilter, [item]]);
                  else
                    setProductFilter(
                      productFilter.filter((product) => !product.includes(item))
                    );
                }}
              >
                {item}
              </div>
            ))}
          </div>

          <div className="footer-btns">
            <a
              className="btn-download"
              onClick={(event) => {
                downloadZip = true;
                handleDownload(event);
              }}
            >
              <img src="./images/drops/dropcard/download-footer.svg" alt="" />
              Download
            </a>

            <button className="btn-share " onClick={handleShareClick}>
              <img
                className="hovered-save"
                src="./images/drops/dropcard/footer-share-hovered.svg"
                alt=""
              />
              <img
                className="not-hovered"
                src="./images/drops/dropcard/footer-share.svg"
                alt=""
              />
              Share
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={downloading}
        className="creation-modal-section"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0)",

            backdropFilter: "none",
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
            zIndex: "6",
          },

          content: {
            backgroundColor: "#383838",
          },
        }}
      >
        <div className="creation-modal">
          <h4> Preparing Download</h4>
          <img src="./icon/creation-drop-logo.svg" alt="" />
          <div class="progress-bar-container">
            <div
              class="progress-bar"
              id="progress-bar"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DropCard;
