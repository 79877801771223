import "./SidePanel.css";
import React, { useMemo, useState } from "react";
import SidePanelMdPreviewer from "./sidePanelMdPreviewer";
import DropDetails from "./DropDetails";
import { Popover } from "@mui/material";
import { useSidePanelContext } from "../../../context/SidepanelProvider";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ToolTip from "../../toolTip/toolTip";

const SidePanel = ({
  cardData,
  setMdText,
  accordianStatus,
  setAccordianStatus,
  setCoverImage,
  setCoverImageName,
  setResourceImage,
  setResourceImageName,
  setArtifactImage,
  setArtifactImageName,
  setSidePanelCreationStatusCompleted,
  sidePanelDropCreation,
  sidePaneluploadOption,
  setSidePanelUploadOption,
  sidePanelCreating,
  setSidePanelCreating,
  usingExtarnalDropSchema,
  githubPrefrence,
  mdLink,
  mdText,
  isMdFileUploaded,
  setIsMdFileUploaded,
}) => {
  const {
    overview,
    overviewValidation,
    prerequisites,
    prerequsitiesValidation,
    prerequisiteApperance,
    gettingStarted,
    gettingStartedValidation,
    artifacts,
    artifcatsValidation,
    resource,
    resourceValidation,
    coverImageUploadingStatus,
    mdFileUploadingStatus,
    gitHubUrlValidatity,
    dispatch,
  } = useSidePanelContext();

  const [showAccordian, setShowAccordian] = useState(
    Array.from(Array(5), (_, i) => false)
  );
  const [resourceHyperText, setResourceHyperText] = useState(
    Array.from(Array(3), (_, i) => ({ text: "", link: "" }))
  );
  // const [accordianStatus, setAccordianStatus] = useState(
  //   Array.from(Array(5), (_, i) => "Not Started")
  // );
  const [expandAll, setExpandAll] = useState(false);

  const [uploadOption, setUploadOption] = useState(true);

  const [selectedTitle, setSelectedTitle] = useState({
    value: "Overview",
    label: "Overview",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverTextIndex, setPopoverTextIndex] = useState(0);

  const handleCoverImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    dispatch({
      type: "SET_COVER_IMAGE_UPLOADING_STATUS",
      payload: {
        coverImageUploadingStatus: {
          fileName: event.target.files[0].name,
          status:
            event.target.files[0].name.includes("jpg") ||
            event.target.files[0].name.includes("jpeg") ||
            event.target.files[0].name.includes("png") ||
            event.target.files[0].name.includes("svg")
              ? "success"
              : "error",
        },
      },
    });
    if (
      event.target.files[0].name.includes("jpg") ||
      event.target.files[0].name.includes("jpeg") ||
      event.target.files[0].name.includes("png") ||
      event.target.files[0].name.includes("svg")
    ) {
      reader.onload = (e) => {
        dispatch({
          type: "SET_OVERVIEW",
          payload: {
            overview: {
              ...overview,
              cover: e.target.result,
              coverRaw: file,
            },
          },
        });
        // setOverview({ ...overview, cover: e.target.result, coverRaw: file });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleResourceImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    dispatch({
      type: "SET_RESOURCE_Validation",
      payload: {
        resourceValidation: {
          ...resourceValidation,
          fileStatus: {
            fileName: event.target.files[0].name,
            status:
              event.target.files[0].name.includes("jpg") ||
              event.target.files[0].name.includes("jpeg") ||
              event.target.files[0].name.includes("png") ||
              event.target.files[0].name.includes("svg")
                ? "success"
                : "error",
          },
        },
      },
    });
    if (
      event.target.files[0].name.includes("jpg") ||
      event.target.files[0].name.includes("jpeg") ||
      event.target.files[0].name.includes("png") ||
      event.target.files[0].name.includes("svg")
    ) {
      reader.onload = (e) => {
        dispatch({
          type: "SET_RESOURCE",
          payload: {
            resource: {
              ...resource,
              file: e.target.result,
              fileRaw: file,
            },
          },
          // setResource({ ...resource, file: e.target.result, fileRaw: file });
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleArtifactImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    dispatch({
      type: "SET_ARTIFACTS_Validation",
      payload: {
        artifcatsValidation: {
          ...artifcatsValidation,
          contentStatus: {
            fileName: event.target.files[0].name,
            status:
              event.target.files[0].name.includes("jpg") ||
              event.target.files[0].name.includes("jpeg") ||
              event.target.files[0].name.includes("png") ||
              event.target.files[0].name.includes("svg")
                ? "success"
                : "error",
          },
        },
      },
    });

    if (
      event.target.files[0].name.includes("jpg") ||
      event.target.files[0].name.includes("jpeg") ||
      event.target.files[0].name.includes("png") ||
      event.target.files[0].name.includes("svg")
    ) {
      reader.onload = (e) => {
        dispatch({
          type: "SET_ARTIFACTS",
          payload: {
            artifacts: {
              ...artifacts,
              content: e.target.result,
              fileRaw: file,
            },
          },
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const gettingStartedTextStyle = {
    width: "calc(100% - 6px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: "11px",
    marginTop: "8px",
  };

  const handleMdTextFileUpload = (event) => {
    try {
      const file = event.target.files[0];
      const reader = new FileReader();

      dispatch({
        type: "SET_MD_FILE_UPLOADING_STATUS",
        payload: {
          mdFileUploadingStatus: {
            fileName: event.target.files[0].name,
            status: event.target.files[0].name.includes("md")
              ? "success"
              : "error",
          },
        },
      });
      if (event.target.files[0].name.includes("md")) {
        reader.onload = (e) => {
          const parsedData = e.target.result;
          setMdText(parsedData);
          setSidePanelCreationStatusCompleted(true);
          setIsMdFileUploaded(true);
        };

        if (file) reader.readAsText(file);
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const handleMdTextFileDrop = (event) => {
    try {
      const file = event.dataTransfer.files[0];
      const reader = new FileReader();

      dispatch({
        type: "SET_MD_FILE_UPLOADING_STATUS",
        payload: {
          mdFileUploadingStatus: {
            fileName: event.target.files[0].name,
            status: event.target.files[0].name.includes("md")
              ? "success"
              : "error",
          },
        },
      });
      if (event.target.files[0].name.includes("md")) {
        reader.onload = (e) => {
          const parsedData = e.target.result;
          setMdText(parsedData);
          setSidePanelCreationStatusCompleted(true);
          setIsMdFileUploaded(true);
        };
        if (file) reader.readAsText(file);
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const handleDropGithubUrl = async (event) => {
    if (
      !event.target.value.includes("http://") ||
      !event.target.value.includes("https://")
    )
      dispatch({
        type: "SET_GITHUB_URL_VALIDITY",
        payload: {
          gitHubUrlValidatity: {
            message:
              "The URL is not valid. Make sure it starts with http:// or https://.",
            status: "error",
          },
        },
      });
    if (event.target.value.includes(".md")) {
      if (event.target.value.includes("blob")) {
        const parts = event.target.value.split("/blob");
        var newLink = parts[0] + parts[1];
      }
      if (event.target.value.includes("tree")) {
        const parts = event.target.value.split("/tree");
        var newLink = parts[0] + parts[1];
      }

      const mdLink = newLink
        ? `https://raw.githubusercontent.com${newLink.substring(18)}`
        : `https://raw.githubusercontent.com${event.target.value.substring(
            18
          )}`;

      try {
        const response = await fetch(mdLink);
        const data = await response.text();
        setMdText(data);
        setSidePanelCreationStatusCompleted(true);
        setIsMdFileUploaded(true);
      } catch (error) {
        console.error("Error fetching JSON:", error);
      }
    }
  };

  useMemo(() => {
    if (!usingExtarnalDropSchema && !isMdFileUploaded) {
      setMdText(`
## Overview

${overview.cover && `![cover selected](./img/${overview.coverRaw.name})`}

### Drop Details
${overview.description}

## Prerequsities
${prerequisites
  .map(
    (prerequisite) =>
      `
- ### ${prerequisite.title}
${
  prerequisiteApperance === "Bullets"
    ? prerequisite.type === "note"
      ? `  > **Note:** ${prerequisite.description}`
      : "  ```shell\n" + "  " + prerequisite.description + "\n  ```"
    : prerequisite.type === "note"
    ? `  > **Note:** ${prerequisite.description}`
    : "  ```shell\n" + "  " + prerequisite.description + "\n  ```"
}
      `
  )
  .join("")}

## Getting Started
### ${gettingStarted.title}
${gettingStarted.description}
${
  gettingStarted.textType === "text"
    ? gettingStarted.texts.map((text) => `- ${text}`).join("\n")
    : "```shell\n" + gettingStarted.code + "\n```"
}

## Development Artifacts
### ${artifacts.title}
${artifacts.description}
${
  artifacts.contentType === "code"
    ? "```shell\n" + artifacts.code + "\n```"
    : `![artifact selected](./img/${artifacts.fileRaw.name})`
}

## Resource
${resource.description}
${
  resource.contentType === "text"
    ? resource.texts
        .map((text, i) =>
          resource.textsLink[i]
            ? `- ${text.replace(
                resource.highlightText[i],
                `[${resource.highlightText[i]}](${resource.textsLink[i]})`
              )}`
            : `- ${text}`
        )
        .join("\n")
    : `![resource selected](./img/${resource.fileRaw.name})`
}


    `);
      if (overview.cover) {
        setCoverImage(overview.cover);
        setCoverImageName(overview.coverRaw.name);
      }
      if (resource.file) {
        setResourceImage(resource.file);
        setResourceImageName(resource.fileRaw.name);
      }
      if (artifacts.contentType === "file") {
        setArtifactImage(artifacts.content);
        setArtifactImageName(artifacts.fileRaw.name);
      }
    }
  }, [resource, prerequisites, artifacts, gettingStarted, overview]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div className="creation-sidepanel">
      <div
        className="side-panel-preview"
        style={{
          marginLeft:
            usingExtarnalDropSchema ||
            (githubPrefrence !== "jumpstart" && "25%"),
        }}
      >
        <span>
          Side Panel Details
          <ToolTip
            title={
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <span>
                  This shows a preview of the drop side panel that will be
                  created when this drop is approved and published. The side
                  panel is rendered based on the main index/readme file from the
                  repository or folder of this drop.
                </span>
                <span>
                  If using an external repository, the file will be rendered
                  directly from there. Ifusing the Jumpstart Drops repository,
                  create the content using the left bar creator
                </span>
                <span>
                  Learn more in the
                  <a
                    style={{
                      fontSize: "var(--Size-200, 12px)",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "var(--Line-height-200, 16px)",
                      cursor: "pointer",
                    }}
                    href="/drops_contribution_guidelines"
                  >
                    Drops contribution guidelines
                  </a>
                </span>
              </div>
            }
            width={400}
            height={150}
          >
            <div className="info-icon">
              <img
                className="normal-info-icon"
                src="./images/drops/sidebar/info-icon.svg"
                alt=""
              />

              <img
                className="hovered-info-icon"
                src="./images/drops/sidebar/info-icon-hover.svg"
                alt=""
              />
              {/* <div class="tooltip-content">{tag.description}</div> */}
            </div>
          </ToolTip>
          {/* <img src="./images/drops/sidebar/info-icon.svg" alt="" /> */}
          <button className="wizard-preview">PREVIEW</button>
        </span>{" "}
        {!usingExtarnalDropSchema &&
        githubPrefrence === "jumpstart" &&
        !isMdFileUploaded ? (
          <DropDetails
            overview={overview}
            gettingStarted={gettingStarted}
            prerequisites={prerequisites}
            artifacts={artifacts}
            resource={resource}
            title={cardData.Title}
            authors={cardData.Authors}
            selectedTitle={selectedTitle}
            setSelectedTitle={setSelectedTitle}
          />
        ) : usingExtarnalDropSchema ? (
          <SidePanelMdPreviewer cardData={cardData} />
        ) : (
          <SidePanelMdPreviewer cardData={cardData} mdTextPreview={mdText} />
        )}
      </div>
      {!usingExtarnalDropSchema && githubPrefrence === "jumpstart" && (
        <div className="card-details side-panel-details">
          <span>
            Side Panel Details
            <ToolTip
              title={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  This information will be used to render the drop sidebar and
                  should be completed according to the drop index. Well-written
                  and descriptive documentation can provide clarity and enhance
                  your drop’s popularity.
                  <span>
                    Learn more in the
                    <a
                      style={{
                        fontSize: "var(--Size-200, 12px)",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "var(--Line-height-200, 16px)",
                        cursor: "pointer",
                      }}
                      href="/drops_contribution_guidelines"
                    >
                      Arc Jumpstart write-up guidelines
                    </a>
                  </span>
                </div>
              }
              width={300}
              height={100}
            >
              <div className="info-icon">
                <img
                  className="normal-info-icon"
                  src="./images/drops/sidebar/info-icon.svg"
                  alt=""
                />

                <img
                  className="hovered-info-icon"
                  src="./images/drops/sidebar/info-icon-hover.svg"
                  alt=""
                />
                {/* <div class="tooltip-content">{tag.description}</div> */}
              </div>
            </ToolTip>
            {/* <img src="./images/drops/sidebar/info-icon.svg" alt="" /> */}
            <button>
              <div className="wizard-edit">EDIT</div>
            </button>
          </span>
          <div>
            <img src="../icon/card-details-creation.svg" alt="" />
          </div>
          <div className="card-details-cta-btns">
            <div
              className="outline-cta-btns"
              style={{ outline: sidePanelCreating ? "1px solid #50e6ff" : "" }}
            >
              <input
                type="radio"
                id="step-by-step"
                name="creation-option"
                value="step-by-step"
                checked={sidePanelCreating}
                onClick={() => {
                  if (sidePanelDropCreation !== "Completed") {
                    setSidePanelCreating(true);
                    setSidePanelCreationStatusCompleted(false);
                  }
                }}
              />
              <label for="step-by-step">
                Create with step-by-step guidance
              </label>
            </div>
            <div
              className="outline-cta-btns"
              style={{ outline: !sidePanelCreating ? "1px solid #50e6ff" : "" }}
            >
              <input
                type="radio"
                id="upload"
                name="creation-option"
                value="upload"
                checked={!sidePanelCreating}
                onClick={() =>
                  sidePanelDropCreation !== "Completed" &&
                  setSidePanelCreating(false)
                }
              />
              <label for="upload">Upload Existing asset</label>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#2F3646",
              color: "#B2CDFF",
              // color: "green",
              paddingLeft: "10px",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "20px",
              padding: "14px 10px",
              margin: "12px 0 10px 0",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "start",
              }}
            >
              {" "}
              <img src="./icon/note-info.svg" style={{}} />{" "}
              <span style={{ marginLeft: "5px" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "20px",
                    // color: "white",
                    borderRadius: "10px",
                  }}
                >
                  Note:
                </span>{" "}
                Complete the fields below and we will create a Drop using GitHub
                mechanisms. You will be able to make changes later in GitHub.
              </span>
            </span>
          </div>
          {!sidePanelCreating ? (
            <>
              {uploadOption && (
                <>
                  <div className="uploading-asset">
                    <div>
                      <img src="./icon/uploading-asset.svg" alt="" />
                    </div>
                    <span>Drag and Drop or</span>
                    <input
                      type="file"
                      style={{
                        opacity: 0,
                        position: "absolute",
                        marginTop: "30px",
                        padding: "40px 200px 100px 200px",
                        width: "auto",
                        cursor: "pointer",
                      }}
                      accept=".md"
                      onChange={handleMdTextFileUpload}
                      onDrop={handleMdTextFileDrop}
                    />
                    <button>Choose file</button>
                    <p>Md</p>
                  </div>
                  {mdFileUploadingStatus?.fileName && (
                    <div
                      className="uploading-status"
                      style={{
                        border:
                          mdFileUploadingStatus.status === "error" &&
                          "1px solid #f87c86",
                      }}
                    >
                      <span>{mdFileUploadingStatus.fileName}</span>
                      <img
                        style={{ cursor: "pointer" }}
                        src="./icon/close-modal.svg"
                        width={15}
                        height={15}
                        onClick={() =>
                          dispatch({
                            type: "SET_MD_FILE_UPLOADING_STATUS",
                            payload: {
                              mdFileUploadingStatus: {
                                fileName: "",
                                status: "",
                              },
                            },
                          })
                        }
                      />
                    </div>
                  )}
                  {mdFileUploadingStatus?.status === "error" && (
                    <div className="validation">
                      <img src="./icon/input-error.svg" />

                      <span className="error-message">
                        Unable to read the file. Make sure the file is not
                        corrupted and try again.
                      </span>
                    </div>
                  )}
                </>
              )}

              <div className="card-details-cta-btns">
                <div
                  className="outline-cta-btns"
                  style={{
                    outline: sidePaneluploadOption ? "1px solid #50e6ff" : "",
                  }}
                  onClick={() => {
                    if (sidePanelDropCreation !== "Completed") {
                      setSidePanelUploadOption(true);
                      setUploadOption(true);
                    }
                  }}
                >
                  <input
                    type="radio"
                    id="upload1"
                    name="uploading-option"
                    value="upload-a-file"
                    checked={sidePaneluploadOption}
                  />
                  <label for="upload1">Upload md or text file</label>
                </div>
                <div
                  className="outline-cta-btns"
                  style={{
                    outline: !sidePaneluploadOption ? "1px solid #50e6ff" : "",
                  }}
                  onClick={() => {
                    if (sidePanelDropCreation !== "Completed") {
                      setSidePanelUploadOption(false);
                      setUploadOption(false);
                    }
                  }}
                >
                  <input
                    type="radio"
                    id="upload2"
                    name="uploading-option"
                    value="upload-a-url"
                    checked={!sidePaneluploadOption}
                  />
                  <label for="upload2">Enter GitHub URL</label>
                </div>
              </div>
              {!uploadOption && (
                <div className="uploading-git-url">
                  <input
                    className="accord2-inp"
                    type="text"
                    htmlFor="title2"
                    placeholder="Enter GitHub URL"
                    style={{ marginTop: "15px" }}
                    onChange={handleDropGithubUrl}
                  />
                  {gitHubUrlValidatity.status === "error" && (
                    <div className="validation">
                      <img src="./icon/input-error.svg" />

                      <span className="error-message">
                        {gitHubUrlValidatity.message}
                      </span>
                    </div>
                  )}
                </div>
              )}
              <div className="schema-upload">
                <p>Show me an example.</p>
                {/* <span>Is there an exmaple of the schema</span> */}
              </div>
            </>
          ) : null}
          {sidePanelCreating ? (
            <>
              <div
                className="all-accordians"
                onClick={() => {
                  setShowAccordian(
                    expandAll
                      ? showAccordian.map(() => false)
                      : showAccordian.map(() => true)
                  );
                  setExpandAll(!expandAll);
                }}
              >
                <p>{expandAll ? "Collapse all" : "Expand all"}</p>
                <img
                  src="../icon/chevron-down-accord.svg"
                  alt=""
                  style={{
                    transform: expandAll ? "rotate(-180deg)" : "rotate(0deg)",
                    transition: "0.3s ease",
                  }}
                />
              </div>
              <div class="accordion">
                {/* 1 */}
                <div
                  class="accordion-header"
                  onClick={() => {
                    setShowAccordian(
                      showAccordian.map((val, i) => (i === 0 ? !val : false))
                    );
                    setSelectedTitle({
                      value: "Overview",
                      label: "Overview",
                    });
                  }}
                >
                  <p>
                    {" "}
                    Upload photo and enter description{" "}
                    <span className="validation-accord">*</span>
                  </p>
                  <div>
                    <div className="accordion-status">
                      {" "}
                      {accordianStatus[0]}
                    </div>
                    <img
                      src="../icon/chevron-down-acc-header.svg"
                      alt=""
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) =>
                            i === 0 ? !val : false
                          )
                        );
                        setSelectedTitle({
                          value: "Overview",
                          label: "Overview",
                        });
                      }}
                      style={{
                        transform: showAccordian[0]
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                        transition: "0.3s ease",
                      }}
                    />
                  </div>
                </div>
                {showAccordian[0] ? (
                  <div>
                    <div className="header-border-bot"></div>
                  </div>
                ) : null}

                <div
                  class="accordion-content"
                  style={{
                    transition: "1s ease",
                    display: showAccordian[0] ? "flex" : "none",
                  }}
                >
                  <div className="uploading-asset">
                    <div>
                      <img src="./icon/uploading-asset.svg" alt="" />
                    </div>
                    <span>Drag and Drop or</span>

                    <button className="uploading-btn">Choose file</button>
                    <p>JPG, JPEG, PNG, SVG</p>
                    <input
                      type="file"
                      style={{
                        opacity: 0,
                        position: "absolute",
                        marginTop: "30px",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      accept=".jpg, .jpeg, .png, .svg"
                      onChange={handleCoverImage}
                    />
                  </div>
                  {coverImageUploadingStatus.fileName && (
                    <div
                      className="uploading-status"
                      style={{
                        border:
                          coverImageUploadingStatus.status === "error" &&
                          "1px solid #f87c86",
                      }}
                    >
                      <span>{coverImageUploadingStatus.fileName}</span>
                      <img
                        style={{ cursor: "pointer" }}
                        src="./icon/close-modal.svg"
                        width={15}
                        height={15}
                        onClick={() =>
                          dispatch({
                            type: "SET_COVER_IMAGE_UPLOADING_STATUS",
                            payload: {
                              mdFileUploadingStatus: {
                                fileName: "",
                                status: "",
                              },
                            },
                          })
                        }
                      />
                    </div>
                  )}
                  {coverImageUploadingStatus.status === "error" && (
                    <div className="validation">
                      <img src="./icon/input-error.svg" />

                      <span className="error-message">
                        Unable to read the file. Make sure the file is not
                        corrupted and try again.
                      </span>
                    </div>
                  )}
                  <p
                    style={{
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "22px",
                    }}
                    value={overview.description}
                  >
                    Description <span className="validation-accord"></span>{" "}
                  </p>
                  <textarea
                    name=""
                    id=""
                    cols="80"
                    rows="4"
                    maxLength="600"
                    placeholder="Enter Description"
                    style={{
                      border:
                        overviewValidation === "error" && "1px solid #f87c86",
                    }}
                    value={overview.description}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_OVERVIEW",
                        payload: {
                          overview: {
                            ...overview,
                            description: e.target.value,
                          },
                        },
                      });
                      // setOverview({ ...overview, description: e.target.value });
                      setAccordianStatus(
                        accordianStatus.map((status, i) =>
                          i === 0 ? "In Progress" : status
                        )
                      );
                    }}
                    onBlur={() =>
                      overview.description.length > 5
                        ? dispatch({
                            type: "SET_OVERVIEW_Validation",
                            payload: {
                              overviewValidation: "success",
                            },
                          })
                        : dispatch({
                            type: "SET_OVERVIEW_Validation",
                            payload: {
                              overviewValidation: "error",
                            },
                          })
                    }
                  />
                  {overviewValidation === "error" && (
                    <div className="validation">
                      <img src="./icon/input-error.svg" />

                      <span className="error-message">
                        Character length should be greater than 5
                      </span>
                    </div>
                  )}

                  <div
                    className="next-btn"
                    onClick={() => {
                      setShowAccordian(
                        showAccordian.map((val, i) =>
                          i === 0 ? false : i === 1 ? true : false
                        )
                      );
                      if (overview.description.length > 3 && overview.cover)
                        setAccordianStatus(
                          accordianStatus.map((val, i) =>
                            i === 0 ? "Completed" : val
                          )
                        );
                      setSelectedTitle({
                        value: "Prerequisites",
                        label: "Prerequisites",
                      });
                    }}
                  >
                    {" "}
                    <button>
                      Next Section
                      <img
                        style={{}}
                        src="../icon/next-section-accord.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div class="accordion">
                {/* 2 */}
                <div
                  class="accordion-header "
                  onClick={() => {
                    setShowAccordian(
                      showAccordian.map((val, i) => (i === 1 ? !val : false))
                    );

                    setSelectedTitle({
                      value: "Prerequisites",
                      label: "Prerequisites",
                    });
                  }}
                >
                  <p>
                    {" "}
                    Define prerequisitess{" "}
                    <span className="validation-accord">*</span>
                  </p>
                  <div>
                    <div className="accordion-status">{accordianStatus[1]}</div>
                    <img
                      src="../icon/chevron-down-acc-header.svg"
                      alt=""
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) =>
                            i === 1 ? !val : false
                          )
                        );

                        setSelectedTitle({
                          value: "Prerequisites",
                          label: "Prerequisites",
                        });
                      }}
                      style={{
                        transform: showAccordian[1]
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                        transition: "0.3s ease",
                      }}
                    />
                  </div>
                </div>
                {showAccordian[1] ? (
                  <div>
                    <div className="header-border-bot"></div>
                  </div>
                ) : null}
                {showAccordian[1] ? (
                  <div class="accordion-content pre-requisit">
                    <div className="card-details-cta-btns width-control">
                      <div
                        className="outline-cta-btns"
                        style={{
                          outline:
                            prerequisiteApperance === "Bullets"
                              ? "1px solid #50e6ff"
                              : "",
                        }}
                      >
                        <input
                          type="radio"
                          id="step-by-step3"
                          name="creation-option3"
                          value="step-by-step3"
                          checked={
                            prerequisiteApperance === "Bullets" ? true : false
                          }
                          onClick={() =>
                            dispatch({
                              type: "SET_PREREQUISITES_APPERANCE",
                              payload: {
                                prerequisiteApperance: "Bullets",
                              },
                            })
                          }
                        />
                        <label for="step-by-step3">Bullet Points</label>
                      </div>
                      <div
                        className="outline-cta-btns"
                        style={{
                          outline:
                            prerequisiteApperance === "Steps"
                              ? "1px solid #50e6ff"
                              : "",
                        }}
                      >
                        <input
                          type="radio"
                          id="upload3"
                          name="creation-option3"
                          value="upload3"
                          checked={
                            prerequisiteApperance === "Steps" ? true : false
                          }
                          onClick={() =>
                            dispatch({
                              type: "SET_PREREQUISITES_APPERANCE",
                              payload: {
                                prerequisiteApperance: "Steps",
                              },
                            })
                          }
                        />
                        <label for="upload3">Steps</label>
                      </div>
                    </div>

                    {prerequisites.map((prerequisite, i) => (
                      <>
                        {" "}
                        <label
                          htmlFor="pre-req"
                          style={{
                            color: "#fff",
                            marginTop: "10px",
                            position: "relative",
                            width: "100%",
                          }}
                        >
                          prerequisite {i + 1}
                          {i !== 0 ? (
                            <img
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                transform: "translate(-50% , -50%)",
                              }}
                              src="./icon/accord-url-cross.svg"
                              alt=""
                              onClick={() => {
                                dispatch({
                                  type: "SET_PREREQUISITES",
                                  payload: {
                                    prerequisites: prerequisites.filter(
                                      (prereq, j) => j !== i
                                    ),
                                  },
                                });
                                // setprerequisites(
                                //   prerequisites.filter((prereq, j) => j !== i)
                                // );
                              }}
                            />
                          ) : null}
                        </label>
                        <input
                          type="text"
                          name=""
                          placeholder="&bull; Enter a prerequisites"
                          maxLength="256"
                          id="pre-req"
                          style={{
                            border:
                              prerequsitiesValidation[i].titleStatus ===
                                "error" && "1px solid #f87c86",
                          }}
                          value={prerequisite.title}
                          onChange={(e) => {
                            dispatch({
                              type: "SET_PREREQUISITES",
                              payload: {
                                prerequisites: prerequisites.map((prereq, j) =>
                                  i === j
                                    ? { ...prereq, title: e.target.value }
                                    : prereq
                                ),
                              },
                            });

                            // setprerequisites(
                            //   prerequisites.map((prereq, j) =>
                            //     i === j
                            //       ? { ...prereq, title: e.target.value }
                            //       : prereq
                            //   )
                            // );
                            if (accordianStatus[1] !== "In Progress")
                              setAccordianStatus(
                                accordianStatus.map((status, i) =>
                                  i === 1 ? "In Progress" : status
                                )
                              );
                          }}
                          onBlur={() =>
                            prerequisite.title.length > 5
                              ? dispatch({
                                  type: "SET_PREREQUISITES_Validation",
                                  payload: {
                                    prerequsitiesValidation:
                                      prerequsitiesValidation.map(
                                        (validation, j) =>
                                          j === i
                                            ? {
                                                ...prerequsitiesValidation[i],
                                                titleStatus: "success",
                                              }
                                            : validation
                                      ),
                                  },
                                })
                              : dispatch({
                                  type: "SET_PREREQUISITES_Validation",
                                  payload: {
                                    prerequsitiesValidation:
                                      prerequsitiesValidation.map(
                                        (validation, j) =>
                                          j === i
                                            ? {
                                                ...prerequsitiesValidation[i],
                                                titleStatus: "error",
                                              }
                                            : validation
                                      ),
                                  },
                                })
                          }
                        />
                        {prerequsitiesValidation[i].titleStatus === "error" && (
                          <div className="validation">
                            <img src="./icon/input-error.svg" />

                            <span className="error-message">
                              Character length should be greater than 5
                            </span>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            width: "calc(100% - 190px)",
                            justifyContent: "space-between",
                          }}
                          className="custom-checkbox right-side-panel"
                        >
                          <div
                            style={{}}
                            onClick={
                              () =>
                                dispatch({
                                  type: "SET_PREREQUISITES",
                                  payload: {
                                    prerequisites: prerequisites.map(
                                      (prereq, j) =>
                                        i === j
                                          ? {
                                              ...prereq,
                                              type: "code",
                                            }
                                          : prereq
                                    ),
                                  },
                                })
                              // setprerequisites(
                              //   prerequisites.map((prereq, j) =>
                              //     i === j
                              //       ? {
                              //           ...prereq,
                              //           type: "code",
                              //         }
                              //       : prereq
                              //   )
                              // )
                            }
                          >
                            <input
                              type="checkbox"
                              id=""
                              name="myCheckbox"
                              value=""
                              checked={
                                prerequisite.type === "code" ? true : false
                              }
                            />
                            <label htmlFor="myCheckbox">Add code block</label>
                          </div>
                          <div
                            style={{}}
                            onClick={() => {
                              dispatch({
                                type: "SET_PREREQUISITES",
                                payload: {
                                  prerequisites: prerequisites.map(
                                    (prereq, j) =>
                                      i === j
                                        ? {
                                            ...prereq,
                                            type: "note",
                                          }
                                        : prereq
                                  ),
                                },
                              });
                              // setprerequisites(
                              //   prerequisites.map((prereq, j) =>
                              //     i === j
                              //       ? {
                              //           ...prereq,
                              //           type: "note",
                              //         }
                              //       : prereq
                              //   )
                              // )
                            }}
                          >
                            <input
                              type="checkbox"
                              id=""
                              name="myCheckbox"
                              value=""
                              checked={
                                prerequisite.type === "note" ? true : false
                              }
                            />
                            <label htmlFor="myCheckbox">Add note block</label>
                          </div>
                        </div>
                        {prerequisite.type !== "" ? (
                          <>
                            <input
                              type="text"
                              name=""
                              id="pre-req"
                              maxLength="256"
                              style={{
                                border:
                                  prerequsitiesValidation[i]
                                    .descriptionStatus === "error" &&
                                  "1px solid #f87c86",
                              }}
                              value={prerequisite.description}
                              onChange={(e) => {
                                dispatch({
                                  type: "SET_PREREQUISITES",
                                  payload: {
                                    prerequisites: prerequisites.map(
                                      (prereq, j) =>
                                        i === j
                                          ? {
                                              ...prereq,
                                              description: e.target.value,
                                            }
                                          : prereq
                                    ),
                                  },
                                });
                              }}
                              onBlur={() =>
                                prerequisite.description.length > 5
                                  ? dispatch({
                                      type: "SET_PREREQUISITES_Validation",
                                      payload: {
                                        prerequsitiesValidation:
                                          prerequsitiesValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? {
                                                    ...prerequsitiesValidation[
                                                      i
                                                    ],
                                                    descriptionStatus:
                                                      "success",
                                                  }
                                                : validation
                                          ),
                                      },
                                    })
                                  : dispatch({
                                      type: "SET_PREREQUISITES_Validation",
                                      payload: {
                                        prerequsitiesValidation:
                                          prerequsitiesValidation.map(
                                            (validation, j) =>
                                              j === i
                                                ? {
                                                    ...prerequsitiesValidation[
                                                      i
                                                    ],
                                                    descriptionStatus: "error",
                                                  }
                                                : validation
                                          ),
                                      },
                                    })
                              }
                            />
                            {prerequsitiesValidation[i].descriptionStatus ===
                              "error" && (
                              <div className="validation">
                                <img src="./icon/input-error.svg" />

                                <span className="error-message">
                                  Character length should be greater than 5
                                </span>
                              </div>
                            )}
                          </>
                        ) : null}
                        {prerequisites.length - 1 === i ? (
                          <img
                            src="./icon/add-authors.svg"
                            style={{ marginTop: "15px" }}
                            alt=""
                            onClick={() => {
                              dispatch({
                                type: "SET_PREREQUISITES",
                                payload: {
                                  prerequisites: [
                                    ...prerequisites,
                                    { name: "", type: "" },
                                  ],
                                },
                              });
                              dispatch({
                                type: "SET_PREREQUISITES_Validation",
                                payload: {
                                  prerequsitiesValidation: [
                                    ...prerequsitiesValidation,
                                    {
                                      titleStatus: "",
                                      descriptionStatus: "",
                                    },
                                  ],
                                },
                              });
                            }}
                          />
                        ) : null}
                      </>
                    ))}
                    <div
                      className="next-btn"
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) =>
                            i === 1 ? false : i === 2 ? true : false
                          )
                        );
                        if (
                          prerequisites.filter(
                            (prerequisite) =>
                              prerequisite.title === "" ||
                              prerequisite.type === "" ||
                              prerequisite.description === ""
                          ).length === 0
                        )
                          setAccordianStatus(
                            accordianStatus.map((val, i) =>
                              i === 1 ? "Completed" : val
                            )
                          );
                        setSelectedTitle({
                          value: "Getting Started",
                          label: "Getting Started",
                        });
                      }}
                    >
                      {" "}
                      <button>
                        Next Section
                        <img
                          style={{}}
                          src="../icon/next-section-accord.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div class="accordion">
                {/* 3 */}
                <div
                  class="accordion-header"
                  onClick={() => {
                    setShowAccordian(
                      showAccordian.map((val, i) => (i === 2 ? !val : false))
                    );
                    setSelectedTitle({
                      value: "Getting Started",
                      label: "Getting Started",
                    });
                  }}
                >
                  <p>
                    {" "}
                    List out getting started instructions{" "}
                    <span className="validation-accord">*</span>
                  </p>
                  <div>
                    <div className="accordion-status">{accordianStatus[2]}</div>
                    <img
                      src="../icon/chevron-down-acc-header.svg"
                      alt=""
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) =>
                            i === 2 ? !val : false
                          )
                        );
                        setSelectedTitle({
                          value: "Getting Started",
                          label: "Getting Started",
                        });
                      }}
                      style={{
                        transform: showAccordian[2]
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                        transition: "0.3s ease",
                      }}
                    />
                  </div>
                </div>
                {showAccordian[2] ? (
                  <div>
                    <div className="header-border-bot"></div>
                  </div>
                ) : null}
                {showAccordian[2] ? (
                  <div class="accordion-content getting-started">
                    <label
                      htmlFor="pre-req"
                      style={{
                        color: "#fff",
                        marginTop: "10px",
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      Sub header <span className="validation-accord"> *</span>
                    </label>
                    <input
                      type="text"
                      name=""
                      placeholder="&bull; Enter a prerequisites"
                      id="pre-req"
                      maxLength="256"
                      style={{
                        border:
                          gettingStartedValidation.titleStatus === "error" &&
                          "1px solid #f87c86",
                      }}
                      value={gettingStarted.title}
                      onChange={(e) => {
                        dispatch({
                          type: "SET_GETTING_STARTED",
                          payload: {
                            gettingStarted: {
                              ...gettingStarted,
                              title: e.target.value,
                            },
                          },
                        });
                        // setGettingStarted({
                        //   ...gettingStarted,
                        //   title: e.target.value,
                        // });
                        if (accordianStatus[2] !== "In Progress")
                          setAccordianStatus(
                            accordianStatus.map((status, i) =>
                              i === 2 ? "In Progress" : status
                            )
                          );
                      }}
                      onBlur={() =>
                        gettingStarted.title.length > 5
                          ? dispatch({
                              type: "SET_GETTING_STARTTED_Validation",
                              payload: {
                                gettingStartedValidation: {
                                  ...gettingStartedValidation,
                                  titleStatus: "success",
                                },
                              },
                            })
                          : dispatch({
                              type: "SET_GETTING_STARTTED_Validation",
                              payload: {
                                gettingStartedValidation: {
                                  ...gettingStartedValidation,
                                  titleStatus: "error",
                                },
                              },
                            })
                      }
                    />
                    {gettingStartedValidation.titleStatus === "error" && (
                      <div className="validation">
                        <img src="./icon/input-error.svg" />

                        <span className="error-message">
                          Character length should be greater than 5
                        </span>
                      </div>
                    )}

                    <p
                      style={{
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "22px",
                      }}
                    >
                      Description <span className="validation-accord">*</span>{" "}
                    </p>
                    <textarea
                      name=""
                      id=""
                      cols="80"
                      rows="3"
                      placeholder=""
                      maxLength="600"
                      style={{
                        border:
                          gettingStartedValidation.descriptionStatus ===
                            "error" && "1px solid #f87c86",
                      }}
                      value={gettingStarted.description}
                      onChange={
                        (e) =>
                          dispatch({
                            type: "SET_GETTING_STARTED",
                            payload: {
                              gettingStarted: {
                                ...gettingStarted,
                                description: e.target.value,
                              },
                            },
                          })
                        // setGettingStarted({
                        //   ...gettingStarted,
                        //   description: e.target.value,
                        // })
                      }
                      onBlur={() =>
                        gettingStarted.description.length > 5
                          ? dispatch({
                              type: "SET_GETTING_STARTTED_Validation",
                              payload: {
                                gettingStartedValidation: {
                                  ...gettingStartedValidation,
                                  descriptionStatus: "success",
                                },
                              },
                            })
                          : dispatch({
                              type: "SET_GETTING_STARTTED_Validation",
                              payload: {
                                gettingStartedValidation: {
                                  ...gettingStartedValidation,
                                  descriptionStatus: "error",
                                },
                              },
                            })
                      }
                    />
                    {gettingStartedValidation.descriptionStatus === "error" && (
                      <div className="validation">
                        <img src="./icon/input-error.svg" />

                        <span className="error-message">
                          Character length should be greater than 5
                        </span>
                      </div>
                    )}

                    <div className="card-details-cta-btns width-pre">
                      <div
                        className="outline-cta-btns"
                        style={{
                          outline:
                            gettingStarted.textType === "text"
                              ? "1px solid #50e6ff"
                              : "",
                        }}
                        onClick={() =>
                          dispatch({
                            type: "SET_GETTING_STARTED",
                            payload: {
                              gettingStarted: {
                                ...gettingStarted,
                                textType: "text",
                              },
                            },
                          })
                        }
                      >
                        <input
                          type="radio"
                          id="getting-started"
                          name="creation-details3"
                          value="getting-started-text"
                          maxLength="256"
                          checked={
                            gettingStarted.textType === "text" ? true : false
                          }
                        />
                        <label for="getting-Started">Enter Text</label>
                      </div>
                      <div
                        className="outline-cta-btns"
                        style={{
                          outline:
                            gettingStarted.textType === "code"
                              ? "1px solid #50e6ff"
                              : "",
                        }}
                        onClick={() => {
                          dispatch({
                            type: "SET_GETTING_STARTED",
                            payload: {
                              gettingStarted: {
                                ...gettingStarted,
                                textType: "code",
                              },
                            },
                          });

                          if (accordianStatus[2] !== "In Progress")
                            setAccordianStatus(
                              accordianStatus.map((status, i) =>
                                i === 2 ? "In Progress" : status
                              )
                            );
                        }}
                      >
                        <input
                          type="radio"
                          id="getting-started-code"
                          name="creation-details3"
                          value="getting-started-code"
                          checked={
                            gettingStarted.textType === "code" ? true : false
                          }
                        />
                        <label for="getting-started-code">Enter Code</label>
                      </div>
                    </div>
                    {gettingStarted.textType === "code" ? (
                      <input
                        type="text"
                        name=""
                        style={{ marginTop: "8px" }}
                        placeholder=" Enter code"
                        id="pre-req"
                        maxLength="256"
                        value={gettingStarted.code}
                        onChange={(e) => {
                          dispatch({
                            type: "SET_GETTING_STARTED",
                            payload: {
                              gettingStarted: {
                                ...gettingStarted,
                                code: e.target.value,
                              },
                            },
                          });
                          // setGettingStarted({
                          //   ...gettingStarted,
                          //   code: e.target.value,
                          // });
                          if (accordianStatus[2] !== "In Progress")
                            setAccordianStatus(
                              accordianStatus.map((status, i) =>
                                i === 2 ? "In Progress" : status
                              )
                            );
                        }}
                      />
                    ) : (
                      gettingStarted.texts.map((text, i) => (
                        <>
                          <div
                            style={
                              i !== 0
                                ? gettingStartedTextStyle
                                : {
                                    marginTop: "8px",
                                    width: "calc(100% - 6px)",
                                  }
                            }
                            key={i}
                          >
                            <input
                              type="text"
                              name=""
                              placeholder=" Enter text"
                              id="pre-req"
                              maxLength="600"
                              value={text}
                              onChange={(e) => {
                                dispatch({
                                  type: "SET_GETTING_STARTED",
                                  payload: {
                                    gettingStarted: {
                                      ...gettingStarted,
                                      texts: gettingStarted.texts.map((tx, j) =>
                                        j === i ? e.target.value : tx
                                      ),
                                    },
                                  },
                                });

                                if (accordianStatus[2] !== "In Progress")
                                  setAccordianStatus(
                                    accordianStatus.map((status, i) =>
                                      i === 2 ? "In Progress" : status
                                    )
                                  );
                              }}
                            ></input>
                            {i !== 0 ? (
                              <img
                                src="./icon/accord-url-cross.svg"
                                style={{}}
                                alt=""
                                onClick={
                                  () =>
                                    dispatch({
                                      type: "SET_GETTING_STARTED",
                                      payload: {
                                        gettingStarted: {
                                          ...gettingStarted,
                                          texts: gettingStarted.texts.filter(
                                            (tx, j) => j !== i
                                          ),
                                        },
                                      },
                                    })
                                  // setGettingStarted({
                                  //   ...gettingStarted,
                                  //   texts: gettingStarted.texts.filter(
                                  //     (tx, j) => j !== i
                                  //   ),
                                  // })
                                }
                              />
                            ) : null}
                          </div>
                          {gettingStarted.texts.length - 1 === i ? (
                            <img
                              src="./icon/add-authors.svg"
                              style={{ marginTop: "15px" }}
                              alt=""
                              onClick={
                                () =>
                                  dispatch({
                                    type: "SET_GETTING_STARTED",
                                    payload: {
                                      gettingStarted: {
                                        ...gettingStarted,
                                        texts: [...gettingStarted.texts, ""],
                                      },
                                    },
                                  })
                                // setGettingStarted({
                                //   ...gettingStarted,
                                //   texts: [...gettingStarted.texts, ""],
                                // })
                              }
                            />
                          ) : null}
                        </>
                      ))
                    )}

                    <div
                      className="next-btn"
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) =>
                            i === 2 ? false : i === 3 ? true : false
                          )
                        );
                        if (
                          gettingStarted.title.length > 3 &&
                          gettingStarted.description.length > 3 &&
                          gettingStarted.textType === "text"
                            ? gettingStarted.texts.map(
                                (text) => text.length > 3
                              ).length > 0
                            : gettingStarted.code.length > 3
                        )
                          setAccordianStatus(
                            accordianStatus.map((val, i) =>
                              i === 2 ? "Completed" : val
                            )
                          );
                        setSelectedTitle({
                          value: "Development Artifacts",
                          label: "Development Artifacts",
                        });
                      }}
                    >
                      {" "}
                      <button>
                        Next Section
                        <img
                          style={{}}
                          src="../icon/next-section-accord.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div class="accordion">
                {/* 4 */}
                <div
                  class="accordion-header"
                  onClick={() => {
                    setShowAccordian(
                      showAccordian.map((val, i) => (i === 3 ? !val : false))
                    );
                    setSelectedTitle({
                      value: "Development Artifacts",
                      label: "Development Artifacts",
                    });
                  }}
                >
                  <p>
                    {" "}
                    Upload development artifacts
                    {/* <span className="validation-accord">*</span> */}
                  </p>
                  <div>
                    <div className="accordion-status">{accordianStatus[3]}</div>
                    <img
                      src="../icon/chevron-down-acc-header.svg"
                      alt=""
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) =>
                            i === 3 ? !val : false
                          )
                        );
                        setSelectedTitle({
                          value: "Development Artifacts",
                          label: "Development Artifacts",
                        });
                      }}
                      style={{
                        transform: showAccordian[3]
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                        transition: "0.3s ease",
                      }}
                    />
                  </div>
                </div>
                {showAccordian[3] ? (
                  <div>
                    <div className="header-border-bot"></div>
                  </div>
                ) : null}
                {showAccordian[3] ? (
                  <div>
                    <div className="accordion-content">
                      <label
                        htmlFor="pre-req"
                        style={{
                          color: "#fff",
                          marginTop: "10px",
                          position: "relative",
                          width: "100%",
                        }}
                      >
                        Sub header <span className="validation-accord"> *</span>
                      </label>
                      <input
                        type="text"
                        name=""
                        placeholder="&bull; Enter a prerequisites"
                        maxLength="256"
                        id="pre-req"
                        style={{
                          border:
                            artifcatsValidation.titleStatus === "error" &&
                            "1px solid #f87c86",
                        }}
                        value={artifacts.title}
                        onChange={(e) => {
                          dispatch({
                            type: "SET_ARTIFACTS",
                            payload: {
                              artifacts: {
                                ...artifacts,
                                title: e.target.value,
                              },
                            },
                          });
                          // setArtifacts({ ...artifacts, title: e.target.value });
                          // if (accordianStatus[3] !== "In Progress")
                          //   setAccordianStatus(
                          //     accordianStatus.map((val, i) =>
                          //       i === 3 ? "In Progress" : val
                          //     )
                          //   );
                        }}
                        onBlur={() =>
                          artifacts.title.length > 5
                            ? dispatch({
                                type: "SET_ARTIFACTS_Validation",
                                payload: {
                                  artifcatsValidation: {
                                    ...artifcatsValidation,
                                    titleStatus: "success",
                                  },
                                },
                              })
                            : dispatch({
                                type: "SET_ARTIFACTS_Validation",
                                payload: {
                                  artifcatsValidation: {
                                    ...artifcatsValidation,
                                    titleStatus: "error",
                                  },
                                },
                              })
                        }
                      />
                      {artifcatsValidation.titleStatus === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            Character length should be greater than 5
                          </span>
                        </div>
                      )}

                      <p
                        style={{
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "22px",
                        }}
                      >
                        Description <span className="validation-accord">*</span>{" "}
                      </p>
                      <textarea
                        name=""
                        id=""
                        cols="80"
                        rows="3"
                        placeholder=""
                        maxLength="600"
                        style={{
                          border:
                            artifcatsValidation.descriptionStatus === "error" &&
                            "1px solid #f87c86",
                        }}
                        value={artifacts.description}
                        onChange={(e) => {
                          dispatch({
                            type: "SET_ARTIFACTS",
                            payload: {
                              artifacts: {
                                ...artifacts,
                                description: e.target.value,
                              },
                            },
                          });
                          // setArtifacts({
                          //   ...artifacts,
                          //   description: e.target.value,
                          // });
                          if (accordianStatus[3] !== "In Progress")
                            setAccordianStatus(
                              accordianStatus.map((val, i) =>
                                i === 3 ? "In Progress" : val
                              )
                            );
                        }}
                        onBlur={() =>
                          artifacts.description.length > 5
                            ? dispatch({
                                type: "SET_ARTIFACTS_Validation",
                                payload: {
                                  artifcatsValidation: {
                                    ...artifcatsValidation,
                                    descriptionStatus: "success",
                                  },
                                },
                              })
                            : dispatch({
                                type: "SET_ARTIFACTS_Validation",
                                payload: {
                                  artifcatsValidation: {
                                    ...artifcatsValidation,
                                    descriptionStatus: "error",
                                  },
                                },
                              })
                        }
                      />
                      {artifcatsValidation.descriptionStatus === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            Character length should be greater than 5
                          </span>
                        </div>
                      )}

                      <div className="card-details-cta-btns">
                        <div
                          className="outline-cta-btns"
                          style={{
                            outline:
                              artifacts.contentType === "file"
                                ? "1px solid #50e6ff"
                                : "",
                          }}
                          onClick={
                            () =>
                              dispatch({
                                type: "SET_ARTIFACTS",
                                payload: {
                                  artifacts: {
                                    ...artifacts,
                                    contentType: "file",
                                  },
                                },
                              })
                            // setArtifacts({ ...artifacts, contentType: "file" })
                          }
                        >
                          <input
                            type="radio"
                            id="step-by-step3"
                            name="creation-option4"
                            value="step-by-step3"
                            checked={
                              artifacts.contentType === "file" ? true : false
                            }
                          />
                          <label for="step-by-step3">Upload file</label>
                        </div>
                        <div
                          className="outline-cta-btns"
                          style={{
                            outline:
                              artifacts.contentType === "code"
                                ? "1px solid #50e6ff"
                                : "",
                          }}
                          onClick={
                            () =>
                              dispatch({
                                type: "SET_ARTIFACTS",
                                payload: {
                                  artifacts: {
                                    ...artifacts,
                                    contentType: "code",
                                  },
                                },
                              })
                            // setArtifacts({ ...artifacts, contentType: "code" })
                          }
                        >
                          <input
                            type="radio"
                            id="upload3"
                            name="creation-option4"
                            value="upload3"
                            checked={
                              artifacts.contentType === "code" ? true : false
                            }
                          />
                          <label for="upload3">Enter code</label>
                        </div>
                      </div>
                      {artifacts.contentType === "code" ? (
                        <>
                          <input
                            type="text"
                            name=""
                            style={{
                              marginTop: "8px",
                              border:
                                artifcatsValidation.codeStatus === "error" &&
                                "1px solid #f87c86",
                            }}
                            placeholder=" Enter code"
                            maxLength="600"
                            id="pre-req"
                            value={
                              artifacts.contentType === "code"
                                ? artifacts.code
                                : ""
                            }
                            onChange={(e) => {
                              dispatch({
                                type: "SET_ARTIFACTS",
                                payload: {
                                  artifacts: {
                                    ...artifacts,
                                    code: e.target.value,
                                  },
                                },
                              });
                              // setArtifacts({
                              //   ...artifacts,
                              //   code: e.target.value,
                              // });
                              if (accordianStatus[3] !== "In Progress")
                                setAccordianStatus(
                                  accordianStatus.map((val, i) =>
                                    i === 3 ? "In Progress" : val
                                  )
                                );
                            }}
                            onBlur={() =>
                              artifacts.code.length > 5
                                ? dispatch({
                                    type: "SET_ARTIFACTS_Validation",
                                    payload: {
                                      artifcatsValidation: {
                                        ...artifcatsValidation,
                                        codeStatus: "success",
                                      },
                                    },
                                  })
                                : dispatch({
                                    type: "SET_ARTIFACTS_Validation",
                                    payload: {
                                      artifcatsValidation: {
                                        ...artifcatsValidation,
                                        codeStatus: "error",
                                      },
                                    },
                                  })
                            }
                          />
                          {artifcatsValidation.codeStatus === "error" && (
                            <div className="validation">
                              <img src="./icon/input-error.svg" />

                              <span className="error-message">
                                Character length should be greater than 5
                              </span>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          {" "}
                          <div className="uploading-asset">
                            <div>
                              <img src="./icon/uploading-asset.svg" alt="" />
                            </div>
                            <span>Drag and Drop or</span>
                            <input
                              type="file"
                              style={{
                                opacity: 0,
                                position: "absolute",
                                marginTop: "35px",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                              accept=".jpg, .jpeg, .png, .svg"
                              onChange={handleArtifactImage}
                            />
                            <button>Choose file</button>
                            <p>JPG, JPEG, PNG, SVG</p>
                          </div>
                          {artifcatsValidation.contentStatus.fileName && (
                            <div
                              className="uploading-status"
                              style={{
                                border:
                                  artifcatsValidation.contentStatus.status ===
                                    "error" && "1px solid #f87c86",
                              }}
                            >
                              <span>
                                {artifcatsValidation.contentStatus.fileName}
                              </span>
                              <img
                                style={{ cursor: "pointer" }}
                                src="./icon/close-modal.svg"
                                width={15}
                                height={15}
                                onClick={() =>
                                  dispatch({
                                    type: "SET_ARTIFACTS_Validation",
                                    payload: {
                                      artifcatsValidation: {
                                        ...artifcatsValidation,
                                        contentStatus: {
                                          fileName: "",
                                          status: "",
                                        },
                                      },
                                    },
                                  })
                                }
                              />
                            </div>
                          )}
                          {artifcatsValidation.contentStatus.status ===
                            "error" && (
                            <div className="validation">
                              <img src="./icon/input-error.svg" />

                              <span className="error-message">
                                Unable to read the file. Make sure the file is
                                not corrupted and try again.
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      <div
                        className="next-btn"
                        onClick={() => {
                          setShowAccordian(
                            showAccordian.map((val, i) =>
                              i === 3 ? false : i === 4 ? true : false
                            )
                          );
                          if (
                            artifacts.title.length > 3 &&
                            artifacts.description.length > 3 &&
                            artifacts.contentType == "file"
                              ? artifacts.content
                              : artifacts.code.length > 3
                          )
                            setAccordianStatus(
                              accordianStatus.map((val, i) =>
                                i === 3 ? "Completed" : val
                              )
                            );
                          setSelectedTitle({
                            value: "Resources",
                            label: "Resources",
                          });
                        }}
                      >
                        {" "}
                        <button>
                          Next Section:
                          <img
                            style={{}}
                            src="../icon/next-section-accord.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>

                    {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "20px 20px 20px 20px",
                      alignItems: "end",
                    }}
                  ></div> */}
                  </div>
                ) : null}
              </div>
              <div class="accordion">
                {/* 5*/}
                <div
                  class="accordion-header"
                  onClick={() => {
                    setShowAccordian(
                      showAccordian.map((val, i) => (i === 4 ? !val : false))
                    );

                    setSelectedTitle({
                      value: "Resources",
                      label: "Resources",
                    });
                  }}
                >
                  <p>
                    {" "}
                    Add resources
                    {/* <span className="validation-accord">*</span> */}
                  </p>
                  <div>
                    <div className="accordion-status">{accordianStatus[4]}</div>
                    <img
                      src="../icon/chevron-down-acc-header.svg"
                      alt=""
                      onClick={() => {
                        setShowAccordian(
                          showAccordian.map((val, i) =>
                            i === 4 ? !val : false
                          )
                        );

                        setSelectedTitle({
                          value: "Resources",
                          label: "Resources",
                        });
                      }}
                      style={{
                        transform: showAccordian[4]
                          ? "rotate(-180deg)"
                          : "rotate(0deg)",
                        transition: "0.3s ease",
                      }}
                    />
                  </div>
                </div>
                {showAccordian[4] ? (
                  <div>
                    <div className="header-border-bot"></div>
                  </div>
                ) : null}
                {showAccordian[4] ? (
                  <div>
                    <div className="accordion-content">
                      <p
                        style={{
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "22px",
                        }}
                      >
                        Description <span className="validation-accord">*</span>{" "}
                      </p>
                      <textarea
                        name=""
                        id=""
                        cols="80"
                        rows="3"
                        placeholder=""
                        style={{
                          border:
                            resourceValidation.descriptionStatus === "error" &&
                            "1px solid #f87c86",
                        }}
                        maxLength="600"
                        value={resource?.description}
                        onChange={(e) => {
                          dispatch({
                            type: "SET_RESOURCE",
                            payload: {
                              resource: {
                                ...resource,
                                description: e.target.value,
                              },
                            },
                          });
                          if (accordianStatus[4] !== "In Progress")
                            setAccordianStatus(
                              accordianStatus.map((val, i) =>
                                i === 4 ? "In Progress" : val
                              )
                            );
                        }}
                        onBlur={() =>
                          resource.description.length > 5
                            ? dispatch({
                                type: "SET_RESOURCE_Validation",
                                payload: {
                                  resourceValidation: {
                                    ...resourceValidation,
                                    descriptionStatus: "success",
                                  },
                                },
                              })
                            : dispatch({
                                type: "SET_RESOURCE_Validation",
                                payload: {
                                  resourceValidation: {
                                    ...resourceValidation,
                                    descriptionStatus: "error",
                                  },
                                },
                              })
                        }
                      />
                      {resourceValidation.descriptionStatus === "error" && (
                        <div className="validation">
                          <img src="./icon/input-error.svg" />

                          <span className="error-message">
                            Character length should be greater than 5
                          </span>
                        </div>
                      )}

                      <div className="card-details-cta-btns">
                        <div
                          className="outline-cta-btns"
                          style={{
                            outline:
                              resource.contentType === "text"
                                ? "1px solid #50e6ff"
                                : "",
                          }}
                          onClick={() =>
                            dispatch({
                              type: "SET_RESOURCE",
                              payload: {
                                resource: { ...resource, contentType: "text" },
                              },
                            })
                          }
                        >
                          <input
                            type="radio"
                            id="resource-text"
                            name="creation-option5"
                            value="step-by-step3"
                            checked={
                              resource.contentType === "text" ? true : false
                            }
                          />
                          <label for="resource-text">Enter Text</label>
                        </div>
                        <div
                          className="outline-cta-btns"
                          style={{
                            outline:
                              resource.contentType === "file"
                                ? "1px solid #50e6ff"
                                : "",
                          }}
                          onClick={
                            () =>
                              dispatch({
                                type: "SET_RESOURCE",
                                payload: {
                                  resource: {
                                    ...resource,
                                    contentType: "file",
                                  },
                                },
                              })

                            // setResource({ ...resource, contentType: "file" })
                          }
                        >
                          <input
                            type="radio"
                            id="resource-file"
                            name="creation-option5"
                            value="upload3"
                            checked={
                              resource.contentType === "file" ? true : false
                            }
                          />
                          <label for="resource-file">Upload files</label>
                        </div>
                      </div>

                      {resource.contentType === "text" ? (
                        resource.texts?.map((textData, i) => (
                          <React.Fragment key={i}>
                            <div
                              style={
                                i !== 0
                                  ? gettingStartedTextStyle
                                  : {
                                      width: "calc(100% - 6px)",
                                      marginTop: "8px",
                                    }
                              }
                            >
                              <input
                                type="text"
                                name=""
                                placeholder=" Enter text"
                                id="pre-req"
                                maxLength="256"
                                value={textData}
                                style={{
                                  cursor: textData.length > 3 && "pointer",
                                  border:
                                    resourceValidation.textsStatus[i] ===
                                      "error" && "1px solid #f87c86",
                                  // color: resource.textsLink[i] && "#50E6FF",
                                }}
                                onChange={(e) => {
                                  dispatch({
                                    type: "SET_RESOURCE",
                                    payload: {
                                      resource: {
                                        ...resource,
                                        texts: resource.texts.map((tx, j) =>
                                          j === i ? e.target.value : tx
                                        ),
                                      },
                                    },
                                  });
                                  // setResource({
                                  //   ...resource,
                                  //   texts: resource.texts.map((tx, j) =>
                                  //     j === i ? e.target.value : tx
                                  //   ),
                                  // });
                                  if (accordianStatus[4] !== "In Progress")
                                    setAccordianStatus(
                                      accordianStatus.map((val, i) =>
                                        i === 4 ? "In Progress" : val
                                      )
                                    );
                                }}
                                onClick={(e) => {
                                  if (textData.length > 3) {
                                    const selection = window.getSelection();
                                    const selectedWord = selection
                                      .toString()
                                      .trim();
                                    if (selectedWord) {
                                      setPopoverTextIndex(i);
                                      handleClick(e);
                                      dispatch({
                                        type: "SET_RESOURCE",
                                        payload: {
                                          resource: {
                                            ...resource,
                                            highlightText:
                                              resource.highlightText.map(
                                                (tx, j) =>
                                                  j === i ? selectedWord : tx
                                              ),
                                          },
                                        },
                                      });
                                      // setResource({
                                      //   ...resource,
                                      //   highlightText:
                                      //     resource.highlightText.map((tx, j) =>
                                      //       j === i ? selectedWord : tx
                                      //     ),
                                      // });
                                    }
                                  }
                                }}
                                onBlur={() =>
                                  textData.length > 5
                                    ? dispatch({
                                        type: "SET_RESOURCE_Validation",
                                        payload: {
                                          resourceValidation: {
                                            ...resourceValidation,
                                            textsStatus:
                                              resourceValidation.textsStatus.map(
                                                (status, j) =>
                                                  i === j ? "success" : status
                                              ),
                                          },
                                        },
                                      })
                                    : dispatch({
                                        type: "SET_RESOURCE_Validation",
                                        payload: {
                                          resourceValidation: {
                                            ...resourceValidation,
                                            textsStatus:
                                              resourceValidation.textsStatus.map(
                                                (status, j) =>
                                                  i === j ? "error" : status
                                              ),
                                          },
                                        },
                                      })
                                }
                              />
                              {resourceValidation.textsStatus[i] ===
                                "error" && (
                                <div className="validation">
                                  <img src="./icon/input-error.svg" />

                                  <span className="error-message">
                                    Character length should be greater than 5
                                  </span>
                                </div>
                              )}

                              {i !== 0 && (
                                <img
                                  src="./icon/accord-url-cross.svg"
                                  style={{}}
                                  alt=""
                                  onClick={() => {
                                    dispatch({
                                      type: "SET_RESOURCE",
                                      payload: {
                                        resource: {
                                          ...resource,
                                          texts: resource.texts.filter(
                                            (tx, j) => j !== i
                                          ),
                                        },
                                      },
                                    });
                                    dispatch({
                                      type: "SET_RESOURCE",
                                      payload: {
                                        resource: {
                                          ...resource,
                                          texts: resource.textsLink.filter(
                                            (tx, j) => j !== i
                                          ),
                                        },
                                      },
                                    });
                                    // setResource({
                                    //   ...resource,
                                    //   texts: resource.texts.filter(
                                    //     (tx, j) => j !== i
                                    //   ),
                                    // });
                                    // setResource({
                                    //   ...resource,
                                    //   texts: resource.textsLink.filter(
                                    //     (tx, j) => j !== i
                                    //   ),
                                    // });
                                  }}
                                />
                              )}
                              {resourceValidation.textsStatus[i] ===
                                "error" && (
                                <div className="validation">
                                  <img src="./icon/input-error.svg" />

                                  <span className="error-message">
                                    Character length should be greater than 5
                                  </span>
                                </div>
                              )}
                            </div>
                            {resource.texts.length - 1 === i ? (
                              <img
                                style={{ marginTop: "8px" }}
                                src="./icon/add-authors.svg"
                                alt=""
                                onClick={() => {
                                  dispatch({
                                    ...resource,
                                    texts: resource.texts.filter(
                                      (tx, j) => j !== i
                                    ),
                                  });
                                  dispatch({
                                    ...resource,
                                    textsLink: [...resource.textsLink, ""],
                                  });
                                  // setResource({
                                  //   ...resource,
                                  //   texts: [...resource.texts, ""],
                                  // });
                                  // setResource({
                                  //   ...resource,
                                  //   textsLink: [...resource.textsLink, ""],
                                  // });
                                }}
                              />
                            ) : null}
                          </React.Fragment>
                        ))
                      ) : (
                        <>
                          <div className="uploading-asset">
                            <div>
                              <img src="./icon/uploading-asset.svg" alt="" />
                            </div>
                            <span>Drag and Drop or</span>
                            <input
                              type="file"
                              style={{
                                opacity: 0,
                                position: "absolute",
                                marginTop: "30px",

                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                              }}
                              accept=".jpg, .jpeg, .png, .svg"
                              onChange={handleResourceImage}
                            />
                            <button>Choose file</button>
                            <p>JPG, JPEG, PNG, SVG</p>
                          </div>
                          {resourceValidation.fileStatus.fileName && (
                            <div
                              className="uploading-status"
                              style={{
                                border:
                                  resourceValidation.fileStatus.status ===
                                    "error" && "1px solid #f87c86",
                              }}
                            >
                              <span>
                                {resourceValidation.fileStatus.fileName}
                              </span>
                              <img
                                style={{ cursor: "pointer" }}
                                src="./icon/close-modal.svg"
                                width={15}
                                height={15}
                                onClick={() =>
                                  dispatch({
                                    type: "SET_RESOURCE_Validation",
                                    payload: {
                                      resourceValidation: {
                                        ...resourceValidation,
                                        fileStatus: {
                                          fileName: "",
                                          status: "",
                                        },
                                      },
                                    },
                                  })
                                }
                              />
                            </div>
                          )}
                          {resourceValidation.fileStatus.status === "error" && (
                            <div className="validation">
                              <img src="./icon/input-error.svg" />

                              <span className="error-message">
                                Unable to read the file. Make sure the file is
                                not corrupted and try again.
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      <Popover
                        sx={{
                          "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                            display: "flex",
                            width: "302px",
                            padding: "15px",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            gap: "10px",

                            color: "#fff",
                            position: "absolute",
                            borderRadius: "4px",
                            background: "#373737",
                            boxShadow:
                              "0px 4px 17.3px 5px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                            top: "388px",
                            left: "1268px",
                          },
                        }}
                        style={{}}
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => {
                          handleClose();
                        }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <div className="popover-text-container">
                          <label>Text to display</label>
                          <input
                            type="text"
                            name=""
                            placeholder=" Enter text"
                            id="pre-req"
                            value={resource.highlightText[popoverTextIndex]}
                            readOnly
                          />
                        </div>
                        <div className="popover-text-container">
                          <label>Address</label>
                          <input
                            type="text"
                            name=""
                            placeholder=" Enter text"
                            id="pre-req"
                            maxLength="256"
                            value={resource.textsLink[popoverTextIndex]}
                            onChange={(e) => {
                              dispatch({
                                type: "SET_RESOURCE",
                                payload: {
                                  resource: {
                                    ...resource,
                                    textsLink: resource.textsLink.map((tx, j) =>
                                      j === popoverTextIndex
                                        ? e.target.value
                                        : tx
                                    ),
                                  },
                                },
                              });
                              // setResource({
                              //   ...resource,
                              //   textsLink: resource.textsLink.map((tx, j) =>
                              //     j === popoverTextIndex ? e.target.value : tx
                              //   ),
                              // });
                            }}
                          />
                        </div>
                        <div className="popover-button-container">
                          <div
                            className="popover-button"
                            onClick={() => {
                              handleClose();
                              dispatch({
                                type: "SET_RESOURCE",
                                payload: {
                                  resource: {
                                    ...resource,
                                    textsLink: resource.textsLink.map((tx, j) =>
                                      j === popoverTextIndex ? "" : tx
                                    ),
                                  },
                                },
                              });
                              // setResource({
                              //   ...resource,
                              //   textsLink: resource.textsLink.map((tx, j) =>
                              //     j === popoverTextIndex ? "" : tx
                              //   ),
                              // });
                            }}
                          >
                            Cancel
                          </div>
                          <div className="popover-button" onClick={handleClose}>
                            Insert
                          </div>
                        </div>
                      </Popover>
                      <div
                        className="next-btn"
                        onClick={() => {
                          setShowAccordian(
                            showAccordian.map((val, i) =>
                              i === 4 ? false : false
                            )
                          );
                          if (
                            resource.description.length > 3 &&
                            resource.contentType === "text"
                              ? resource.texts.filter(
                                  (textData) => textData.length > 3
                                ).length === 3
                              : resource.file
                          )
                            setAccordianStatus(
                              accordianStatus.map((val, i) =>
                                i === 4 ? "Completed" : val
                              )
                            );
                        }}
                      >
                        {" "}
                        <button>
                          Review
                          <img
                            style={{}}
                            src="../icon/next-section-accord.svg"
                            alt=""
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <ToolTip
                title={<img src="./images/drops/sidepanelPreview.png" />}
                width={640}
                height={560}
              >
                <b className="example-text"> Show me an example.</b>
              </ToolTip>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SidePanel;
