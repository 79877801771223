import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const useAnalytics = () => {
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: "f09c8d7dfd2e4508b6675609afe24cec",
      channelConfiguration: {
        eventsLimitInMem: 50,
      },
      propertyConfiguration: {
        env: "PROD",
      },
      webAnalyticsConfiguration: {
        autoCapture: {
          scroll: true,
          pageView: true,
          onLoad: true,
          onUnload: true,
          click: true,
          resize: true,
          jsError: true,
        },
      },
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();
};
