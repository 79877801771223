import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import CodeCopyBtn from "../navigation/buttons/CodeCopyButton";
import "./index.css";

const MdFilePreviewer = ({ previewText, githubLink }) => {
  const [markdown, setMarkdown] = useState("");
  const [imgIndex, setImgIndex] = useState(0);
  const Pre = ({ children }) => (
    <pre className="blog-pre">
      <CodeCopyBtn>{children}</CodeCopyBtn>
      {children}
    </pre>
  );

  const Note = ({ children }) => {
    return (
      <div
        style={{
          backgroundColor: "#f0f0f0",
          padding: "10px",
          margin: "10px 0",
          borderLeft: "5px solid #ccc",
        }}
      >
        {children}
      </div>
    );
  };

  const renderers = {
    image: ({ src, alt }) => {
      const imageUrl = `
      ${githubLink}${src}`;
      return <img src={imageUrl} alt={alt} />;
    },
  };

  const BlockQuote = ({ children, props }) => {
    // Create a new array with modified children

    let content = "";
    try {
      if (
        children[1]?.props?.children[0].props.children
          .toUpperCase()
          .includes("IMPORTANT")
      )
        content = "Important";
      var modifiedChildren = children;
    } catch (e) {
      try {
        if (
          children[1]?.props?.children[0]?.includes("[!NOTE]") ||
          children[1]?.props?.children?.includes("[!NOTE]") ||
          children[1]?.props?.children[0]?.includes("[!IMPORTANT]") ||
          children[1]?.props?.children?.includes("[!IMPORTANT]") ||
          children[1]?.props?.children[0]?.includes("[!TIP]") ||
          children[1]?.props?.children?.includes("[!TIP]") ||
          children[1]?.props?.children[0]?.includes("[!CAUTION]") ||
          children[1]?.props?.children?.includes("[!CAUTION]") ||
          children[1]?.props?.children[0]?.includes("[!WARNING]") ||
          children[1]?.props?.children?.includes("[!WARNING]") ||
          children[1]?.props?.children[0]?.includes("[!DISCLAIMER]") ||
          children[1]?.props?.children?.includes("[!DISCLAIMER]")
        ) {
          var modifiedChildren = React.Children.map(
            children,
            (child, index) => {
              if (index === 1 && React.isValidElement(child)) {
                const childContent = child.props.children;

                let modifiedContent = "";

                if (
                  childContent[0].includes("[!IMPORTANT]") ||
                  childContent.includes("[!IMPORTANT]")
                ) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace(
                              "[!IMPORTANT]",
                              "<strong>Important:</strong>"
                            )
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!IMPORTANT]",
                      "<strong>Important:</strong>"
                    );
                  content = "Important";
                } else if (childContent[0].includes("[!TIP]")) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace("[!TIP]", "<strong>Tip:</strong>")
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  modifiedContent = childContent.replace(
                    "[!TIP]",
                    "<strong>Tip:</strong>"
                  );
                  content = "Tip";
                } else if (childContent[0].includes("[!CAUTION]")) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace(
                              "[!CAUTION]",
                              "<strong>Caution:</strong>"
                            )
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!CAUTION]",
                      "<strong>Caution:</strong>"
                    );

                  content = "Caution";
                } else if (childContent[0].includes("[!DISCLAIMER]")) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace(
                              "[!DISCLAIMER]",
                              "<strong>Disclaimer:</strong>"
                            )
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!DISCLAIMER]",
                      "<strong>Disclaimer:</strong>"
                    );

                  content = "Disclaimer";
                } else if (childContent.includes("[!WARNING]")) {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace(
                              "[!WARNING]",
                              "<strong>Warning:</strong>"
                            )
                          : typeof child === "object"
                          ? `<strong>${child.props.children}</strong>`
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!WARNING]",
                      "<strong>Warning:</strong>"
                    );
                  content = "Warning";
                } else {
                  if (typeof childContent === "object")
                    modifiedContent = childContent
                      .map((child, i) =>
                        i === 0
                          ? child.replace("[!NOTE]", "<strong>Note:</strong>")
                          : typeof child === "object"
                          ? child.props?.children
                            ? `<strong>${child.props.children}</strong>`
                            : null
                          : child
                      )
                      .join("");
                  else
                    modifiedContent = childContent.replace(
                      "[!NOTE]",
                      "<strong>Note:</strong>"
                    );
                  content = "Note";
                }

                return (
                  <span dangerouslySetInnerHTML={{ __html: modifiedContent }} />
                );
              }
              // Return the child unchanged
              return child;
            }
          );
        } else var modifiedChildren = children;
      } catch (err) {
        var modifiedChildren = children;
      }
    }

    return (
      <blockquote
        style={{
          backgroundColor:
            content === "Important"
              ? "#5E5176"
              : content === "Tip"
              ? "#3A5937"
              : content === "Caution"
              ? "#75383C"
              : content === "Disclaimer"
              ? "#625D31"
              : content === "Warning"
              ? "#625D31"
              : "#2F3646",
          color:
            content === "Important"
              ? "#F3ECFF"
              : content === "Tip"
              ? "#D8FFD5"
              : content === "Caution"
              ? "#FFE2E3"
              : content === "Disclaimer"
              ? "#FFF492"
              : content === "Warning"
              ? "#FFF492"
              : "#B2CDFF",
          paddingLeft: "10px",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "20px",
          padding: "14px 10px",
          margin: "0 0 10px 0",
          borderRadius: "10px",
        }}
      >
        <span style={{ display: "flex", alignItems: "start" }}>
          <img
            src={
              content === "Important"
                ? "./icon/important-info.svg"
                : content === "Tip"
                ? "./icon/tip.svg"
                : content === "Caution"
                ? "./icon/caution-info.svg"
                : content === "Disclaimer"
                ? "./icon/disclaimer-info.svg"
                : content === "Warning"
                ? "./icon/disclaimer-info.svg"
                : "./icon/note-info.svg"
            }
            style={{
              marginTop: content === "" ? "13px" : "2px",
              marginRight: "5px",
            }}
          />

          <span style={{ marginLeft: "" }}>{modifiedChildren}</span>
        </span>
      </blockquote>
    );
  };

  const EmphasizedNote = ({ children }) => {
    return (
      <span
        style={{
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "20px",
          background: children === "IMPORTANT" && "red",
        }}
      >
        {children}
      </span>
    );
  };

  return (
    <div>
      <ReactMarkdown
        className="post-markdown"
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className = "blog-code", children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            const codeText = children; // Assuming there is only one child

            const copyToClipboard = () => {
              navigator.clipboard
                .writeText(codeText)
                .then(() => console.log("done"))
                .catch((error) => console.error("Failed to copy:", error));
            };

            return !inline && match ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  className="cli-code"
                  style={{
                    backgroundColor: "#666666",
                    borderTopLeftRadius: "4px",
                    borderTopRightRadius: "4px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      padding: "5px",
                    }}
                    onClick={copyToClipboard}
                    className="code-copy-btn"
                  >
                    <div>{node?.properties?.className[0]?.split("-")[1]}</div>
                    <img
                      className="copy-btn"
                      width={"23px"}
                      src="./icon/copy-square.svg"
                      alt="Copy"
                    />{" "}
                    <img
                      className="hovered-copy-btn"
                      width={"23px"}
                      src="./icon/hovered-copy.svg"
                      alt="Copy"
                    />
                  </div>
                </div>
                <div
                  className="cli-code-text"
                  style={{
                    backgroundColor: "#282C34",
                    minWidth: "20px",
                    minHeight: "40px",
                    width: "100%",
                    color: "#fff",

                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    borderRadius: "4px",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                >
                  <div
                    style={{
                      padding: "15px 10px 10px 10px",
                      whiteSpace: "pre-line",
                      wordBreak: "break-word",
                    }}
                  >
                    {node?.properties?.className[0]?.split("-")[1] ===
                    "json" ? (
                      <pre className="shell-pre">
                        <code>
                          {children.split("\n").map((line, index) => (
                            <span key={index}>
                              {line.startsWith("{") ||
                              line.startsWith("}") ||
                              line.startsWith("[") ||
                              line.startsWith("]") ? (
                                <span className="json-curly-bracket">
                                  <br />
                                  {line}
                                </span>
                              ) : line.startsWith('"') ? (
                                <span className="json-string">{line}</span>
                              ) : (
                                <>
                                  <span className="json-key">
                                    {line.split(":")[0]}
                                  </span>
                                  {line.split(":")[1] && ":"}
                                  <span className="json-value">
                                    {line.split(":")[1]}
                                  </span>
                                  <br />
                                </>
                              )}
                            </span>
                          ))}
                        </code>
                      </pre>
                    ) : (
                      <code className="language-shell" {...props}>
                        {children}
                      </code>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          p: ({ node, children, ...props }) => {
            try {
              const isBlockQuote =
                children && children[0]?.props?.children?.startsWith("Note:");

              return (
                <p
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",

                    fontStyle: "normal",
                    fontWeight: "400",

                    color: isBlockQuote ? "inherit" : "#fff",
                  }}
                  {...props}
                >
                  {children}
                </p>
              );
            } catch (e) {
              console.log(e);
            }
          },

          h3: ({ node, children, ...props }) => {
            return (
              <h3
                style={{
                  fontSize: "16px",
                  lineHeight: "16px",

                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#FFFFFF",
                }}
                {...props}
              >
                {children}
              </h3>
            );
          },
          img: ({ src, alt }) => {
            let imageUrl = "";
            let mainImageUrl = "";
            let masterImageUrl = "";

            if (src.includes("badge")) return;

            if (!src.includes("http")) {
              if (src.startsWith(".")) {
                imageUrl = `${githubLink}${src.substring(1)}`;
                mainImageUrl = `${githubLink}/main${src.substring(1)}`;
                masterImageUrl = `${githubLink}/master${src.substring(1)}`;
              } else if (src.startsWith("/")) {
                imageUrl = `${githubLink}${src}`;
                mainImageUrl = `${githubLink}/main${src}`;
                masterImageUrl = `${githubLink}/master${src}`;
              } else {
                imageUrl = `${githubLink}/${src}`;
                mainImageUrl = `${githubLink}/main/${src}`;
                masterImageUrl = `${githubLink}/master/${src}`;
              }

              const urls = [imageUrl, mainImageUrl, masterImageUrl];

              const promises = urls.map((url) => fetch(url));

              Promise.all(promises)
                .then((responses) => {
                  const results = responses.map((response) => {
                    if (response.status === 200) return true;
                    else return false;
                  });
                  return Promise.all(results);
                })
                .then((data) => {
                  setImgIndex(data.indexOf(true));
                })
                .catch((error) => console.error(error));
            }
            return (
              <img
                style={{ maxWidth: "-webkit-fill-available" }}
                src={
                  src.includes("http")
                    ? src
                    : imgIndex === 0
                    ? imageUrl
                    : imgIndex === 1
                    ? mainImageUrl
                    : masterImageUrl
                }
                alt={alt}
              />
            );
          },

          li: ({ node, children, ...props }) => {
            return (
              <li
                style={{
                  fontSize: "14px",
                  lineHeight: "20px",

                  fontStyle: "normal",
                  fontWeight: "400",
                  color: "#FFFFFF",
                }}
                {...props}
              >
                {children}
              </li>
            );
          },
          ul: ({ node, children, ...props }) => {
            return (
              <div>
                <ul style={{}} {...props}>
                  {children}
                </ul>
              </div>
            );
          },

          a: ({ node, children, ...props }) => {
            return (
              <a
                style={{ textDecoration: "none" }}
                onMouseOver={() => {
                  // Add underline style on hover
                  this.style.textDecoration = "underline";
                }}
                onMouseOut={() => {
                  // Remove underline style on mouseout
                  this.style.textDecoration = "none";
                }}
                {...props}
              >
                {children}
              </a>
            );
          },

          table: ({ node, children, ...props }) => {
            return (
              <table
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  tableLayout: "fixed",
                  border: "1px solid #BDBDBD",
                }}
                {...props}
              >
                {children}
              </table>
            );
          },
          th: ({ node, children, ...props }) => {
            return (
              <th
                style={{
                  padding: "10px 12px",
                  textAlign: "left",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontSeight: "400",
                  lineHeight: "24.49px",
                  letterSpacing: "0.48px",
                  border: "1px solid #BDBDBD",
                  background: "var(--Neutral-Table-Header-Background, #38434D)",
                }}
                {...props}
              >
                {children}
              </th>
            );
          },
          td: ({ node, children, ...props }) => {
            return (
              <td
                style={{
                  wordWrap: "break-word",
                  color: "",
                  background: "#242424",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "300",
                  textAlign: "left",
                  lineHeight: "24.49px",
                  letterSpacing: "0.48px",
                  border: "1px solid #BDBDBD",
                  padding: "8px",
                }}
                {...props}
              >
                {children}
              </td>
            );
          },
          a: ({ node, href, children, ...props }) => {
            const requiredUrl = githubLink?.split("/");
            let modifiedhref = href;
            if (!href?.includes("http") && githubLink)
              modifiedhref = `https://github.com/${requiredUrl[3]}/${requiredUrl[4]}/blob/${requiredUrl[5]}/${href}`;

            return (
              <a href={modifiedhref} {...props} target="_blank">
                {children}
              </a>
            );
          },

          blockquote: BlockQuote,
          strong: EmphasizedNote,
        }}
      >
        {previewText}
      </ReactMarkdown>
    </div>
  );
};

export default MdFilePreviewer;
