import { useEffect, useLayoutEffect, useState } from "react";

import Select from "react-select";

import { Link } from "react-router-dom";
import ShareMetaData from "../../shareMetaData";
import MdFilePreviewer from "../../mdFilePreviewer";
const SidePanelMdPreviewer = ({ cardData, mdTextPreview }) => {
  const [mdLink, setMdLink] = useState();
  const [githubLink, setGithubLink] = useState();
  const [modalHeadings, setModalHeadings] = useState([]);
  const [previewText, setPreviewText] = useState();
  const [options, setOptions] = useState();
  const [selectedTitle, setSelectedTitle] = useState();
  const [mdText, setMdText] = useState();
  const [darkMode, setDarkMode] = useState(true);

  const { Title, Summary, Authors, Products, LastModified, Source } = cardData;

  useLayoutEffect(() => {
    if (Source) {
      if (Source.includes("blob")) {
        const parts = Source.split("/blob");
        var newLink = parts[0] + parts[1];
      }
      if (Source.includes("tree")) {
        const parts = Source.split("/tree");
        var newLink = parts[0] + parts[1];
      }
      setMdLink(
        newLink
          ? `https://raw.githubusercontent.com${newLink.substring(18)}`
          : `https://raw.githubusercontent.com${Source.substring(18)}`
      );
      setGithubLink(Source);
    }
  }, []);

  console.log(mdTextPreview, cardData);

  const getDataFromMD = (start, end, text) => {
    var startIndex = start ? text.indexOf(start) + start.length : 0;

    let endIndex = text.indexOf(end);
    if (endIndex === -1) {
      endIndex = text.length;
    }

    // Extract the text between the markers
    const extractedText = text.substring(startIndex, endIndex);
    setPreviewText(extractedText);
  };

  const fetchTextMapping = (text) => {
    setPreviewText();
    modalHeadings.length = 0;

    const regex = /^##(?!\s*>|\s*#)(.*)/gm;
    let match;
    while ((match = regex.exec(text)) !== null) {
      console.log(match[1], !match[1].includes("Introduction"));
      if (
        !match[1].includes(Title) &&
        !modalHeadings.includes(match[1]) &&
        !match[1].includes("Introduction")
      )
        modalHeadings.push(match[1]);
    }
    if (!modalHeadings.includes(" Overview") && modalHeadings.length > 0)
      modalHeadings.unshift("Overview");

    const lines = text.split("\n");

    if (modalHeadings.length > 0) {
      if (text.includes("linkTitle"))
        getDataFromMD(">", modalHeadings[1], text);
      else getDataFromMD("", modalHeadings[1], text);
    }

    // Find the line containing the title
    const titleLine = lines.find((line) => line.trim().startsWith("title:"));

    // Extract only the title text
    const title = titleLine ? titleLine.split(`"`)[1].trim() : "";

    setModalHeadings([...modalHeadings]);
  };

  useEffect(() => {
    setOptions(
      modalHeadings.map((title) => ({
        value: title,
        label: title,
      }))
    );
    setSelectedTitle({ value: modalHeadings[0], label: modalHeadings[0] });
  }, [modalHeadings]);

  const handleSelectedRighbarTitle = (selected) => {
    const i = modalHeadings.indexOf(selected.value);
    console.log(i);
    setSelectedTitle(selected);
    if (i === 0)
      mdText.includes("linkTitle")
        ? getDataFromMD(">", modalHeadings[i + 1], mdText)
        : getDataFromMD("", modalHeadings[i + 1], mdText);
    else getDataFromMD(selected.value, modalHeadings[i + 1], mdText);
  };

  useEffect(() => {
    if (mdLink) {
      console.log("fgfgf");
      const urls = [
        `${mdLink}/main/README.md`,
        `${mdLink}/master/README.md`,
        `${mdLink}/README.md`,
        `${mdLink}/index.md`,
        `${mdLink}/main/index.md`,
        `${mdLink}/master/index.md`,
        `${mdLink}/_index.md`,
        `${mdLink}/main/_index.md`,
        `${mdLink}/master/_index.md`,
      ];

      const promises = urls.map((url) => fetch(url));

      Promise.all(promises)
        .then((responses) => {
          const results = responses.map((response) => response.text());
          return Promise.all(results);
        })
        .then((data) => {
          const mdData = data.find((text) => text.length > 15);
          setMdText(mdData);
          fetchTextMapping(mdData);
        })
        .catch((error) => console.error(error));
    } else if (mdTextPreview) {
      setMdText(mdTextPreview);
      fetchTextMapping(mdTextPreview);
    }
  }, [mdLink]);

  const rightBarLastModifiedFormat = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate; // Output: April 4, 2024
  };

  const ModalHeader = ({ darkMode, onClose }) => {
    const headerStyles = {
      color: darkMode ? "#fff" : "#333",
      textAlign: "center",
      marginBottom: "40px",
      position: "relative",
    };

    return (
      <>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            marginRight: "10px",
            paddingTop: "10px",
          }}
          //   onClick={onClose}
        ></span>
        <div style={headerStyles}>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <h3
                style={{
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "26px" /* 80% */,
                  letterSpacing: "0.6px",
                  marginBottom: "5px",
                  display: "flex",
                  paddingLeft: "60px",
                  wordBreak: "break-word",
                  textAlign: "left",
                }}
              >
                {Title}
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "start",
                  paddingLeft: "60px",
                  flexWrap: "wrap",
                }}
              >
                {" "}
                {Authors.map((author, i) => (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        // padding: "0 60px",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "350",
                        lineHeight: "24px",
                        letterSpacing: "0.7px",
                      }}
                      key={i}
                    >
                      {i === 0 ? "By " : " |"}

                      <span
                        style={{
                          color: "rgb(80, 230, 255)",
                          cursor: "pointer",
                          padding: "0 5px",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "24px",
                          letterSpacing: "0.7px",
                        }}
                        onClick={() => window.open(author?.Link, "_blank")}
                      >
                        {" "}
                        {author?.Name}
                      </span>
                      {i === Authors.length - 1 ? (
                        <div
                          style={{
                            display: "flex",
                            // padding: "0 60px",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "350",
                            lineHeight: "24px",
                            letterSpacing: "0.7px",
                          }}
                          key={i}
                        >
                          |
                          <span
                            style={{
                              color: "rgb(80, 230, 255)",
                              cursor: "pointer",
                              padding: "0 5px",
                              fontSize: "14px",

                              fontStyle: "normal",
                              fontWeight: "600",
                              lineHeight: "24px",
                              letterSpacing: "0.7px",
                              display: "flex",
                            }}
                            onClick={() => window.open(githubLink, "_blank")}
                          >
                            {" "}
                            View In Github
                            <img style={{}} src="./icon/github-icon.svg" />
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              {LastModified && (
                <div
                  style={{
                    display: "flex",
                    padding: "0 60px",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "350",
                    lineHeight: "24px",
                    letterSpacing: "0.7px",
                  }}
                >
                  Last Updated {rightBarLastModifiedFormat(LastModified)}
                </div>
              )}
            </div>
            <Select
              value={selectedTitle}
              onChange={handleSelectedRighbarTitle}
              options={options}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: state.hasValue ? "#474747" : "#242424",
                  width: "calc(100% - 117px)",
                  borderRadius: "10px 10px 10px 10px",
                  marginLeft: "60px",
                  marginTop: "15px",
                  boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
                  border: "1px solid var(--Neutral-Stroke-1-Rest, #666)",
                  textAlign: "left",
                  "&:hover": {
                    backgroundColor: "#383838",
                    borderColor: "#666",
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  background: "#242424",
                  color: "#ffffff",
                  marginLeft: "60px",
                  width: "calc(100% - 117px)",
                  marginTop: 0,
                  borderColor: "white",

                  outline: "",
                  boxShadow: "0px 4px 20px 0px rgba(255, 255, 255, 0.15)",
                  border: "1px solid var(--Neutral-Stroke-1-Rest, #666)",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  textAlign: "left",
                }),
                option: (defaultStyles, state) => ({
                  ...defaultStyles,

                  backgroundColor: state.isSelected ? "#383838" : "#242424",
                  "&:hover": {
                    backgroundColor: "#383838",
                  },
                }),
                input: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "White",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#ffffff",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  color: "#ffffff",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "white",
                  primary: "white",
                },
              })}
            />
          </div>
        </div>
      </>
    );
  };

  const ModalBody = ({ darkMode }) => {
    const bodyStyles = {
      color: darkMode ? "#fff" : "#333",
      padding: "60px",
      paddingTop: "0",
      maxHeight: "700px",
      overflowY: "scroll",
    };

    return (
      <div style={bodyStyles}>
        <MdFilePreviewer previewText={previewText} githubLink={mdLink} />
        {selectedTitle?.value?.includes("Overview") ? (
          <blockquote
            style={{
              background:
                "var(--Neutral-Callout-Disclaimer-Background, #625D31)",
              color: "var(--Neutral-Callout-Disclaimer-Foreground, #FFF492)",
              // color: "green",
              paddingLeft: "10px",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "20px",
              padding: "14px 10px",
              margin: "0 0 10px 0",
              borderRadius: "10px",
            }}
          >
            <span
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "row",
                padding: "22.64px 10px",
                borderRadius: "10px",
              }}
            >
              <img
                src="./icon/disclaimer-info.svg"
                style={{ marginRight: "5px", marginTop: "2px" }}
              />
              <span
              // style={{ marginLeft: "", display: "flex", flexDirection: "row" }}
              >
                <div>
                  <span
                    style={{
                      color:
                        "var(--Neutral-Callout-Disclaimer-Foreground, #FFF492)",

                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "20px" /* 142.857% */,
                      letterSpacing: "-0.14px",
                      marginRight: "8px",
                    }}
                  >
                    Disclaimer:
                  </span>
                  Please carefully review the{" "}
                  <a
                    style={{
                      color: "var(--Neutral-Callout-Hyperlink, #50E6FF)",

                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      lineHeight: "20px",
                      letterSpacing: "-0.14px",
                      cursor: "pointer",
                    }}
                    href="https://github.com/Azure/arc_jumpstart_drops/blob/main/DISCLAIMER.md"
                    target="_blank"
                  >
                    disclaimer page
                  </a>{" "}
                  before contributing or using any Jumpstart Drop to help you
                  understand the risks and limitations associated with the use
                  of all Drops.
                </div>
              </span>
            </span>
          </blockquote>
        ) : null}
      </div>
    );
  };
  return (
    <>
      <ShareMetaData title={Title} description={Summary} />
      <div className="side-panel-body">
        <ModalHeader darkMode={darkMode} />
        <ModalBody darkMode={darkMode} />
        <Link to="/azure_jumpstart_drops"></Link>
      </div>
    </>
  );
};

export default SidePanelMdPreviewer;
