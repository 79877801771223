import "./sideBar.css";
import dropsFilters from "../../../data/dropsFilter.json";
import { useMemo, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const SideBar = ({
  lastUpdated,
  setLastUpdated,
  tagCheckBox,
  difficulty,
  setDifficulty,
  programmingLanguage,
  setProgrammingLanguage,
  productFilter,
  setProductFilter,
  topic,
  setTopic,
  type,
  setType,
}) => {
  const [showDropDown, setShowDropDown] = useState(
    dropsFilters.map(() => true)
  );

  const handleDropDown = (i) => {
    setShowDropDown(
      showDropDown.map((dropDown, index) =>
        index === i ? !dropDown : dropDown
      )
    );
  };
  const TagTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
      borderRadius: 4,
      maxWidth: 240,
      maxHeight: 200,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: " var(--Neutral-Background-21-Rest, #292929)",
      boxShadow:
        "0px 4px 8px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
      fontSize: "var(--Size-200, 12px)",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "var(--Line-height-200, 16px)",
      color: "var(--NeutralForeground1.Rest)",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "#292929",
    },
  }));

  return (
    <div className="sidebar">
      {dropsFilters.map((filter, i) => (
        <div className="filter-group" key={i}>
          <div className="title" onClick={() => handleDropDown(i)}>
            <span>{filter.name}</span>
            <img
              src="./images/drops/sidebar/chevron-up.svg"
              alt=""
              style={{
                transform: !showDropDown[i] ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.2s ease-in-out",
                cursor: "pointer",
              }}
            />
          </div>
          {showDropDown[i] ? (
            <div className="collapsable-filter">
              {filter.tags.map((tag, j) => (
                <div
                  className="filter-item"
                  key={tag.name}
                  onClick={() => {
                    if (filter.name === "Last Updated")
                      setLastUpdated(
                        lastUpdated.map((last, index) =>
                          index === j ? !last : false
                        )
                      );
                    else if (filter.name === "Difficulty")
                      if (difficulty.includes(tag.name))
                        setDifficulty(
                          difficulty.filter(
                            (difficult) => difficult !== tag.name
                          )
                        );
                      else setDifficulty([...difficulty, tag.name]);
                    else if (filter.name === "Products")
                      if (
                        productFilter.filter(
                          (product) => product === tag.aliases
                        ).length > 0
                      )
                        setProductFilter(
                          productFilter.filter(
                            (product) => product !== tag.aliases
                          )
                        );
                      else setProductFilter([...productFilter, tag.aliases]);
                    else if (filter.name === "Language")
                      if (programmingLanguage.includes(tag.name))
                        setProgrammingLanguage(
                          programmingLanguage.filter(
                            (language) => language !== tag.name
                          )
                        );
                      else
                        setProgrammingLanguage([
                          ...programmingLanguage,
                          tag.name,
                        ]);
                    else if (filter.name === "Topics")
                      if (topic.includes(tag.name))
                        setTopic(topic.filter((topik) => topik !== tag.name));
                      else setTopic([...topic, tag.name]);
                    else if (filter.name === "Type")
                      if (type.includes(tag.value))
                        setType(type.filter((typ) => typ !== tag.value));
                      else setType([...type, tag.value]);
                  }}
                >
                  <input
                    type="checkbox"
                    name=""
                    checked={
                      filter.name === "Last Updated"
                        ? lastUpdated[j]
                        : filter.name === "Difficulty"
                        ? difficulty.includes(tag.name)
                          ? true
                          : false
                        : filter.name === "Products"
                        ? productFilter.filter(
                            (product) => product === tag.aliases
                          ).length > 0
                          ? true
                          : false
                        : filter.name === "Language"
                        ? programmingLanguage.includes(tag.name)
                          ? true
                          : false
                        : filter.name === "Topics"
                        ? topic.includes(tag.name)
                          ? true
                          : false
                        : filter.name === "Type"
                        ? type.includes(tag.value)
                          ? true
                          : false
                        : tagCheckBox[i][j]
                    }
                    id={tag.name}
                    hidden
                  />

                  <label htmlFor="checkbox">
                    {tag.name}
                    {tag.description &&
                    filter.name !== "Language" &&
                    filter.name !== "Difficulty" ? (
                      <TagTooltip
                        title={tag.description}
                        placement="right"
                        arrow
                      >
                        <div className="info-icon">
                          <img
                            className="normal-info-icon"
                            src="./images/drops/sidebar/info-icon.svg"
                            alt=""
                          />

                          <img
                            className="hovered-info-icon"
                            src="./images/drops/sidebar/info-icon-hover.svg"
                            alt=""
                          />
                        </div>
                      </TagTooltip>
                    ) : null}
                  </label>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default SideBar;
