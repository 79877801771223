import React, { useEffect, useState } from "react";
import MdFilePreviewer from "../../mdFilePreviewer";

const CardSchema = ({ onClose }) => {
  const [faqText, setFaqText] = useState();

  useEffect(() => {
    fetch(
      "https://raw.githubusercontent.com/Azure/arc_jumpstart_drops/main/SCHEMA.md"
    )
      .then((res) => res.text())
      .then((text) => setFaqText(text));
  }, []);
  const closeButtonStyles = {
    position: "absolute",
    top: "0",
    right: "0",
    transform: "translate(-50% , 351%)",
    cursor: "pointer",
    color: "#333",
    fontSize: "20px",
  };

  return (
    <div style={{}} className="card-schema">
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
          marginRight: "10px",
          paddingTop: "10px",
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        <img style={closeButtonStyles} src="./icon/close-modal.svg" />
      </span>
      <div
        style={{
          height: "906px",
          overflow: "scroll",
        }}
      >
        <MdFilePreviewer previewText={faqText} />
      </div>
    </div>
  );
};

export default CardSchema;
