import { useEffect, useState } from "react";
import "./banner.css";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";

const Banner = ({
  dropCardData,
  setSearchedDrop,
  setDropCardData,
  difficulty,
  setDifficulty,
  programmingLanguage,
  setProgrammingLanguage,
  productFilter,
  setProductFilter,
  topics,
  setTopics,
  type,
  setType,
  lastUpdated,
  setLastUpdated,
  inputSearch,
  setInputSearch,
}) => {
  const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);

  useEffect(() => {
    document.getElementById("drop-banner").style.cssText =
      "height: 355px; padding-top: 100px;";
    document.querySelector(".drop-banner .text-side").style.cssText =
      "padding-bottom: 0;";
    document.querySelector(" .banner-description").style.cssText =
      "opacity : 1;";

    document.getElementById("search-container").style.cssText =
      "opacity : 1; transition-delay: 0.5s; width:674px; ";
    document.getElementById("banner-img").style.cssText =
      "width: 300px; transform: translateY(100px)";

    // Cleanup on unmount
  }, []);
  const [selectedSearch, setSelectedSearch] = useState({
    value: "Search",
    label: "Search",
    image: "../icon/creation-drop-light.svg",
  });

  let paragraph = "";

  for (const key in dropCardData[0]) {
    if (Array.isArray(dropCardData[0][key])) {
      dropCardData[0][key].forEach((item) => {
        if (key === "Authors") paragraph += `${item.Name} (${item.Link}), `;
        else paragraph += `${item} `;
      });
      paragraph = paragraph.slice(0, -2); // remove the last comma and space
    } else {
      paragraph += `${dropCardData[0][key]}. `;
    }
  }

  const options = [
    {
      label: "suggested links",
      options: dropCardData.map((data) => {
        let paragraph = "";

        for (const key in data) {
          if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
              if (key === "Authors")
                paragraph += `${item.Name} (${item.Link}), `;
              else paragraph += `${item} `;
            });
            paragraph = paragraph.slice(0, -2); // remove the last comma and space
          } else {
            paragraph += `${data[key]}. `;
          }
        }

        let authorMatched = "";

        for (let item of data.Authors) {
          if (item.Name.includes(inputSearch) && inputSearch) {
            authorMatched = item.Name;
          }
        }

        return {
          value: paragraph,
          check: data.Title,
          label: (
            <span>
              {data.Title} {authorMatched ? "| by " : ""}
              {authorMatched ? <strong>{authorMatched}</strong> : ""}
            </span>
          ),
        };
      }),
    },
  ];

  const handleSelectedTitle = (selected) => {
    setIsSearchMenuOpen(false);

    const i = dropCardData.indexOf((val) => val.Title === selected.value);
    setSearchedDrop([selected.check]);
    setSelectedSearch(selected);
    setInputSearch(selected.check);
  };

  const handleKeyDown = (event) => {
    setIsSearchMenuOpen(true);
    if (event.key === "Enter") {
      setIsSearchMenuOpen(false);
      event.preventDefault();
      const filteredDrops = options[0]?.options.filter((option) =>
        option.value.includes(inputSearch)
      );

      setSearchedDrop(filteredDrops.map((drop) => drop.check));
    }

    if (
      (event.key === "Backspace" && selectedSearch.value !== "search") ||
      (event.key === "Delete" && selectedSearch.value !== "search")
    ) {
      setSelectedSearch({
        value: "Search",
        label: "Search",
        image: "../icon/creation-drop-light.svg",
      });
      setInputSearch("");
      setSearchedDrop("");
    }
  };

  const CustomOption = (props) => {
    const { children, isSelected, isFocused } = props;
    return (
      <components.Option {...props}>
        <img
          src={
            isSelected || isFocused
              ? "../icon/search-arrow-hover.svg"
              : "../icon/search-arrow.svg"
          }
          alt="tick"
          style={{
            height: "20px",
            width: "20px",
          }}
        />
        {children}
      </components.Option>
    );
  };

  const CustomSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src="./icon/searchbar-search.svg" />
      {children}
    </components.SingleValue>
  );

  const handleInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      setInputSearch(inputValue);
    }
  };

  const handleFocus = () => {
    setIsSearchMenuOpen(true);
  };

  const handleBlur = () => {
    setIsSearchMenuOpen(false);
    setSelectedSearch({
      value: inputSearch,
      label: inputSearch,
    });
  };

  const handleClearPills = () => {
    setProductFilter([]);
    setDifficulty([]);
    setProgrammingLanguage([]);
    setTopics([]);
    setType([]);
    setLastUpdated([false, false, false]);
  };

  return (
    <div>
      <div className="navdown-wrapper">
        <div
          style={{ gap: "8px", background: "black", paddingBottom: "8px" }}
          className="drops-creation-btn"
        >
          <span>
            Home <span> /</span>
            <span
              style={{
                color: "rgb(80, 230, 255)",
                cursor: "pointer",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "600 !important",
                lineHeight: "24px",
                letterSpacing: "0.65px",
              }}
            >
              {" "}
              Jumpstart Drops
            </span>
          </span>
          <Link
            to="/drops_contribution_guidelines"
            style={{ textDecoration: "none" }}
          >
            <button className="creation-btn">
              <img
                className="normal-drop-creation-icon"
                src="../icon/not-hovered-creation.svg"
                alt=""
              />
              <img
                className="hovered-drop-creation-icon"
                src="../icon/hovered-creation.svg"
                alt=""
              />
              <span>Create a Drop</span>
            </button>
          </Link>
        </div>

        <div
          className="preview-callout"
          id="preview-callout"
          style={{
            height: "25px",
            background: "#192F33",
            padding: "22px",
            display: "flex",
            justifyContent: "start",

            alignItems: "center",
            textAlign: "center",
          }}
        >
          {" "}
          <div
            className="dropcard-preview"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "8.71px",
            }}
            htmlFor=""
          >
            <button
              style={{
                fontSize: "10px",
                fontStyle: "normal",
                fontWeight: "600",
                lineHeight: "15px",
                letterSpacing: "1.5px",
                color: "#FFF",
                borderRadius: "5.263px",
                background: "#47595C",
                border: "none",

                display: "flex",
                padding: "3.947px 7.895px",
                alignItems: "center",
                gap: "6.699px",
                whiteSpace: "normal",
              }}
            >
              PREVIEW
            </button>
            <p
              style={{
                display: "flex",
                fontSize: "16.697px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24.49px",
                letterSpacing: "-0.167px",
                color: "#C5D2D5",
              }}
            >
              Explore the
              <a
                className=""
                style={{
                  fontSize: "16.697px",
                  fontStyle: "normal",
                  fontWeight: `600 !important`,
                  lineHeight: "24.49px",
                  letterSpacing: "-0.167px",
                  margin: "0 6px",
                }}
                href="https://azure.microsoft.com/en-us/support/legal/preview-supplemental-terms"
                target="_blank"
              >
                Public Preview
              </a>
              version of Jumpstart Drops.
              <a
                className=""
                style={{
                  color: "#50E6FF",
                  fontSize: "16.697px",
                  fontStyle: "normal",
                  fontWeight: `600 !important`,
                  lineHeight: "24.49px",
                  letterSpacing: "-0.167px",
                  margin: "0 6px",
                }}
                href="https://github.com/Azure/arc_jumpstart_drops/issues/new?assignees=fcabrera23&labels=enhancement&projects=&template=%F0%9F%9A%80feature-request.md&title="
                target="_blank"
              >
                {" "}
                Share your feedback
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="drop-banner" id="drop-banner">
        <div className="text-side">
          <div className="banner-description">
            <span>DEVELOPMENT ARTIFACTS</span>
            <b>
              Jumpstart <span style={{ color: "#14BDBD" }}>Drops</span>
            </b>
            <p>
              Browse a curated collection of scripts, tools, and other resources
              from the community.
            </p>
          </div>
          <div class="search-container" id="search-container">
            <Select
              value={
                !selectedSearch.value
                  ? inputSearch
                    ? { value: inputSearch, label: inputSearch }
                    : { value: "Search", label: "Search" }
                  : selectedSearch
              }
              options={options}
              onKeyDown={handleKeyDown}
              onChange={handleSelectedTitle}
              onInputChange={handleInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              components={{
                Option: CustomOption,
              }}
              menuIsOpen={isSearchMenuOpen}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  alignItems: "center",
                  borderRadius: "20px",
                  border: "1px solid var(--Neutral-Foreground-1-Rest, #FFF)",

                  background: "var(--Neutral-Background-4-Rest, #0a0a0a)",
                  width: "100%",
                  padding: "8px 11px",
                  zIndex: 5,

                  transition: "opacity 0.5s",

                  "&:hover": {
                    background: "#383838",
                  },
                  ...(state.selectProps.menuIsOpen && {
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }),
                }),
                menu: (provided) => ({
                  ...provided,
                  width: "100%",
                  background: "var(--Neutral-Background-19-Rest, #000)",

                  color: "var(--Neutral-Foreground-2-Brand-Selected, #50E6FF)",
                  marginTop: "-10px",

                  borderColor: "white",
                  cursor: "pointer",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "350",
                  lineHeight: "22px",
                  padding: "10px 16px",
                  alignItems: "center",
                  borderRadius: "0px 0px 20px 20px",
                }),
                option: (provided, state) => ({
                  ...provided,
                  display: "flex",
                  flexDirection: "Row",
                  gap: "10px",
                  backgroundColor: state.isSelected ? "#383838" : "#000",

                  "&:hover": {
                    backgroundColor: "#383838",
                    borderRadius: "4px",
                  },
                }),

                optionGroup__heading: (styles) => ({
                  ...styles,
                  color: "white",
                }),
                input: (styles) => ({
                  ...styles,
                  backgroundImage: `url("../../../../icon/searchbar-search.svg")`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "left center",
                  color: "white",
                  paddingLeft: "30px",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  paddingLeft: "100px",
                  color: "#ffffff",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  color: "#ffffff",
                  paddingLeft: "30px",
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  transform: state.selectProps.menuIsOpen
                    ? "rotate(-180deg)"
                    : null,
                  transition: "transform 0.3s",
                  color: "#ffffff",
                  "&:hover": {
                    color: "#ffffff",
                  },
                }),
              }}
            />
            <div className="filter-pill-container">
              {productFilter.map((filter) => (
                <div className="filter-pill">
                  <div className="pill-text">{filter[0]}</div>
                  <img
                    src="../icon/pill-cross.svg"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setProductFilter(
                        productFilter.filter((product) => product !== filter)
                      )
                    }
                  />
                </div>
              ))}
              {programmingLanguage.map((filter) => (
                <div className="filter-pill">
                  <div className="pill-text">{filter}</div>
                  <img
                    src="../icon/pill-cross.svg"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setProgrammingLanguage(
                        programmingLanguage.filter(
                          (language) => language !== filter
                        )
                      )
                    }
                  />
                </div>
              ))}

              {difficulty.map((filter) => (
                <div className="filter-pill">
                  <div className="pill-text">{filter}</div>
                  <img
                    src="../icon/pill-cross.svg"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setDifficulty(
                        difficulty.filter((language) => language !== filter)
                      )
                    }
                  />
                </div>
              ))}
              {lastUpdated.includes(true) && (
                <div className="filter-pill">
                  <div className="pill-text">
                    {lastUpdated[0]
                      ? "< 6 months"
                      : lastUpdated[0]
                      ? "6 months - 12 months"
                      : "> 12 months"}
                  </div>
                  <img
                    src="../icon/pill-cross.svg"
                    style={{ cursor: "pointer" }}
                    onClick={() => setLastUpdated([false, false, false])}
                  />
                </div>
              )}
              {topics.map((filter) => (
                <div className="filter-pill">
                  <div className="pill-text">{filter}</div>
                  <img
                    src="../icon/pill-cross.svg"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setTopics(topics.filter((topic) => topic !== filter))
                    }
                  />
                </div>
              ))}
              {type.map((filter) => (
                <div className="filter-pill">
                  <div className="pill-text">{filter}</div>
                  <img
                    src="../icon/pill-cross.svg"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setType(type.filter((typeData) => typeData !== filter))
                    }
                  />
                </div>
              ))}
              {(productFilter.length > 0 ||
                programmingLanguage.length > 0 ||
                difficulty.length > 0 ||
                lastUpdated.includes(true) ||
                topics.length > 0 ||
                type.length > 0) && (
                <div className="clear-filter" onClick={handleClearPills}>
                  {" "}
                  <div className="clear-filter-text">Clear all</div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="banner-side" id="banner-side">
          <img
            id="banner-img"
            src="images/drops/drop-logo.svg"
            alt="svg icon"
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;
